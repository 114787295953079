<template>
  <v-container
    v-if="activeBoard != null"
    fluid
    style="height: 100%; display: flex; flex-direction: column"
    :class="activeBoard.fullPage ? 'px-3 pt-6 ma-0' : 'pa-3'"
  >
    <div
      style="height: 100%; display: flex; flex-direction: column"
      :style="activeBoard.fullPage ? '' : `background: #eceff1; border-radius: 0.5rem;`"
      :class="activeBoard.fullPage ? '' : 'px-5 pb-5 inset-shadow'"
    >
      <v-row class="mt-0 mb-0 mt-0" justify="space-between" align="center">
        <v-col lg="auto" sm="12" cols="12" class="d-flex align-center" style="gap: 0.5rem">
          <h2 class="text--primary font-weight-black mr-2">
            <i class="fad mr-2 ml-1" :class="activeBoard.icon"></i>{{ activeBoard.text }}
          </h2>

          <v-btn
            @click="collapseAll"
            icon
            color="secondary"
            style="text-transform: none !important"
          >
            <i class="fad fa-chevron-double-up"></i>
          </v-btn>
          <v-btn @click="expandAll" icon color="secondary" style="text-transform: none !important">
            <i class="fad fa-chevron-double-down"></i>
          </v-btn>

          <refresh
            :loading="loadingBigBoard"
            @refresh="getBigBoardMonthsDebounced()"
            small
          ></refresh>

          <v-scroll-y-transition mode="out-in">
            <div
              v-if="!loadingBigBoard && lastUpdatedAgo != null"
              class="ml-2 d-flex align-center fs-12px font-weight-bold"
              :class="{
                'green--text': BbFreshness == FRESHNESS.Fresh,
                'orange--text': BbFreshness == FRESHNESS.Due,
                'red--text': BbFreshness == FRESHNESS.Expired,
              }"
              style="gap: 0.4rem"
            >
              <i class="fas fa-clock"></i>Last Updated:
              <span class="">
                <dater :date="generateDate" :alteredText="lastUpdatedAgo"></dater>
              </span>
            </div>
          </v-scroll-y-transition>
        </v-col>
        <v-col
          lg="auto"
          md="12"
          sm="12"
          cols="12"
          class="d-flex flex-row align-center justify-end flex-wrap"
          style="gap: 0.5rem"
        >
          <v-menu
            dense
            offset-y
            bottom
            z-index="10000"
            v-if="$has(perms.ResourceOffers.Create) || $has(perms.ResourceShiftRequests.Create)"
          >
            <template v-slot:activator="{ attrs: menuAttrs, on: menuOn }">
              <v-tooltip bottom z-index="999" nudge-bottom="-4px">
                <template v-slot:activator="{ on, attrs }">
                  <div color="info" v-bind="menuAttrs" v-on="menuOn">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      color="deep-purple"
                      dark
                      min-height="28px"
                      min-width="32px"
                      width="32px"
                      class="mr-1"
                      style="text-transform: none !important"
                    >
                      <span class="fs-15px d-flex align-center justify-center">
                        <i class="fad fa-pencil" style="line-height: 0; position: absolute"></i>
                      </span>
                    </v-btn>
                  </div>
                </template>
                <span class="d-flex align-center">Edit My Hours</span>
              </v-tooltip>
            </template>
            <v-list class="more-options-menu">
              <v-list-item @click="openCreateOffer" v-if="$has(perms.ResourceOffers.Create)">
                <v-list-item-icon class="mr-2 justify-center">
                  <i class="fad fa-horse-head secondary--text"></i>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="secondary--text font-weight-medium">
                    Offer Hours to Another User
                    <!-- <span class="opacity-64 fs-12px ml-1">"Horse Trading"</span> -->
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                @click="openCreateShiftRequest"
                v-if="$has(perms.ResourceShiftRequests.Create)"
              >
                <v-list-item-icon class="mr-2 justify-center">
                  <i class="fad fa-clock secondary--text"></i>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="secondary--text font-weight-medium">
                    Shift Hours to Another Month</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
          <date-range-month
            v-if="!isSmallBoard"
            class="mr-1"
            actions-menu
            :start-date.sync="options.startDate"
            :end-date.sync="options.endDate"
          ></date-range-month>
          <v-sheet elevation="2" height="32" class="d-flex flex-row align-center pl-2" rounded>
            <label class="fs-14px ma-0 mx-1" style="font-weight: 600">Contracts:</label>
            <v-radio-group
              v-model="filterContractStatus"
              mandatory
              hide-details
              class="mt-0 pt-0 ml-1 big-board-radio"
              row
            >
              <v-radio :value="null" color="deep-purple">
                <template v-slot:label>
                  <div
                    class="font-weight-bold fs-13px d-flex align-center"
                    :class="filterContractStatus == null ? 'deep-purple--text' : 'secondary--text'"
                  >
                    ALL
                  </div>
                </template>
              </v-radio>
              <v-radio :value="enums.CONTRACT_STATUS.Pending.value" color="deep-purple">
                <template v-slot:label>
                  <div
                    class="font-weight-bold fs-13px d-flex align-center"
                    :class="
                      filterContractStatus == enums.CONTRACT_STATUS.Pending.value
                        ? 'deep-purple--text'
                        : 'secondary--text'
                    "
                  >
                    <i class="fas fa-pause mr-2" style="line-height: 0"></i> Pending
                  </div>
                </template>
              </v-radio>
              <v-radio :value="enums.CONTRACT_STATUS.Approved.value" color="deep-purple">
                <template v-slot:label>
                  <div
                    class="font-weight-bold fs-13px d-flex align-center"
                    :class="
                      filterContractStatus == enums.CONTRACT_STATUS.Approved.value
                        ? 'deep-purple--text'
                        : 'secondary--text'
                    "
                  >
                    <i class="fas fa-play mr-2" style="line-height: 0"></i> Approved
                  </div>
                </template>
              </v-radio>
            </v-radio-group>
          </v-sheet>
          <v-sheet elevation="2" height="32" class="mr-2 px-2" rounded v-if="false">
            <v-switch
              v-model="showLaborGroup"
              class="ma-0"
              color="orange"
              style="font-size: 14px !important"
              hide-details
            >
              <template v-slot:label>
                <label class="v-switch-label ma-0 mx-1 fs-14px">Show Labor Groups</label>
              </template>
            </v-switch>
          </v-sheet>
        </v-col>
      </v-row>
      <v-scroll-y-transition
        mode="out-in"
        style="
          overflow: auto;
          overflow: overlay;
          height: 100%;
          flex: 1 1 auto;
          display: flex;
          flex-direction: column;
        "
      >
        <div
          v-if="loadingBigBoard"
          :key="'projectBigBoardMonths-list-loading'"
          class="projectBigBoardMonths-list-loading d-flex flex-column align-center justify-center"
          style="
            overflow: auto;
            overflow: overlay;
            height: 100%;
            flex: 1 1 auto;
            display: flex;
            flex-direction: column;
          "
        >
          <video
            width="275"
            muted
            loop
            autoplay
            class="inset-shadow-video"
            style="padding: 0.5rem; border-radius: 10rem; background: #fff"
          >
            <source src="/img/art/astronaut-mines-coins-4979111-4153144.mp4" type="video/mp4" />
          </video>
          <h3 class="mt-3 text--secondary">Generating {{ activeBoard.text }} Data ...</h3>
        </div>
        <div class="no-data-available" :key="'no-data-available'" v-else-if="loadingBigBoardError">
          <img
            width="600"
            style="max-width: 100%; width: 500px !important"
            src="/img/art/undraw_into_the_night_vumi.svg"
          />
          <h1 class="mt-3 error--text" style="font-size: 2.75rem; color: #e45d51">
            Error Loading {{ activeBoard.text }}!
          </h1>
          <h3 class="font-weight-bold text--disabled" style="font-size: 1.5rem">
            Uh-Oh! Something went wrong!
          </h3>
        </div>
        <v-data-table
          dense
          v-else-if="projectBigBoardMonthsAuthorized.length > 0"
          :headers="headers"
          :items="projectBigBoardMonthsAuthorized"
          class="elevation-2 mt-0 big-board-table table-sticky-header-exclude-footer"
          :items-per-page="-1"
          :group-by="showLaborGroup ? 'laborType' : []"
          hide-default-footer
          hide-default-header
          style="
            overflow: auto;
            overflow: overlay;
            height: 100%;
            flex: 1 1 auto;
            display: flex;
            flex-direction: column;
          "
        >
          <template v-slot:[`group.header`]="{ items, isOpen, group, toggle }">
            <th
              colspan="100%"
              class="big-board-group"
              :class="getLaborTypeObj(items[0].laborType).groupClass"
            >
              <div class="big-board-group-content">
                <v-btn
                  width="24px"
                  height="24px"
                  :ref="group"
                  :table-group-data-open="isOpen"
                  :table-group-labor-type="items[0].laborType"
                  icon
                  :dark="getLaborTypeObj(items[0].laborType).groupDark"
                  @click="toggle"
                >
                  <i :class="isOpen ? 'far fa-angle-up' : 'far fa-angle-down'"></i>
                </v-btn>
                <i
                  v-if="getLaborTypeObj(items[0].laborType).groupIcon"
                  class="fad fs-18px mr-1"
                  style="line-height: 0"
                  :class="getLaborTypeObj(items[0].laborType).groupIcon"
                ></i>
                <label
                  class="d-inline-flex ma-0"
                  style="
                    font-weight: 600 !important;
                    font-size: 14px;
                    flex: none;
                    align-self: center;
                  "
                >
                  {{ getLaborTypeObj(items[0].laborType).desc }}
                </label>
              </div>
            </th>
          </template>
          <template v-slot:header="{ props }">
            <thead class="v-data-table-header elevation-2">
              <tr>
                <th colspan="1" role="columnheader" scope="col" class="pl-2"></th>
                <th colspan="1" role="columnheader" scope="col" class="pl-2"></th>
                <th
                  v-for="(headerGroup, gi) in groupedHeaders"
                  :key="gi"
                  :colspan="headerGroup.length"
                  role="columnheader"
                  scope="col"
                  class="pl-2"
                  style="border-bottom: thin solid rgba(42, 54, 59, 0.12)"
                >
                  <span class="year-header-value">{{ headerGroup[0].year }}</span>
                </th>
              </tr>
              <tr>
                <th
                  v-for="header in props.headers"
                  :key="header.value"
                  role="columnheader"
                  scope="col"
                  :aria-label="header.text"
                  class="text-start"
                  :class="header.class"
                  :style="{
                    width: header.width || '',
                    minWidth: header.width || '',
                  }"
                >
                  <span v-if="header.type == 'date'">{{ header.month }}</span>
                  <span v-else>{{ header.text }}</span>
                </th>
              </tr>
            </thead>
          </template>
          <template v-slot:item="{ item }">
            <tr>
              <td :class="[isNetSummarySpecial(item) ? 'net-summary-special' : '']">
                <div class="d-flex align-center" v-if="item.rowType == 'UserEntry'">
                  <user-all-selector
                    v-if="item.recordType == 0"
                    :users="users"
                    required
                    hideLabel
                    hideDetails
                    hideBorder
                    readonly
                    :isUsersLoading="isUsersLoading"
                    v-model="item.userId"
                    style="width: 100%"
                    class="ml-0"
                  ></user-all-selector>
                  <user-avatar
                    class="ml-2"
                    v-else
                    showNoUser
                    :noUserText="item.placeholderName"
                    noUserIcon="fas fa-question"
                  ></user-avatar>
                </div>
                <div class="d-flex align-center" :class="[item.class]" v-else>
                  <h4>{{ item.title }}</h4>
                </div>
              </td>
              <td
                class="px-0"
                :class="[
                  highlighted.headerValue == 'rowSumTotal' &&
                  highlighted.index == projectBigBoardMonthsAuthorized.indexOf(item)
                    ? 'isHighlighted'
                    : '',
                ]"
              >
                <!-- {{ item.rowType }}} -->
                <span
                  v-if="item['rowSumTotal']"
                  class="row-sum-span"
                  :class="[
                    getEntryRangeSumClass(item['rowSumTotal']),
                    isNetSummarySpecial(item) ? 'net-summary-special' : '',
                  ]"
                  @click="
                    () => {
                      !isNetSummarySpecial(item)
                        ? viewRowTotalRecords(
                            item,
                            projectBigBoardMonthsAuthorized.indexOf(item),
                            'rowSumTotal'
                          )
                        : false;
                    }
                  "
                >
                  <v-tooltip
                    top
                    z-index="999"
                    color="white"
                    nudge-top="-8px"
                    content-class="elevation-4 pa-0 has-thin-border"
                    :disabled="filterContractStatus != null"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <span
                        v-bind="attrs"
                        v-on="on"
                        style="
                          width: 100%;
                          max-width: 100px;
                          height: 100%;
                          text-align: left;
                          justify-content: flex-start;
                          display: flex;
                        "
                      >
                        <span class="span-content">
                          <span v-if="filterContractStatus == enums.CONTRACT_STATUS.Approved.value">
                            <span v-if="item['rowSumTotal'].datatype == 'money'">
                              {{ item["rowSumTotal"].totalApproved | usdFormat }}
                            </span>
                            <span v-else-if="item['rowSumTotal'].datatype == 'hours'">
                              {{ item["rowSumTotal"].totalApproved | fixedFloatFormat }}
                            </span>
                          </span>
                          <span v-if="filterContractStatus == null">
                            <span v-if="item['rowSumTotal'].datatype == 'money'">
                              {{ item["rowSumTotal"].total | usdFormat }}
                            </span>
                            <span v-else-if="item['rowSumTotal'].datatype == 'hours'">
                              {{ item["rowSumTotal"].total | fixedFloatFormat }}
                            </span>
                          </span>
                          <span v-if="filterContractStatus == enums.CONTRACT_STATUS.Pending.value">
                            <span v-if="item['rowSumTotal'].datatype == 'money'">
                              {{ item["rowSumTotal"].totalPending | usdFormat }}
                            </span>
                            <span v-else-if="item['rowSumTotal'].datatype == 'hours'">
                              {{ item["rowSumTotal"].totalPending | fixedFloatFormat }}
                            </span>
                          </span>
                        </span>
                      </span>
                    </template>
                    <span class="d-flex align-center">
                      <span
                        class="total-pending px-2 py-1"
                        :class="{
                          ...getCellClass(item['rowSumTotal'].totalPending),
                        }"
                      >
                        <i class="fad fa-pause fa-swap-opacity mr-1"></i>
                        <span v-if="item['rowSumTotal'].datatype == 'money'">
                          {{ item["rowSumTotal"].totalPending | usdFormat }}
                        </span>
                        <span v-else-if="item['rowSumTotal'].datatype == 'hours'">
                          {{ item["rowSumTotal"].totalPending | fixedFloatFormat }}
                        </span>
                      </span>
                      <v-divider vertical></v-divider>
                      <span
                        class="total-approved px-2 py-1"
                        :class="{
                          ...getCellClass(item['rowSumTotal'].totalApproved),
                        }"
                      >
                        <i class="fad fa-play mr-1"></i>
                        <span v-if="item['rowSumTotal'].datatype == 'money'">
                          {{ item["rowSumTotal"].totalApproved | usdFormat }}
                        </span>
                        <span v-else-if="item['rowSumTotal'].datatype == 'hours'">
                          {{ item["rowSumTotal"].totalApproved | fixedFloatFormat }}
                        </span>
                      </span>
                    </span>
                  </v-tooltip>
                </span>
              </td>
              <template v-for="(header, i) in dynamicHeaders">
                <td
                  v-if="item[header.value] != null"
                  :key="i"
                  :class="[
                    header.cellClass,
                    isNetSummarySpecial(item) ? 'net-summary-special' : '',
                    highlighted.headerValue == header.value &&
                    highlighted.index == projectBigBoardMonthsAuthorized.indexOf(item)
                      ? 'isHighlighted'
                      : '',
                  ]"
                  :colspan="item.colSpan != null ? item.colSpan : 1"
                  @click="
                    !isNetSummarySpecial(item)
                      ? viewRecords(
                          item, //[header.value]
                          projectBigBoardMonthsAuthorized.indexOf(item),
                          header.value
                        )
                      : null
                  "
                >
                  <span
                    v-if="item[header.value].rowType == 'UserEntry'"
                    class="entry-span"
                    :class="{
                      ...getEntryHoursClass(item[header.value]),
                    }"
                    :key="i"
                  >
                    <v-tooltip
                      top
                      z-index="999"
                      color="white"
                      nudge-top="-8px"
                      content-class="elevation-4 pa-0 has-thin-border"
                      :disabled="filterContractStatus != null"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <span
                          v-bind="attrs"
                          v-on="on"
                          style="
                            width: 100%;
                            max-width: 100px;
                            height: 100%;
                            text-align: left;
                            justify-content: flex-start;
                            display: flex;
                          "
                        >
                          <span class="span-content">
                            <span
                              class="total-approved"
                              v-if="filterContractStatus == enums.CONTRACT_STATUS.Approved.value"
                            >
                              {{ item[header.value].totalApprovedHours | fixedFloatFormat }}
                            </span>
                            <span class="total-hours" v-if="filterContractStatus == null">
                              {{ item[header.value].totalHours | fixedFloatFormat }}
                            </span>
                            <span
                              class="total-pending"
                              v-if="filterContractStatus == enums.CONTRACT_STATUS.Pending.value"
                            >
                              {{ item[header.value].totalPendingHours | fixedFloatFormat }}
                            </span>
                          </span>
                        </span>
                      </template>
                      <span class="d-flex align-center">
                        <span
                          class="total-pending px-2 py-1"
                          :class="{
                            ...getCellClass(item[header.value].totalPendingHours),
                          }"
                        >
                          <i class="fad fa-pause fa-swap-opacity mr-1"></i>
                          {{ item[header.value].totalPendingHours | fixedFloatFormat }}
                        </span>
                        <v-divider vertical></v-divider>
                        <span
                          class="total-approved px-2 py-1"
                          :class="{
                            ...getCellClass(item[header.value].totalApprovedHours),
                          }"
                        >
                          <i class="fad fa-play mr-1"></i>
                          {{ item[header.value].totalApprovedHours | fixedFloatFormat }}
                        </span>
                      </span>
                    </v-tooltip>
                  </span>
                  <span
                    v-else-if="item[header.value].laborType == enums.LABOR_TYPE.Hours.value"
                    class="hours-span"
                    :class="{ ...getEntryHoursClass(item[header.value]) }"
                    :key="i"
                  >
                    <v-tooltip
                      top
                      z-index="999"
                      color="white"
                      nudge-top="-8px"
                      content-class="elevation-4 pa-0 has-thin-border"
                      :disabled="filterContractStatus != null"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <span
                          v-bind="attrs"
                          v-on="on"
                          style="
                            width: 100%;
                            max-width: 100px;
                            height: 100%;
                            text-align: left;
                            justify-content: flex-start;
                            display: flex;
                          "
                        >
                          <span
                            class="span-content"
                            :class="{
                              'sticky-cell': item[header.value].rowType == 'TotalRangeHours',
                            }"
                          >
                            <span
                              class="total-approved"
                              v-if="filterContractStatus == enums.CONTRACT_STATUS.Approved.value"
                            >
                              {{ item[header.value].totalApprovedHours | fixedFloatFormat }}
                            </span>
                            <span class="total-hours" v-if="filterContractStatus == null">
                              {{ item[header.value].totalHours | fixedFloatFormat }}
                            </span>
                            <span
                              class="total-pending"
                              v-if="filterContractStatus == enums.CONTRACT_STATUS.Pending.value"
                            >
                              {{ item[header.value].totalPendingHours | fixedFloatFormat }}
                            </span>
                          </span>
                        </span>
                      </template>
                      <span class="d-flex align-center">
                        <span
                          class="total-pending px-2 py-1"
                          :class="{
                            ...getCellClass(item[header.value].totalPendingHours),
                          }"
                        >
                          <i class="fad fa-pause fa-swap-opacity mr-1"></i>
                          {{ item[header.value].totalPendingHours | fixedFloatFormat }}
                        </span>
                        <v-divider vertical></v-divider>
                        <span
                          class="total-approved px-2 py-1"
                          :class="{
                            ...getCellClass(item[header.value].totalApprovedHours),
                          }"
                        >
                          <i class="fad fa-play mr-1"></i>
                          {{ item[header.value].totalApprovedHours | fixedFloatFormat }}
                        </span>
                      </span>
                    </v-tooltip>
                  </span>
                  <span
                    v-else-if="item[header.value].laborType == enums.LABOR_TYPE.Revenue.value"
                    class="revenue-span"
                    :class="{
                      ...getEntryRevenueClass(item[header.value]),
                    }"
                    :key="i"
                  >
                    <v-tooltip
                      top
                      z-index="999"
                      color="white"
                      nudge-top="-8px"
                      content-class="elevation-4 pa-0 has-thin-border"
                      :disabled="filterContractStatus != null"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <span
                          v-bind="attrs"
                          v-on="on"
                          style="
                            width: 100%;
                            max-width: 100px;
                            height: 100%;
                            text-align: left;
                            justify-content: flex-start;
                            display: flex;
                          "
                        >
                          <span
                            class="span-content"
                            :class="{
                              'sticky-cell': item[header.value].rowType == 'TotalRangeRevenue',
                            }"
                          >
                            <span
                              class="total-approved"
                              v-if="filterContractStatus == enums.CONTRACT_STATUS.Approved.value"
                            >
                              {{ item[header.value].totalApprovedRevenue | usdFormat }}
                            </span>
                            <span class="total-revenue" v-if="filterContractStatus == null">
                              {{ item[header.value].totalRevenue | usdFormat }}
                            </span>
                            <span
                              class="total-pending"
                              v-if="filterContractStatus == enums.CONTRACT_STATUS.Pending.value"
                            >
                              {{ item[header.value].totalPendingRevenue | usdFormat }}
                            </span>
                          </span>
                        </span>
                      </template>
                      <span class="d-flex align-center">
                        <span
                          class="total-pending px-2 py-1"
                          :class="{
                            ...getCellClass(item[header.value].totalPendingRevenue),
                          }"
                        >
                          <i class="fad fa-pause fa-swap-opacity mr-1"></i>
                          {{ item[header.value].totalPendingRevenue | usdFormat }}
                        </span>
                        <v-divider vertical></v-divider>
                        <span
                          class="total-approved px-2 py-1"
                          :class="{
                            ...getCellClass(item[header.value].totalApprovedRevenue),
                          }"
                        >
                          <i class="fad fa-play mr-1"></i>
                          {{ item[header.value].totalApprovedRevenue | usdFormat }}
                        </span>
                      </span>
                    </v-tooltip>
                  </span>
                  <span
                    v-else-if="item[header.value].laborType == enums.LABOR_TYPE.Labor.value"
                    class="labor-span"
                    :class="{
                      ...getEntryLaborClass(item[header.value]),
                    }"
                    :key="i"
                  >
                    <v-tooltip
                      top
                      z-index="999"
                      color="white"
                      nudge-top="-8px"
                      content-class="elevation-4 pa-0 has-thin-border"
                      :disabled="filterContractStatus != null"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <span
                          v-bind="attrs"
                          v-on="on"
                          style="
                            width: 100%;
                            max-width: 100px;
                            height: 100%;
                            text-align: left;
                            justify-content: flex-start;
                            display: flex;
                          "
                        >
                          <span
                            class="span-content"
                            :class="{
                              'sticky-cell': item[header.value].rowType == 'TotalRangeLabor',
                            }"
                          >
                            <span
                              class="total-approved"
                              v-if="filterContractStatus == enums.CONTRACT_STATUS.Approved.value"
                            >
                              {{ item[header.value].totalApprovedLabor | usdFormat }}
                            </span>
                            <span class="total-labor" v-if="filterContractStatus == null">
                              {{ item[header.value].totalLabor | usdFormat }}
                            </span>
                            <span
                              class="total-pending"
                              v-if="filterContractStatus == enums.CONTRACT_STATUS.Pending.value"
                            >
                              {{ item[header.value].totalPendingLabor | usdFormat }}
                            </span>
                          </span>
                        </span>
                      </template>
                      <span class="d-flex align-center">
                        <span
                          class="total-pending px-2 py-1"
                          :class="{
                            ...getCellClass(item[header.value].totalPendingLabor),
                          }"
                        >
                          <i class="fad fa-pause fa-swap-opacity mr-1"></i>
                          {{ item[header.value].totalPendingLabor | usdFormat }}
                        </span>
                        <v-divider vertical></v-divider>
                        <span
                          class="total-approved px-2 py-1"
                          :class="{
                            ...getCellClass(item[header.value].totalApprovedLabor),
                          }"
                        >
                          <i class="fad fa-play mr-1"></i>
                          {{ item[header.value].totalApprovedLabor | usdFormat }}
                        </span>
                      </span>
                    </v-tooltip>
                  </span>
                  <span
                    v-else-if="
                      item[header.value].laborType == enums.LABOR_TYPE.DirectExpensesCost.value ||
                      item[header.value].laborType == enums.LABOR_TYPE.DirectExpensesRevenue.value
                    "
                    class="direct-expenses-span"
                    :class="{
                      ...getEntryDirectExpensesClass(item[header.value]),
                    }"
                    :key="i"
                  >
                    <v-tooltip
                      top
                      z-index="999"
                      color="white"
                      nudge-top="-8px"
                      content-class="elevation-4 pa-0 has-thin-border"
                      :disabled="filterContractStatus != null"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <span
                          v-bind="attrs"
                          v-on="on"
                          style="
                            width: 100%;
                            max-width: 100px;
                            height: 100%;
                            text-align: left;
                            justify-content: flex-start;
                            display: flex;
                          "
                        >
                          <span
                            class="span-content"
                            :class="{
                              'sticky-cell': item[header.value].rowType == 'TotalRangeLabor',
                            }"
                          >
                            <span
                              class="total-approved"
                              v-if="filterContractStatus == enums.CONTRACT_STATUS.Approved.value"
                            >
                              {{ item[header.value].totalApprovedExpenses | usdFormat }}
                            </span>
                            <span class="total-labor" v-if="filterContractStatus == null">
                              {{ item[header.value].totalExpenses | usdFormat }}
                            </span>
                            <span
                              class="total-pending"
                              v-if="filterContractStatus == enums.CONTRACT_STATUS.Pending.value"
                            >
                              {{ item[header.value].totalPendingExpenses | usdFormat }}
                            </span>
                          </span>
                        </span>
                      </template>
                      <span class="d-flex align-center">
                        <span
                          class="total-pending px-2 py-1"
                          :class="{
                            ...getCellClass(item[header.value].totalPendingExpenses),
                          }"
                        >
                          <i class="fad fa-pause fa-swap-opacity mr-1"></i>
                          {{ item[header.value].totalPendingExpenses | usdFormat }}
                        </span>
                        <v-divider vertical></v-divider>
                        <span
                          class="total-approved px-2 py-1"
                          :class="{
                            ...getCellClass(item[header.value].totalApprovedExpenses),
                          }"
                        >
                          <i class="fad fa-play mr-1"></i>
                          {{ item[header.value].totalApprovedExpenses | usdFormat }}
                        </span>
                      </span>
                    </v-tooltip>
                  </span>
                  <span
                    v-else-if="item[header.value].laborType == enums.LABOR_TYPE.NetSummary.value"
                    class="net-summary-span"
                    :class="{
                      ...getEntryNetSummaryClass(item[header.value]),
                    }"
                    :key="i"
                  >
                    <v-tooltip
                      top
                      z-index="999"
                      color="white"
                      nudge-top="-8px"
                      content-class="elevation-4 pa-0 has-thin-border"
                      :disabled="filterContractStatus != null"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <span
                          v-bind="attrs"
                          v-on="on"
                          style="
                            width: 100%;
                            max-width: 100px;
                            height: 100%;
                            text-align: left;
                            justify-content: flex-start;
                            display: flex;
                          "
                        >
                          <span
                            class="span-content"
                            :class="{
                              'sticky-cell': item[header.value].rowType == 'TotalRangeLabor',
                            }"
                          >
                            <span
                              class="total-approved"
                              v-if="filterContractStatus == enums.CONTRACT_STATUS.Approved.value"
                            >
                              {{ item[header.value].totalApproved | usdFormat }}
                            </span>
                            <span class="total-labor" v-if="filterContractStatus == null">
                              {{ item[header.value].total | usdFormat }}
                            </span>
                            <span
                              class="total-pending"
                              v-if="filterContractStatus == enums.CONTRACT_STATUS.Pending.value"
                            >
                              {{ item[header.value].totalPending | usdFormat }}
                            </span>
                          </span>
                        </span>
                      </template>
                      <span class="d-flex align-center">
                        <span
                          class="total-pending px-2 py-1"
                          :class="{
                            ...getCellClass(item[header.value].totalPending),
                          }"
                        >
                          <i class="fad fa-pause fa-swap-opacity mr-1"></i>
                          {{ item[header.value].totalPending | usdFormat }}
                        </span>
                        <v-divider vertical></v-divider>
                        <span
                          class="total-approved px-2 py-1"
                          :class="{
                            ...getCellClass(item[header.value].totalApproved),
                          }"
                        >
                          <i class="fad fa-play mr-1"></i>
                          {{ item[header.value].totalApproved | usdFormat }}
                        </span>
                      </span>
                    </v-tooltip>
                  </span>
                </td>
              </template>
            </tr>
          </template>
        </v-data-table>
        <div class="no-data-available" :key="'no-data-available'" v-else>
          <img src="/img/art/tumble-weed.svg" class="mt-3" />
          <p class="font-weight-medium mb-5 text--disabled">No Data to Show!</p>
        </div>
      </v-scroll-y-transition>
    </div>
    <big-board-entry-details
      ref="bigBoardEntryDetails"
      :users="users"
      :isUsersLoading="isUsersLoading"
      :type="type"
    ></big-board-entry-details>
    <!-- :managementMode="!isMyBoard && $has(perms.ResourceOffers.CreateForOthers)" -->
    <edit-offer ref="editOffer"></edit-offer>
    <edit-shift-request ref="editShiftRequest"></edit-shift-request>
  </v-container>
</template>

<script>
import moment from "moment";
import bigBoardService from "../services/bigBoard-service";
import perms from "../../../plugins/permissions";
import enums from "../../../plugins/enums";
import usersAPI from "../../Admin/services/StaffService";
import UserAllSelector from "../../Shared/components/UserAllSelector.vue";
import DateRangeMonth from "../../Shared/components/DateRangeMonth.vue";
import { groupBy } from "lodash";
import BigBoardEntryDetails from "./BigBoardEntryDetails.vue";
import EditOffer from "../../ResourceOffers/components/EditOffer.vue";
import EditShiftRequest from "../../ResourceShiftRequests/components/EditShiftRequest.vue";

export default {
  name: "big-board-viewer",
  components: {
    UserAllSelector,
    BigBoardEntryDetails,
    EditOffer,
    EditShiftRequest,
    DateRangeMonth,
  },
  data() {
    return {
      perms,
      enums,
      boardTimerId: null,
      FRESHNESS: {
        Fresh: 1,
        Due: 2,
        Expired: 3,
      },
      BbFreshnessStep: 5 * 60 * 1000, // 5 minutes
      BbFreshness: 1,
      BbFreshnessTimer: null,
      generateDate: null,
      lastUpdatedAgo: null,
      lastUpdatedAgoTimer: null,
      lastUpdatedAgoStep: 60 * 1000, // 5 Seconds
      MonthNames: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      contractStatus: this.$options.filters.EnumToList(enums.CONTRACT_STATUS, true),
      laborTypesList: enums.LABOR_TYPE,
      expenseCategoriesList: this.$options.filters.EnumToList(enums.DIRECT_EXPENSE_CATEGORY, true),
      storageKey: "ProjectBigBoardMonths",
      originalMonths: [],
      projectBigBoardMonths: [],
      loadingBigBoardError: false,
      loadingBigBoard: false,
      ProjectBigBoardMonthsTimerId: null,
      mainSearchInFocus: false,
      isUsersLoading: false,
      showLaborGroup: true,
      headers: [],
      users: [],
      filterContractStatus: null,
      options: {
        startDate: null,
        endDate: null,
        contractStatus: null,
      },
      yearsList: [],
      highlighted: {
        index: null,
        headerValue: null,
      },
    };
  },
  props: {
    type: {
      type: Number,
      default: null,
    },
    projectId: {
      type: Number,
      default: null,
    },
  },
  computed: {
    activeBoard() {
      return this.getEnumMember(enums.BIG_BOARD_TYPE, this.type);
    },
    isBigBoard() {
      return this.type == enums.BIG_BOARD_TYPE.BigBoard.value;
    },
    isSmallBoard() {
      return this.type == enums.BIG_BOARD_TYPE.SmallBoard.value;
    },
    isMyBoard() {
      return this.type == enums.BIG_BOARD_TYPE.MyBoard.value;
    },
    dynamicHeaders() {
      return this.headers.filter((h) => h.value != "userId" && h.value != "rowSumTotal");
    },
    groupedHeaders() {
      return groupBy(this.dynamicHeaders, (h) => h.year);
    },
    Perms_ViewEntryDetails() {
      if (this.isBigBoard) return perms.BigBoard.ViewEntryDetails;
      else if (this.isSmallBoard) return perms.SmallBoard.ViewEntryDetails;
      else if (this.isMyBoard) return perms.MyBoard.ViewEntryDetails;
      return null;
    },
    Perms_ViewLaborHoursSummary() {
      if (this.isBigBoard) return perms.BigBoard.ViewLaborHoursSummary;
      else if (this.isSmallBoard) return perms.SmallBoard.ViewLaborHoursSummary;
      else if (this.isMyBoard) return perms.MyBoard.ViewLaborHoursSummary;
      return null;
    },
    Perms_ViewLaborRevenueSummary() {
      if (this.isBigBoard) return perms.BigBoard.ViewLaborRevenueSummary;
      else if (this.isSmallBoard) return perms.SmallBoard.ViewLaborRevenueSummary;
      else if (this.isMyBoard) return perms.MyBoard.ViewLaborRevenueSummary;
      return null;
    },
    Perms_ViewLaborCostSummary() {
      if (this.isBigBoard) return perms.BigBoard.ViewLaborCostSummary;
      else if (this.isSmallBoard) return perms.SmallBoard.ViewLaborCostSummary;
      else if (this.isMyBoard) return perms.MyBoard.ViewLaborCostSummary;
      return null;
    },
    Perms_ViewDirectExpensesCostSummary() {
      if (this.isBigBoard) return perms.BigBoard.ViewDirectExpensesCostSummary;
      else if (this.isSmallBoard) return perms.SmallBoard.ViewDirectExpensesCostSummary;
      else if (this.isMyBoard) null; //return perms.MyBoard.ViewDirectExpensesCostSummary;
      return null;
    },
    Perms_ViewDirectExpensesRevenueSummary() {
      if (this.isBigBoard) return perms.BigBoard.ViewDirectExpensesRevenueSummary;
      else if (this.isSmallBoard) return perms.SmallBoard.ViewDirectExpensesRevenueSummary;
      else if (this.isMyBoard) null; //return perms.MyBoard.ViewDirectExpensesRevenueSummary;
      return null;
    },
    Perms_ViewNetSummary() {
      if (this.isBigBoard) return perms.BigBoard.ViewNetSummary;
      else if (this.isSmallBoard) return perms.SmallBoard.ViewNetSummary;
      else if (this.isMyBoard) return perms.MyBoard.ViewNetSummary;
      return null;
    },
    Perms_ViewNetSummaryTotalCost() {
      if (this.isBigBoard) return perms.BigBoard.ViewNetSummaryTotalCost;
      else if (this.isSmallBoard) return perms.SmallBoard.ViewNetSummaryTotalCost;
      else if (this.isMyBoard) return perms.MyBoard.ViewNetSummaryTotalCost;
      return null;
    },
    Perms_ViewNetSummaryTotalRevenue() {
      if (this.isBigBoard) return perms.BigBoard.ViewNetSummaryTotalRevenue;
      else if (this.isSmallBoard) return perms.SmallBoard.ViewNetSummaryTotalRevenue;
      else if (this.isMyBoard) return perms.MyBoard.ViewNetSummaryTotalRevenue;
      return null;
    },
    Perms_ViewNetSummaryNetProfit() {
      if (this.isBigBoard) return perms.BigBoard.ViewNetSummaryNetProfit;
      else if (this.isSmallBoard) return perms.SmallBoard.ViewNetSummaryNetProfit;
      else if (this.isMyBoard) return perms.MyBoard.ViewNetSummaryNetProfit;
      return null;
    },
    Perms_AC() {
      if (this.isBigBoard) return perms.BigBoard.ViewLaborTypeAssociateConsultants;
      else if (this.isSmallBoard) return perms.SmallBoard.ViewLaborTypeAssociateConsultants;
      else if (this.isMyBoard) return perms.MyBoard.ViewLaborTypeAssociateConsultants;
      return null;
    },
    Perms_SC() {
      if (this.isBigBoard) return perms.BigBoard.ViewLaborTypeSeniorConsultants;
      else if (this.isSmallBoard) return perms.SmallBoard.ViewLaborTypeSeniorConsultants;
      else if (this.isMyBoard) return perms.MyBoard.ViewLaborTypeSeniorConsultants;
      return null;
    },
    Perms_C() {
      if (this.isBigBoard) return perms.BigBoard.ViewLaborTypeConsultants;
      else if (this.isSmallBoard) return perms.SmallBoard.ViewLaborTypeConsultants;
      else if (this.isMyBoard) return perms.MyBoard.ViewLaborTypeConsultants;
      return null;
    },
    Perms_VI() {
      if (this.isBigBoard) return perms.BigBoard.ViewLaborTypeVirtualIntegrators;
      else if (this.isSmallBoard) return perms.SmallBoard.ViewLaborTypeVirtualIntegrators;
      else if (this.isMyBoard) return perms.MyBoard.ViewLaborTypeVirtualIntegrators;
      return null;
    },
    Perms_BIM() {
      if (this.isBigBoard) return perms.BigBoard.ViewLaborTypeBIMSpecialists;
      else if (this.isSmallBoard) return perms.SmallBoard.ViewLaborTypeBIMSpecialists;
      else if (this.isMyBoard) return perms.MyBoard.ViewLaborTypeBIMSpecialists;
      return null;
    },
    Perms_MGT() {
      if (this.isBigBoard) return perms.BigBoard.ViewLaborTypeManagement;
      else if (this.isSmallBoard) return perms.SmallBoard.ViewLaborTypeManagement;
      else if (this.isMyBoard) return perms.MyBoard.ViewLaborTypeManagement;
      return null;
    },
    Perms_TCN() {
      if (this.isBigBoard) return perms.BigBoard.ViewLaborTypeTechnical;
      else if (this.isSmallBoard) return perms.SmallBoard.ViewLaborTypeTechnical;
      else if (this.isMyBoard) return perms.MyBoard.ViewLaborTypeTechnical;
      return null;
    },
    Perms_SCD() {
      if (this.isBigBoard) return perms.BigBoard.ViewLaborTypeSCD;
      else if (this.isSmallBoard) return perms.SmallBoard.ViewLaborTypeSCD;
      else if (this.isMyBoard) return perms.MyBoard.ViewLaborTypeSCD;
      return null;
    },
    projectBigBoardMonthsAuthorized() {
      var authGroups = [];
      if (this.$has(this.Perms_ViewLaborHoursSummary))
        authGroups.push(enums.LABOR_TYPE.Hours.value);

      if (this.$has(this.Perms_ViewLaborRevenueSummary))
        authGroups.push(enums.LABOR_TYPE.Revenue.value);

      if (this.$has(this.Perms_ViewLaborCostSummary)) authGroups.push(enums.LABOR_TYPE.Labor.value);

      if (this.$has(this.Perms_ViewDirectExpensesCostSummary))
        authGroups.push(enums.LABOR_TYPE.DirectExpensesCost.value);

      if (this.$has(this.Perms_ViewDirectExpensesRevenueSummary))
        authGroups.push(enums.LABOR_TYPE.DirectExpensesRevenue.value);

      if (this.$has(this.Perms_ViewNetSummary)) authGroups.push(enums.LABOR_TYPE.NetSummary.value);

      if (this.$has(this.Perms_AC)) authGroups.push(enums.LABOR_TYPE.AC.value);

      if (this.$has(this.Perms_SC)) authGroups.push(enums.LABOR_TYPE.SC.value);

      if (this.$has(this.Perms_C)) authGroups.push(enums.LABOR_TYPE.C.value);

      if (this.$has(this.Perms_VI)) authGroups.push(enums.LABOR_TYPE.VI.value);

      if (this.$has(this.Perms_BIM)) authGroups.push(enums.LABOR_TYPE.BIM.value);

      if (this.$has(this.Perms_MGT)) authGroups.push(enums.LABOR_TYPE.MGT.value);

      if (this.$has(this.Perms_TCN)) authGroups.push(enums.LABOR_TYPE.TCN.value);

      if (this.$has(this.Perms_SCD)) authGroups.push(enums.LABOR_TYPE.SCD.value);

      // return this.projectBigBoardMonths.filter(
      //   (h) => h.laborType > 0 || authGroups.includes(h.laborType)
      // );
      return this.projectBigBoardMonths.filter((h) => authGroups.includes(h.laborType));
    },
  },
  created() {
    var bbType = this.isBigBoard ? "BigBoard" : this.isMyBoard ? "MyBoard" : null;
    this.options.startDate = this.$getFromLocal(
      `${bbType}-range-startDate`,
      false,
      this.formatDate(new Date(new Date().getFullYear(), 0, 1)).substr(0, 7)
    );
    this.options.endDate = this.$getFromLocal(
      `${bbType}-range-endDate`,
      false,
      this.formatDate(new Date(new Date().getFullYear(), 11, 31)).substr(0, 7)
    );
    this.fillYearsList();
  },
  mounted() {
    document.querySelector("main.v-main").classList.add("sticky-main-fix");
    this.getAllUsersData();
    if (this.isSmallBoard) {
      this.filterContractStatus = enums.CONTRACT_STATUS.Approved.value;
      this.getBigBoardMonthsDebounced();
    } else if (this.isMyBoard) {
      this.filterContractStatus = enums.CONTRACT_STATUS.Approved.value;
    }
  },
  beforeDestroy() {
    clearInterval(this.BbFreshnessTimer);
    clearInterval(this.lastUpdatedAgoTimer);
    document.querySelector("main.v-main").classList.remove("sticky-main-fix");
  },
  methods: {
    openCreateOffer() {
      this.$refs.editOffer.open(null);
    },
    openCreateShiftRequest() {
      this.$refs.editShiftRequest.open(null);
    },
    expandAll() {
      Object.keys(this.$refs).forEach((k) => {
        if (this.$refs[k] && !this.$refs[k].$attrs["table-group-data-open"]) {
          this.$refs[k].$el.click();
        }
      });
    },
    collapseAll() {
      Object.keys(this.$refs).forEach((k) => {
        if (this.$refs[k] && this.$refs[k].$attrs["table-group-data-open"]) {
          this.$refs[k].$el.click();
        }
      });
    },
    collapseAllExceptNet() {
      Object.keys(this.$refs).forEach((k) => {
        if (
          this.$refs[k] &&
          this.$refs[k].$attrs["table-group-data-open"] &&
          this.$refs[k].$attrs["table-group-labor-type"] !=
            enums.LABOR_TYPE.NetSummary.value.toString()
        ) {
          this.$refs[k].$el.click();
        }
      });
    },
    fillYearsList() {
      var currentYear = new Date().getFullYear();
      var lastYear = currentYear + 5;
      var firstYear = 2019;
      var totalYears = lastYear - firstYear + 1;
      for (let i = 0; i < totalYears; i++) {
        this.yearsList.push(firstYear + i);
      }
    },
    isNetSummarySpecial(item) {
      return (
        item.laborType == enums.LABOR_TYPE.NetSummary.value &&
        (item.rowType == "TotalNetCost" ||
          item.rowType == "TotalNetRevenue" ||
          item.rowType == "TotalNet")
      );
    },
    getEntryRangeSumClass(item) {
      var itemVal;
      if (this.filterContractStatus == null) itemVal = item.total;
      else if (this.filterContractStatus == enums.CONTRACT_STATUS.Approved.value)
        itemVal = item.totalApproved;
      else if (this.filterContractStatus == enums.CONTRACT_STATUS.Pending.value)
        itemVal = item.totalPending;

      if (itemVal > 0) return "val-positive";
      else if (itemVal < 0) return "val-negative";
      else if (itemVal == 0) return "val-zero";
    },
    getEntryHoursClass(item) {
      var itemVal;
      if (this.filterContractStatus == null) itemVal = item.totalHours;
      else if (this.filterContractStatus == enums.CONTRACT_STATUS.Approved.value)
        itemVal = item.totalApprovedHours;
      else if (this.filterContractStatus == enums.CONTRACT_STATUS.Pending.value)
        itemVal = item.totalPendingHours;
      return {
        "val-positive": itemVal > 0,
        "val-negative": itemVal < 0,
        "val-zero": itemVal == 0,
      };
    },
    getEntryRevenueClass(item) {
      var itemVal;
      if (this.filterContractStatus == null) itemVal = item.totalRevenue;
      else if (this.filterContractStatus == enums.CONTRACT_STATUS.Approved.value)
        itemVal = item.totalApprovedRevenue;
      else if (this.filterContractStatus == enums.CONTRACT_STATUS.Pending.value)
        itemVal = item.totalPendingRevenue;
      return {
        "val-positive": itemVal > 0,
        "val-negative": itemVal < 0,
        "val-zero": itemVal == 0,
      };
    },
    getEntryLaborClass(item) {
      var itemVal = 0;
      if (this.filterContractStatus == null) itemVal = item.totalLabor;
      else if (this.filterContractStatus == enums.CONTRACT_STATUS.Approved.value)
        itemVal = item.totalApprovedLabor;
      else if (this.filterContractStatus == enums.CONTRACT_STATUS.Pending.value)
        itemVal = item.totalPendingLabor;
      return {
        "val-positive": itemVal > 0,
        "val-negative": itemVal < 0,
        "val-zero": itemVal == 0,
      };
    },
    getEntryDirectExpensesClass(item) {
      var itemVal = 0;
      if (this.filterContractStatus == null) itemVal = item.totalExpenses;
      else if (this.filterContractStatus == enums.CONTRACT_STATUS.Approved.value)
        itemVal = item.totalApprovedExpenses;
      else if (this.filterContractStatus == enums.CONTRACT_STATUS.Pending.value)
        itemVal = item.totalPendingExpenses;
      return {
        "val-positive": itemVal > 0,
        "val-negative": itemVal < 0,
        "val-zero": itemVal == 0,
      };
    },
    getEntryNetSummaryClass(item) {
      var itemVal = 0;
      if (this.filterContractStatus == null) itemVal = item.total;
      else if (this.filterContractStatus == enums.CONTRACT_STATUS.Approved.value)
        itemVal = item.totalApproved;
      else if (this.filterContractStatus == enums.CONTRACT_STATUS.Pending.value)
        itemVal = item.totalPending;
      return {
        "val-positive": itemVal > 0,
        "val-negative": itemVal < 0,
        "val-zero": itemVal == 0,
      };
    },
    getCellClass(val) {
      return {
        "val-positive": val > 0,
        "val-negative": val < 0,
        "val-zero": val == 0,
      };
    },
    //entry = item[headerValue] => headerValue = 'rowSumTotal'
    viewRowTotalRecords(item, index, headerValue) {
      var entry = item[headerValue];
      if (!this.$has(this.Perms_ViewEntryDetails)) return;
      this.highlighted.index = index;
      this.highlighted.headerValue = headerValue;
      let recordsList = [];

      this.$log("viewRowTotalRecords > item", item, "entry", entry);

      if (item.rowType == "UserEntry") {
        recordsList = this.generateRowTotalUserEntryRecords(item, index, headerValue);
      } else if (
        item.laborType == enums.LABOR_TYPE.Hours.value ||
        item.laborType == enums.LABOR_TYPE.Revenue.value ||
        item.laborType == enums.LABOR_TYPE.Labor.value
      ) {
        recordsList = this.generateRowTotalHoursRevCostEntryRecords(item, index, headerValue);
      } else if (item.laborType == enums.LABOR_TYPE.DirectExpensesCost.value) {
        recordsList = this.generateRowTotalDirectExpensesEntryRecords(item, index, headerValue);
      } else if (item.laborType == enums.LABOR_TYPE.DirectExpensesRevenue.value) {
        recordsList = this.generateRowTotalDirectExpensesEntryRecords(item, index, headerValue);
      } else if (item.laborType == enums.LABOR_TYPE.NetSummary.value) {
        if (
          item.originalLaborType == enums.LABOR_TYPE.Labor.value ||
          item.originalLaborType == enums.LABOR_TYPE.Revenue.value
        ) {
          recordsList = this.generateRowTotalHoursRevCostEntryRecords(item, index, headerValue);
        } else if (item.originalLaborType == enums.LABOR_TYPE.DirectExpensesCost.value) {
          recordsList = this.generateRowTotalDirectExpensesEntryRecords(item, index, headerValue);
        } else if (item.originalLaborType == enums.LABOR_TYPE.DirectExpensesRevenue.value) {
          recordsList = this.generateRowTotalDirectExpensesEntryRecords(item, index, headerValue);
        }
      }
      var dateRangeStart = this.dynamicHeaders[0].text;
      var dateRangeEnd = this.dynamicHeaders[this.dynamicHeaders.length - 1].text;
      this.$refs.bigBoardEntryDetails.openRowTotal(
        item,
        dateRangeStart,
        dateRangeEnd,
        this.projectId,
        recordsList
      );
    },
    generateRowTotalUserEntryRecords(item, index, headerValue) {
      this.$log("generateRowTotalUserEntryRecords", item);
      let recordsList = [];
      let tempRecords = [];

      const rowItem = this.projectBigBoardMonths[index];
      this.dynamicHeaders.forEach((hdr) => {
        if (rowItem[hdr.value].records != null)
          tempRecords.push(...rowItem[hdr.value].records.slice());
      });

      for (let i = 0; i < tempRecords.length; i++) {
        const record = tempRecords[i];
        recordsList.push({
          record: record,
          contractId: record.contractId,
          constructionPhaseId: record.constructionPhaseId,
          hours: record.hours,
          sellCost: record.sellCost,
          laborCost: record.laborCost,
          total: this.isMyBoard ? record.laborCost * record.hours : record.sellCost * record.hours,
          contractStatus: null,
          contract: null,
          project: null,
          client: null,
          constructionPhase: null,
        });
      }
      return recordsList;
    },
    generateRowTotalHoursRevCostEntryRecords(item, index, headerValue) {
      var targetEntry = item[headerValue];
      this.$log("generateRowTotalHoursRevCostEntryRecords", item);

      let recordsList = [];
      let validEntries = [];

      this.originalMonths.forEach((targetMonth) => {
        if (targetMonth.entries != null)
          validEntries.push(
            ...targetMonth.entries
              .filter((e) => e.laborType == item.sumLaborType || item.sumLaborType == -1)
              .slice()
          );
      });

      for (let i = 0; i < validEntries.length; i++) {
        const entry = validEntries[i];
        if (entry.records == null) continue;
        for (let j = 0; j < entry.records.length; j++) {
          const record = entry.records[j];
          recordsList.push({
            record: record,
            contractId: record.contractId,
            constructionPhaseId: record.constructionPhaseId,
            hours: record.hours,
            sellCost: record.sellCost,
            laborCost: record.laborCost,
            total: this.isMyBoard
              ? record.laborCost * record.hours
              : record.sellCost * record.hours,
            contractStatus: record.contractStatus,
            contract: null,
            project: null,
            client: null,
            constructionPhase: null,
          });
        }
      }
      return recordsList;
    },
    generateRowTotalDirectExpensesEntryRecords(item, index, headerValue) {
      var targetEntry = item[headerValue];
      this.$log("generateRowTotal DirectExpenses EntryRecords", item);

      let validExpenses = [];
      this.originalMonths.forEach((targetMonth) => {
        if (targetMonth.expenses != null)
          validExpenses.push(
            ...targetMonth.expenses
              .filter((exp) => exp.category == item.sumCategory || item.sumCategory == -1)
              .slice()
          );
      });

      validExpenses = validExpenses.map((e) => {
        return {
          record: e,
          ...e,
        };
      });

      this.$log("validExpenses", validExpenses);
      return validExpenses;
    },
    //entry = item[header.value]
    viewRecords(item, index, headerValue) {
      var entry = item[headerValue];
      if (!this.$has(this.Perms_ViewEntryDetails)) return;
      this.highlighted.index = index;
      this.highlighted.headerValue = headerValue;
      let recordsList = [];
      if (entry.rowType == "UserEntry") {
        recordsList = this.generateUserEntryRecords(item, index, headerValue);
      } else if (
        entry.laborType == enums.LABOR_TYPE.Hours.value ||
        entry.laborType == enums.LABOR_TYPE.Revenue.value ||
        entry.laborType == enums.LABOR_TYPE.Labor.value
      ) {
        recordsList = this.generateHoursRevCostEntryRecords(item, index, headerValue);
      } else if (entry.laborType == enums.LABOR_TYPE.DirectExpensesCost.value) {
        recordsList = this.generateDirectExpensesEntryRecords(item, index, headerValue);
      } else if (entry.laborType == enums.LABOR_TYPE.DirectExpensesRevenue.value) {
        recordsList = this.generateDirectExpensesEntryRecords(item, index, headerValue);
      } else if (entry.laborType == enums.LABOR_TYPE.NetSummary.value) {
        if (
          entry.originalLaborType == enums.LABOR_TYPE.Labor.value ||
          entry.originalLaborType == enums.LABOR_TYPE.Revenue.value
        ) {
          recordsList = this.generateHoursRevCostEntryRecords(item, index, headerValue);
        } else if (entry.originalLaborType == enums.LABOR_TYPE.DirectExpensesCost.value) {
          recordsList = this.generateDirectExpensesEntryRecords(item, index, headerValue);
        } else if (entry.originalLaborType == enums.LABOR_TYPE.DirectExpensesRevenue.value) {
          recordsList = this.generateDirectExpensesEntryRecords(item, index, headerValue);
        }
      }
      this.$refs.bigBoardEntryDetails.open(entry, this.projectId, recordsList);
    },
    generateUserEntryRecords(item, index, headerValue) {
      var targetEntry = item[headerValue];
      this.$log("generateUserEntryRecords", headerValue, targetEntry);
      if (targetEntry.records == null) return [];
      let recordsList = [];
      for (let i = 0; i < targetEntry.records.length; i++) {
        const record = targetEntry.records[i];
        recordsList.push({
          record: record,
          contractId: record.contractId,
          constructionPhaseId: record.constructionPhaseId,
          hours: record.hours,
          sellCost: record.sellCost,
          laborCost: record.laborCost,
          total: this.isMyBoard ? record.laborCost * record.hours : record.sellCost * record.hours,
          contractStatus: null,
          contract: null,
          project: null,
          client: null,
          constructionPhase: null,
        });
      }
      return recordsList;
    },
    generateHoursRevCostEntryRecords(item, index, headerValue) {
      var targetEntry = item[headerValue];
      var targetMonth = this.originalMonths.find((m) => m.date == targetEntry.parentDate.date);
      this.$log(
        "generateHoursRevCostEntryRecords, targetMonth",
        targetMonth,
        "targetEntry",
        targetEntry
      );

      let recordsList = [];
      let validEntries = targetMonth.entries.filter(
        (e) => e.laborType == targetEntry.sumLaborType || targetEntry.sumLaborType == -1
      );

      for (let i = 0; i < validEntries.length; i++) {
        const entry = validEntries[i];
        if (entry.records == null) continue;
        for (let j = 0; j < entry.records.length; j++) {
          const record = entry.records[j];
          recordsList.push({
            record: record,
            contractId: record.contractId,
            constructionPhaseId: record.constructionPhaseId,
            hours: record.hours,
            sellCost: record.sellCost,
            laborCost: record.laborCost,
            total: this.isMyBoard
              ? record.laborCost * record.hours
              : record.sellCost * record.hours,
            contractStatus: record.contractStatus,
            contract: null,
            project: null,
            client: null,
            constructionPhase: null,
          });
        }
      }
      this.$log("recordsList", recordsList);
      return recordsList;
    },
    generateDirectExpensesEntryRecords(item, index, headerValue) {
      var targetEntry = item[headerValue];
      var targetMonth = this.originalMonths.find((m) => m.date == targetEntry.parentDate.date);
      this.$log(
        "generateDirectExpensesEntryRecords, targetMonth",
        targetMonth,
        "targetEntry",
        targetEntry
      );

      let validExpenses = targetMonth.expenses.filter(
        (exp) => exp.category == targetEntry.sumCategory || targetEntry.sumCategory == -1
      );
      validExpenses = validExpenses.map((e) => {
        return {
          record: e,
          ...e,
        };
      });

      this.$log("validExpenses", validExpenses);
      return validExpenses;
    },
    getLaborTypeObj(val) {
      return this.getEnumMember(this.laborTypesList, val);
    },
    getExpenseCategoryObj(val) {
      return this.getEnumMember(this.expenseCategoriesList, val);
    },
    getAllUsersData() {
      this.isUsersLoading = true;
      usersAPI
        .typeHead(null, true)
        .then((resp) => {
          this.users = resp.data;
          this.isUsersLoading = false;
        })
        .catch(() => {
          this.isUsersLoading = false;
        });
    },
    getBigBoardMonthsDebounced() {
      if (this.boardTimerId == null) {
        this.boardTimerId = -1;
        this.getBigBoardMonths();
        return;
      }
      // cancel pending call
      clearTimeout(this.boardTimerId);

      // delay new call 400ms
      this.boardTimerId = setTimeout(() => {
        this.getBigBoardMonths();
      }, 400);
    },
    getBigBoardMonths() {
      this.loadingBigBoard = true;
      this.loadingBigBoardError = false;
      this.highlighted.index = null;
      this.highlighted.headerValue = null;
      clearInterval(this.BbFreshnessTimer);
      clearInterval(this.lastUpdatedAgoTimer);
      this.generateDate = null;
      if (this.isBigBoard) {
        bigBoardService
          .GetByRange(this.$clean(this.options, true))
          .then((resp) => {
            this.loadingBigBoard = false;
            this.prepareBigBoardData(resp.data);
          })
          .catch((err) => {
            this.loadingBigBoard = false;
            this.loadingBigBoardError = true;
            // this.$handleError(err);
          });
      } else if (this.isMyBoard) {
        bigBoardService
          .GetForCurrentUser(this.$clean(this.options, true))
          .then((resp) => {
            this.loadingBigBoard = false;
            this.prepareBigBoardData(resp.data);
          })
          .catch((err) => {
            this.loadingBigBoard = false;
            this.loadingBigBoardError = true;
            // this.$handleError(err);
          });
      } else if (this.isSmallBoard) {
        bigBoardService
          .GetByProject(
            this.$clean(
              {
                projectId: this.projectId,
                contractStatus: this.options.contractStatus,
              },
              true
            )
          )
          .then((resp) => {
            this.loadingBigBoard = false;
            this.prepareBigBoardData(resp.data);
          })
          .catch((err) => {
            this.loadingBigBoard = false;
            this.loadingBigBoardError = true;
            // this.$handleError(err);
          });
      }
    },
    getFirstDayOfMonth(year, month) {
      return new Date(year, month, 1);
    },
    getLastDayOfMonth(year, month) {
      return new Date(year, month + 1, 0);
    },
    updateBbFreshness() {
      if (this.BbFreshness == this.FRESHNESS.Fresh) this.BbFreshness = this.FRESHNESS.Due;
      else if (this.BbFreshness == this.FRESHNESS.Due) {
        this.BbFreshness = this.FRESHNESS.Expired;
        clearInterval(this.BbFreshnessTimer);
      }
    },
    updateLastUpdatedAgo() {
      if (this.loadingBigBoard || this.generateDate == null) return;
      this.lastUpdatedAgo = moment(this.generateDate).fromNow();
      // this.$log("lastUpdatedAgo", this.lastUpdatedAgo);
    },
    prepareBigBoardData(data) {
      this.generateDate = Date.now();

      this.BbFreshness = this.FRESHNESS.Fresh;
      this.BbFreshnessTimer = setInterval(this.updateBbFreshness.bind(this), this.BbFreshnessStep);

      this.lastUpdatedAgo = null;
      this.lastUpdatedAgoTimer = setInterval(
        this.updateLastUpdatedAgo.bind(this),
        this.lastUpdatedAgoStep
      );
      this.updateLastUpdatedAgo();

      if (data == null || data.length == 0) {
        this.projectBigBoardMonths = [];
        return;
      }

      this.projectBigBoardMonths = data.sort(
        (objA, objB) => Number(new Date(objA.date)) - Number(new Date(objB.date))
      );

      // fill the missing in between months
      for (let i = 0; i < this.projectBigBoardMonths.length; ) {
        //if there's no adjacent month
        if (i + 1 > this.projectBigBoardMonths.length - 1) break;
        const monthA = this.projectBigBoardMonths[i].date;
        const monthB = this.projectBigBoardMonths[i + 1].date;
        var list = this.monthDiffList(new Date(monthA), new Date(monthB));
        for (let j = 0; j < list.length - 1; j++) {
          var dummyMonth = this.cloneDeep(this.projectBigBoardMonths[i]);
          dummyMonth.date = this.formatDate(list[j]);
          dummyMonth.entries.forEach((entry, index) => {
            dummyMonth.entries[index] = {
              parentDate: {
                date: dummyMonth.date,
                year: `${new Date(`${dummyMonth.date}-01T00:00:00`).getFullYear()}`,
                month: this.MonthNames[new Date(`${dummyMonth.date}-01T00:00:00`).getMonth()],
              },
              userId: entry.userId,
              laborType: entry.laborType,
              recordType: entry.recordType,
              placeholderName: entry.placeholderName,
              totalHours: 0,
              totalPendingHours: 0,
              totalApprovedHours: 0,
              records: [],
            };
          });
          dummyMonth.expenses = [];
          this.projectBigBoardMonths.splice(i + j + 1, 0, dummyMonth);
        }
        i = i + list.length;
      }

      //fill missing start months
      const firstMonth = new Date(this.projectBigBoardMonths[0].date);
      var startDate = this.getFirstDayOfMonth(
        this.getDateObj(this.options.startDate).getFullYear(),
        this.getDateObj(this.options.startDate).getMonth()
      );
      startDate.setMonth(startDate.getMonth() - 1);
      firstMonth.setMonth(firstMonth.getMonth() - 1);
      var list = this.monthDiffList(startDate, firstMonth);
      for (let j = list.length - 1; j >= 0; j--) {
        var dummyMonth = this.cloneDeep(this.projectBigBoardMonths[0]);
        dummyMonth.date = this.formatDate(list[j]);
        dummyMonth.entries.forEach((entry, index) => {
          dummyMonth.entries[index] = {
            parentDate: {
              date: dummyMonth.date,
              year: `${new Date(`${dummyMonth.date}-01T00:00:00`).getFullYear()}`,
              month: this.MonthNames[new Date(`${dummyMonth.date}-01T00:00:00`).getMonth()],
            },
            userId: entry.userId,
            laborType: entry.laborType,
            recordType: entry.recordType,
            placeholderName: entry.placeholderName,
            totalHours: 0,
            totalPendingHours: 0,
            totalApprovedHours: 0,
            records: [],
          };
        });
        dummyMonth.expenses = [];
        this.projectBigBoardMonths.unshift(dummyMonth);
      }

      //fill missing end months
      const lastMonth = new Date(
        this.projectBigBoardMonths[this.projectBigBoardMonths.length - 1].date
      );
      var endDate = this.getFirstDayOfMonth(
        this.getDateObj(this.options.endDate).getFullYear(),
        this.getDateObj(this.options.endDate).getMonth()
      );
      endDate.setMonth(endDate.getMonth());
      lastMonth.setMonth(lastMonth.getMonth());
      list = this.monthDiffList(lastMonth, endDate);
      for (let j = 0; j <= list.length - 1; j++) {
        var dummyMonth = this.cloneDeep(
          this.projectBigBoardMonths[this.projectBigBoardMonths.length - 1]
        );
        dummyMonth.date = this.formatDate(list[j]);
        dummyMonth.entries.forEach((entry, index) => {
          dummyMonth.entries[index] = {
            parentDate: {
              date: dummyMonth.date,
              year: `${new Date(`${dummyMonth.date}-01T00:00:00`).getFullYear()}`,
              month: this.MonthNames[new Date(`${dummyMonth.date}-01T00:00:00`).getMonth()],
            },
            userId: entry.userId,
            laborType: entry.laborType,
            recordType: entry.recordType,
            placeholderName: entry.placeholderName,
            totalHours: 0,
            totalPendingHours: 0,
            totalApprovedHours: 0,
            records: [],
          };
        });
        dummyMonth.expenses = [];
        this.projectBigBoardMonths.push(dummyMonth);
      }

      // update the month date format
      this.projectBigBoardMonths.forEach((month) => {
        month.date = month.date.substr(0, 7);
      });

      //Totals prepare => calc revenue/labor/hours totals per month
      for (let i = 0; i < this.projectBigBoardMonths.length; i++) {
        const month = this.projectBigBoardMonths[i];

        //total labor revenue
        var totalRevenue = month.entries.reduce((monthTotal, entry) => {
          var entryTotal =
            entry.records == null
              ? 0
              : entry.records.reduce((recordTotal, record) => {
                  return recordTotal + record.hours * record.sellCost;
                }, 0);
          return monthTotal + entryTotal;
        }, 0);
        var totalPendingRevenue = month.entries.reduce((monthTotal, entry) => {
          var entryTotal =
            entry.records == null
              ? 0
              : entry.records
                  .filter(
                    (r) =>
                      r.contractStatus == enums.CONTRACT_STATUS.Pending.value ||
                      r.contractStatus == enums.CONTRACT_STATUS.Draft.value
                  )
                  .reduce((recordTotal, record) => {
                    return recordTotal + record.hours * record.sellCost;
                  }, 0);
          return monthTotal + entryTotal;
        }, 0);
        var totalApprovedRevenue = month.entries.reduce((monthTotal, entry) => {
          var entryTotal =
            entry.records == null
              ? 0
              : entry.records
                  .filter((r) => r.contractStatus == enums.CONTRACT_STATUS.Approved.value)
                  .reduce((recordTotal, record) => {
                    return recordTotal + record.hours * record.sellCost;
                  }, 0);
          return monthTotal + entryTotal;
        }, 0);
        month.revenue = [
          {
            laborType: enums.LABOR_TYPE.Revenue.value,
            sumLaborType: -1,
            rowType: "TotalMonthLaborRevenue",
            title: "Total Labor Revenue",
            class: "group-total",
            totalRevenue: totalRevenue,
            totalPendingRevenue: totalPendingRevenue,
            totalApprovedRevenue: totalApprovedRevenue,
          },
        ];

        //total labor cost
        var totalLabor = month.entries.reduce((monthTotal, entry) => {
          var entryTotal =
            entry.records == null
              ? 0
              : entry.records.reduce((recordTotal, record) => {
                  return recordTotal + record.hours * record.laborCost;
                }, 0);
          return monthTotal + entryTotal;
        }, 0);
        var totalPendingLabor = month.entries.reduce((monthTotal, entry) => {
          var entryTotal =
            entry.records == null
              ? 0
              : entry.records
                  .filter(
                    (r) =>
                      r.contractStatus == enums.CONTRACT_STATUS.Pending.value ||
                      r.contractStatus == enums.CONTRACT_STATUS.Draft.value
                  )
                  .reduce((recordTotal, record) => {
                    return recordTotal + record.hours * record.laborCost;
                  }, 0);
          return monthTotal + entryTotal;
        }, 0);
        var totalApprovedLabor = month.entries.reduce((monthTotal, entry) => {
          var entryTotal =
            entry.records == null
              ? 0
              : entry.records
                  .filter((r) => r.contractStatus == enums.CONTRACT_STATUS.Approved.value)
                  .reduce((recordTotal, record) => {
                    return recordTotal + record.hours * record.laborCost;
                  }, 0);
          return monthTotal + entryTotal;
        }, 0);
        month.labor = [
          {
            laborType: enums.LABOR_TYPE.Labor.value,
            sumLaborType: -1,
            rowType: "TotalMonthLaborCost",
            title: "Total Labor Cost",
            class: "group-total",
            totalLabor: totalLabor,
            totalPendingLabor: totalPendingLabor,
            totalApprovedLabor: totalApprovedLabor,
          },
        ];

        //total hours
        var totalHours = month.entries.reduce((monthTotal, entry) => {
          return monthTotal + entry.totalHours;
        }, 0);
        var totalPendingHours = month.entries.reduce((monthTotal, entry) => {
          return monthTotal + entry.totalPendingHours;
        }, 0);
        var totalApprovedHours = month.entries.reduce((monthTotal, entry) => {
          return monthTotal + entry.totalApprovedHours;
        }, 0);
        month.hours = [
          {
            laborType: enums.LABOR_TYPE.Hours.value,
            sumLaborType: -1,
            sumCategory: -1,
            rowType: "TotalMonthHours",
            title: "Total Month Hours",
            class: "group-total",
            colSpan: 1,
            month: month,
            totalHours: totalHours,
            totalPendingHours: totalPendingHours,
            totalApprovedHours: totalApprovedHours,
          },
        ];

        //total direct expenses (cost)
        var totalExpensesCost = month.expenses.reduce((monthTotal, entry) => {
          return monthTotal + entry.laborCost;
        }, 0);
        var totalPendingExpensesCost = month.expenses.reduce((monthTotal, entry) => {
          var entryLaborCost =
            entry.contractStatus == enums.CONTRACT_STATUS.Pending.value ||
            entry.contractStatus == enums.CONTRACT_STATUS.Draft.value
              ? entry.laborCost
              : 0;
          return monthTotal + entryLaborCost;
        }, 0);
        var totalApprovedExpensesCost = month.expenses.reduce((monthTotal, entry) => {
          var entryLaborCost =
            entry.contractStatus == enums.CONTRACT_STATUS.Approved.value ? entry.laborCost : 0;
          return monthTotal + entryLaborCost;
        }, 0);
        month.directExpensesCost = [
          {
            laborType: enums.LABOR_TYPE.DirectExpensesCost.value,
            isCost: true,
            sumCategory: -1,
            rowType: "TotalDirectExpensesCost",
            title: "Total Expenses Cost",
            class: "group-total",
            colSpan: 1,
            totalExpenses: totalExpensesCost,
            totalPendingExpenses: totalPendingExpensesCost,
            totalApprovedExpenses: totalApprovedExpensesCost,
          },
        ];

        //total direct expenses (revenue)
        var totalExpensesRevenue = month.expenses.reduce((monthTotal, entry) => {
          return monthTotal + entry.sellCost;
        }, 0);
        var totalPendingExpensesRevenue = month.expenses.reduce((monthTotal, entry) => {
          var entrySellCost =
            entry.contractStatus == enums.CONTRACT_STATUS.Pending.value ||
            entry.contractStatus == enums.CONTRACT_STATUS.Draft.value
              ? entry.sellCost
              : 0;
          return monthTotal + entrySellCost;
        }, 0);
        var totalApprovedExpensesRevenue = month.expenses.reduce((monthTotal, entry) => {
          var entrySellCost =
            entry.contractStatus == enums.CONTRACT_STATUS.Approved.value ? entry.sellCost : 0;
          return monthTotal + entrySellCost;
        }, 0);

        month.directExpensesRevenue = [
          {
            laborType: enums.LABOR_TYPE.DirectExpensesRevenue.value,
            isCost: false,
            sumCategory: -1,
            rowType: "TotalDirectExpensesRevenue",
            title: "Total Expenses Revenue",
            class: "group-total",
            colSpan: 1,
            totalExpenses: totalExpensesRevenue,
            totalPendingExpenses: totalPendingExpensesRevenue,
            totalApprovedExpenses: totalApprovedExpensesRevenue,
          },
        ];

        month.net = [];

        //total net summary
        if (this.$has(this.Perms_ViewLaborCostSummary))
          month.net.push({
            laborType: enums.LABOR_TYPE.NetSummary.value,
            originalLaborType: enums.LABOR_TYPE.Labor.value,
            sumLaborType: -1,
            rowType: "TotalMonthLaborCost",
            title: "Total Labor Cost",
            class: "group-total",
            total: totalLabor,
            totalPending: totalPendingLabor,
            totalApproved: totalApprovedLabor,
          });

        if (this.$has(this.Perms_ViewDirectExpensesCostSummary))
          month.net.push({
            laborType: enums.LABOR_TYPE.NetSummary.value,
            originalLaborType: enums.LABOR_TYPE.DirectExpensesCost.value,
            isCost: true,
            sumCategory: -1,
            rowType: "TotalDirectExpensesCost",
            title: "Total Expenses Cost",
            class: "group-total",
            total: totalExpensesCost,
            totalPending: totalPendingExpensesCost,
            totalApproved: totalApprovedExpensesCost,
          });

        if (this.$has(this.Perms_ViewNetSummaryTotalCost))
          month.net.push({
            laborType: enums.LABOR_TYPE.NetSummary.value,
            rowType: "TotalNetCost",
            title: "Total Cost",
            class: "group-total-dark",
            total: totalLabor + totalExpensesCost,
            totalPending: totalPendingLabor + totalPendingExpensesCost,
            totalApproved: totalApprovedLabor + totalApprovedExpensesCost,
          });

        if (this.$has(this.Perms_ViewLaborRevenueSummary))
          month.net.push({
            laborType: enums.LABOR_TYPE.NetSummary.value,
            originalLaborType: enums.LABOR_TYPE.Revenue.value,
            sumLaborType: -1,
            rowType: "TotalMonthLaborRevenue",
            title: "Total Labor Revenue",
            class: "group-total",
            total: totalRevenue,
            totalPending: totalPendingRevenue,
            totalApproved: totalApprovedRevenue,
          });

        if (this.$has(this.Perms_ViewDirectExpensesRevenueSummary))
          month.net.push({
            laborType: enums.LABOR_TYPE.NetSummary.value,
            originalLaborType: enums.LABOR_TYPE.DirectExpensesRevenue.value,
            isCost: false,
            sumCategory: -1,
            rowType: "TotalDirectExpensesRevenue",
            title: "Total Expenses Revenue",
            class: "group-total",
            total: totalExpensesRevenue,
            totalPending: totalPendingExpensesRevenue,
            totalApproved: totalApprovedExpensesRevenue,
          });

        if (this.$has(this.Perms_ViewNetSummaryTotalRevenue))
          month.net.push({
            laborType: enums.LABOR_TYPE.NetSummary.value,
            rowType: "TotalNetRevenue",
            title: "Total Revenue",
            class: "group-total-dark",
            total: totalRevenue + totalExpensesRevenue,
            totalPending: totalPendingRevenue + totalPendingExpensesRevenue,
            totalApproved: totalApprovedRevenue + totalApprovedExpensesRevenue,
          });

        if (this.$has(this.Perms_ViewNetSummaryNetProfit))
          month.net.push({
            laborType: enums.LABOR_TYPE.NetSummary.value,
            rowType: "TotalNet",
            title: "Net Profit",
            class: "group-total-dark",
            total: totalRevenue + totalExpensesRevenue - (totalLabor + totalExpensesCost),
            totalPending:
              totalPendingRevenue +
              totalPendingExpensesRevenue -
              (totalPendingLabor + totalPendingExpensesCost),
            totalApproved:
              totalApprovedRevenue +
              totalApprovedExpensesRevenue -
              (totalApprovedLabor + totalApprovedExpensesCost),
          });

        //generate totals for revenue/labor/hours for each labor type group
        var laborTypesGroups = groupBy(month.entries, (e) => e.laborType);
        for (const [key, value] of Object.entries(laborTypesGroups)) {
          var laborType = this.getLaborTypeObj(Number.parseInt(key));

          //total revenue
          var laborTotalRevenue = value.reduce((monthTotal, entry) => {
            var entryTotal =
              entry.records == null
                ? 0
                : entry.records.reduce((recordTotal, record) => {
                    return recordTotal + record.hours * record.sellCost;
                  }, 0);
            return monthTotal + entryTotal;
          }, 0);
          var laborTotalPendingRevenue = value.reduce((monthTotal, entry) => {
            var entryTotal =
              entry.records == null
                ? 0
                : entry.records
                    .filter(
                      (r) =>
                        r.contractStatus == enums.CONTRACT_STATUS.Pending.value ||
                        r.contractStatus == enums.CONTRACT_STATUS.Draft.value
                    )
                    .reduce((recordTotal, record) => {
                      return recordTotal + record.hours * record.sellCost;
                    }, 0);
            return monthTotal + entryTotal;
          }, 0);
          var laborTotalApprovedRevenue = value.reduce((monthTotal, entry) => {
            var entryTotal =
              entry.records == null
                ? 0
                : entry.records
                    .filter((r) => r.contractStatus == enums.CONTRACT_STATUS.Approved.value)
                    .reduce((recordTotal, record) => {
                      return recordTotal + record.hours * record.sellCost;
                    }, 0);
            return monthTotal + entryTotal;
          }, 0);
          month.revenue.push({
            laborType: enums.LABOR_TYPE.Revenue.value,
            sumLaborType: laborType.value,
            rowType: `${laborType.text}_LaborTotalRevenue`,
            title: `${laborType.desc} Total Labor Revenue`,
            colSpan: 1,
            totalRevenue: laborTotalRevenue,
            totalPendingRevenue: laborTotalPendingRevenue,
            totalApprovedRevenue: laborTotalApprovedRevenue,
          });

          //total labor
          var laborTotalLabor = value.reduce((monthTotal, entry) => {
            var entryTotal =
              entry.records == null
                ? 0
                : entry.records.reduce((recordTotal, record) => {
                    return recordTotal + record.hours * record.laborCost;
                  }, 0);
            return monthTotal + entryTotal;
          }, 0);
          var laborTotalPendingLabor = value.reduce((monthTotal, entry) => {
            var entryTotal =
              entry.records == null
                ? 0
                : entry.records
                    .filter(
                      (r) =>
                        r.contractStatus == enums.CONTRACT_STATUS.Pending.value ||
                        r.contractStatus == enums.CONTRACT_STATUS.Draft.value
                    )
                    .reduce((recordTotal, record) => {
                      return recordTotal + record.hours * record.laborCost;
                    }, 0);
            return monthTotal + entryTotal;
          }, 0);
          var laborTotalApprovedLabor = value.reduce((monthTotal, entry) => {
            var entryTotal =
              entry.records == null
                ? 0
                : entry.records
                    .filter((r) => r.contractStatus == enums.CONTRACT_STATUS.Approved.value)
                    .reduce((recordTotal, record) => {
                      return recordTotal + record.hours * record.laborCost;
                    }, 0);
            return monthTotal + entryTotal;
          }, 0);
          month.labor.push({
            laborType: enums.LABOR_TYPE.Labor.value,
            sumLaborType: laborType.value,
            rowType: `${laborType.text}_LaborTotalCost`,
            title: `${laborType.desc} Total Labor Cost`,
            colSpan: 1,
            totalLabor: laborTotalLabor,
            totalPendingLabor: laborTotalPendingLabor,
            totalApprovedLabor: laborTotalApprovedLabor,
          });

          //total hours
          var laborTotalHours = value.reduce((monthTotal, entry) => {
            return monthTotal + entry.totalHours;
          }, 0);
          var laborTotalPendingHours = value.reduce((monthTotal, entry) => {
            return monthTotal + entry.totalPendingHours;
          }, 0);
          var laborTotalApprovedHours = value.reduce((monthTotal, entry) => {
            return monthTotal + entry.totalApprovedHours;
          }, 0);
          month.hours.push({
            laborType: enums.LABOR_TYPE.Hours.value,
            sumLaborType: laborType.value,
            rowType: `${laborType.text}_LaborTotalHours`,
            title: `${laborType.desc} Total Hours`,
            totalHours: laborTotalHours,
            totalPendingHours: laborTotalPendingHours,
            totalApprovedHours: laborTotalApprovedHours,
          });
        }

        //generate totals for expenses (cost) for each expenses category
        for (let i = 0; i < this.expenseCategoriesList.length; i++) {
          const expenseCategory = this.expenseCategoriesList[i];
          var expensesCatList = month.expenses.filter(
            (exp) => exp.category == expenseCategory.value
          );
          //total cost
          var categoryTotalCost = expensesCatList.reduce((monthTotal, entry) => {
            return monthTotal + entry.laborCost;
          }, 0);
          var categoryTotalPendingCost = expensesCatList.reduce((monthTotal, entry) => {
            var entryLaborCost =
              entry.contractStatus == enums.CONTRACT_STATUS.Pending.value ||
              entry.contractStatus == enums.CONTRACT_STATUS.Draft.value
                ? entry.laborCost
                : 0;
            return monthTotal + entryLaborCost;
          }, 0);
          var categoryTotalApprovedCost = expensesCatList.reduce((monthTotal, entry) => {
            var entryLaborCost =
              entry.contractStatus == enums.CONTRACT_STATUS.Approved.value ? entry.laborCost : 0;
            return monthTotal + entryLaborCost;
          }, 0);
          month.directExpensesCost.push({
            laborType: enums.LABOR_TYPE.DirectExpensesCost.value,
            isCost: true,
            sumCategory: expenseCategory.value,
            rowType: `${expenseCategory.text}_TotalDirectExpensesCost`,
            title: `${expenseCategory.text} Expenses Total Cost`,
            colSpan: 1,
            totalExpenses: categoryTotalCost,
            totalPendingExpenses: categoryTotalPendingCost,
            totalApprovedExpenses: categoryTotalApprovedCost,
          });
        }

        //generate totals for expenses (revenue) for each expenses category
        for (let i = 0; i < this.expenseCategoriesList.length; i++) {
          const expenseCategory = this.expenseCategoriesList[i];
          var expensesCatList = month.expenses.filter(
            (exp) => exp.category == expenseCategory.value
          );
          //total revenue
          var categoryTotalRevenue = expensesCatList.reduce((monthTotal, entry) => {
            return monthTotal + entry.sellCost;
          }, 0);
          var categoryTotalPendingRevenue = expensesCatList.reduce((monthTotal, entry) => {
            var entrySellCost =
              entry.contractStatus == enums.CONTRACT_STATUS.Pending.value ||
              entry.contractStatus == enums.CONTRACT_STATUS.Draft.value
                ? entry.sellCost
                : 0;
            return monthTotal + entrySellCost;
          }, 0);
          var categoryTotalApprovedRevenue = expensesCatList.reduce((monthTotal, entry) => {
            var entrySellCost =
              entry.contractStatus == enums.CONTRACT_STATUS.Approved.value ? entry.sellCost : 0;
            return monthTotal + entrySellCost;
          }, 0);
          month.directExpensesRevenue.push({
            laborType: enums.LABOR_TYPE.DirectExpensesRevenue.value,
            isCost: false,
            sumCategory: expenseCategory.value,
            rowType: `${expenseCategory.text}_TotalDirectExpensesRevenue`,
            title: `${expenseCategory.text} Expenses Total Revenue`,
            colSpan: 1,
            totalExpenses: categoryTotalRevenue,
            totalPendingExpenses: categoryTotalPendingRevenue,
            totalApprovedExpenses: categoryTotalApprovedRevenue,
          });
        }
      }

      //preserve the original month data structure
      this.originalMonths = this.cloneDeep(this.projectBigBoardMonths);

      this.headers = this.projectBigBoardMonths.map((m, i) => {
        var generatedFirstDayOfMonth = new Date(`${m.date}-01T00:00:00`);
        return {
          text: m.date,
          value: `hours-${m.date}`,
          year: `${generatedFirstDayOfMonth.getFullYear()}`,
          month: this.MonthNames[generatedFirstDayOfMonth.getMonth()],
          width: "84px",
          class: "pl-2",
          cellClass: "px-0",
          type: "date",
          sortable: false,
        };
      });
      this.headers.unshift({
        text: "Row Total",
        value: "rowSumTotal",
        width: "84px",
        class: "pl-2",
        cellClass: "px-0",
        type: "normal",
        sortable: false,
      });
      this.headers.unshift({
        text: "Resource",
        value: "userId",
        width: "275px",
        class: "pl-2",
        type: "normal",
        cellClass: "pl-2",
        sortable: false,
      });

      //restructure the board to be like a table
      //basically from a one-d list of months to 2d table => rows and columns
      var flatList = [];
      for (let i = 0; i < this.projectBigBoardMonths.length; i++) {
        const month = this.projectBigBoardMonths[i];

        // User Entries > Broken by Labor Type
        for (let j = 0; j < month.entries.length; j++) {
          const entry = this.cloneDeep(month.entries[j]);

          //Add Row for User Entry
          let targetUserResource = null;
          if (entry.recordType == 0)
            targetUserResource = flatList.find(
              (e) => e.userId == entry.userId && e.laborType == entry.laborType
            );
          else
            targetUserResource = flatList.find(
              (e) => e.placeholderName == entry.placeholderName && e.laborType == entry.laborType
            );

          if (targetUserResource) {
            //Already Added Resource, add NEW HEADER prop value
            targetUserResource[`hours-${month.date}`] = {
              class: entry.class,
              rowType: "UserEntry",
              userId: entry.userId,
              laborType: entry.laborType,
              recordType: entry.recordType,
              placeholderName: entry.placeholderName,
              parentDate: {
                date: month.date,
                year: `${new Date(`${month.date}-01T00:00:00`).getFullYear()}`,
                month: this.MonthNames[new Date(`${month.date}-01T00:00:00`).getMonth()],
              },
              totalHours: entry.totalHours,
              totalPendingHours: entry.totalPendingHours,
              totalApprovedHours: entry.totalApprovedHours,
              records: this.cloneDeep(entry.records),
            };
          } else {
            //New Resource, add header prop value, then add as new resource
            entry.rowType = "UserEntry";
            entry[`hours-${month.date}`] = {
              class: entry.class,
              rowType: "UserEntry",
              userId: entry.userId,
              laborType: entry.laborType,
              recordType: entry.recordType,
              placeholderName: entry.placeholderName,
              parentDate: {
                date: month.date,
                year: `${new Date(`${month.date}-01T00:00:00`).getFullYear()}`,
                month: this.MonthNames[new Date(`${month.date}-01T00:00:00`).getMonth()],
              },
              totalHours: entry.totalHours,
              totalPendingHours: entry.totalPendingHours,
              totalApprovedHours: entry.totalApprovedHours,
              records: this.cloneDeep(entry.records),
            };
            flatList.push(entry);
          }
        }

        // Revenue Summary
        for (let j = 0; j < month.revenue.length; j++) {
          const entry = this.cloneDeep(month.revenue[j]);

          //Add Row for User Entry
          const targetResource = flatList.find(
            (e) => e.rowType == entry.rowType && e.laborType == entry.laborType
          );
          if (targetResource) {
            //Already Added Resource, add NEW HEADER prop value
            targetResource[`hours-${month.date}`] = {
              class: entry.class,
              laborType: entry.laborType,
              sumLaborType: entry.sumLaborType,
              rowType: entry.rowType,
              title: entry.title,
              totalRevenue: entry.totalRevenue,
              totalPendingRevenue: entry.totalPendingRevenue,
              totalApprovedRevenue: entry.totalApprovedRevenue,
              parentDate: {
                date: month.date,
                year: `${new Date(month.date).getFullYear()}`,
                month: this.MonthNames[new Date(month.date).getMonth()],
              },
            };
          } else {
            //New Resource, add header prop value, then add as new resource
            entry[`hours-${month.date}`] = {
              class: entry.class,
              laborType: entry.laborType,
              sumLaborType: entry.sumLaborType,
              rowType: entry.rowType,
              title: entry.title,
              totalRevenue: entry.totalRevenue,
              totalPendingRevenue: entry.totalPendingRevenue,
              totalApprovedRevenue: entry.totalApprovedRevenue,
              parentDate: {
                date: month.date,
                year: `${new Date(month.date).getFullYear()}`,
                month: this.MonthNames[new Date(month.date).getMonth()],
              },
            };
            flatList.push(entry);
          }
        }

        // Labor Summary
        for (let j = 0; j < month.labor.length; j++) {
          const entry = this.cloneDeep(month.labor[j]);

          //Add Row for User Entry
          const targetResource = flatList.find(
            (e) => e.rowType == entry.rowType && e.laborType == entry.laborType
          );
          if (targetResource) {
            //Already Added Resource, add NEW HEADER prop value
            targetResource[`hours-${month.date}`] = {
              class: entry.class,
              laborType: entry.laborType,
              sumLaborType: entry.sumLaborType,
              rowType: entry.rowType,
              title: entry.title,
              totalLabor: entry.totalLabor,
              totalPendingLabor: entry.totalPendingLabor,
              totalApprovedLabor: entry.totalApprovedLabor,
              parentDate: {
                date: month.date,
                year: `${new Date(month.date).getFullYear()}`,
                month: this.MonthNames[new Date(month.date).getMonth()],
              },
            };
          } else {
            //New Resource, add header prop value, then add as new resource
            entry[`hours-${month.date}`] = {
              class: entry.class,
              laborType: entry.laborType,
              sumLaborType: entry.sumLaborType,
              rowType: entry.rowType,
              title: entry.title,
              totalLabor: entry.totalLabor,
              totalPendingLabor: entry.totalPendingLabor,
              totalApprovedLabor: entry.totalApprovedLabor,
              parentDate: {
                date: month.date,
                year: `${new Date(month.date).getFullYear()}`,
                month: this.MonthNames[new Date(month.date).getMonth()],
              },
            };
            flatList.push(entry);
          }
        }

        // Hours Summary
        for (let j = 0; j < month.hours.length; j++) {
          const entry = this.cloneDeep(month.hours[j]);
          //Add Row for User Entry
          const targetResource = flatList.find(
            (e) => e.rowType == entry.rowType && e.laborType == entry.laborType
          );
          if (targetResource) {
            //Already Added Resource, add NEW HEADER prop value
            targetResource[`hours-${month.date}`] = {
              class: entry.class,
              laborType: entry.laborType,
              sumLaborType: entry.sumLaborType,
              rowType: entry.rowType,
              title: entry.title,
              totalHours: entry.totalHours,
              totalPendingHours: entry.totalPendingHours,
              totalApprovedHours: entry.totalApprovedHours,
              parentDate: {
                date: month.date,
                year: `${new Date(month.date).getFullYear()}`,
                month: this.MonthNames[new Date(month.date).getMonth()],
              },
            };
          } else {
            //New Resource, add header prop value, then add as new resource
            entry[`hours-${month.date}`] = {
              class: entry.class,
              laborType: entry.laborType,
              sumLaborType: entry.sumLaborType,
              rowType: entry.rowType,
              colSpan: entry.colSpan ?? 1,
              title: entry.title,
              totalHours: entry.totalHours,
              totalPendingHours: entry.totalPendingHours,
              totalApprovedHours: entry.totalApprovedHours,
              parentDate: {
                date: month.date,
                year: `${new Date(month.date).getFullYear()}`,
                month: this.MonthNames[new Date(month.date).getMonth()],
              },
            };
            flatList.push(entry);
          }
        }

        // Direct Expenses Cost Summary
        for (let j = 0; j < month.directExpensesCost.length; j++) {
          const entry = this.cloneDeep(month.directExpensesCost[j]);
          //Add Row for Direct Expenses
          const targetResource = flatList.find(
            (e) => e.rowType == entry.rowType && e.laborType == entry.laborType
          );
          if (targetResource) {
            //Already Added Resource, add NEW HEADER prop value
            targetResource[`hours-${month.date}`] = {
              class: entry.class,
              laborType: entry.laborType,
              isCost: entry.isCost,
              sumCategory: entry.sumCategory,
              rowType: entry.rowType,
              title: entry.title,
              totalExpenses: entry.totalExpenses,
              totalPendingExpenses: entry.totalPendingExpenses,
              totalApprovedExpenses: entry.totalApprovedExpenses,
              parentDate: {
                date: month.date,
                year: `${new Date(month.date).getFullYear()}`,
                month: this.MonthNames[new Date(month.date).getMonth()],
              },
            };
          } else {
            //New Resource, add header prop value, then add as new resource
            entry[`hours-${month.date}`] = {
              class: entry.class,
              laborType: entry.laborType,
              isCost: entry.isCost,
              sumCategory: entry.sumCategory,
              rowType: entry.rowType,
              colSpan: entry.colSpan ?? 1,
              title: entry.title,
              totalExpenses: entry.totalExpenses,
              totalPendingExpenses: entry.totalPendingExpenses,
              totalApprovedExpenses: entry.totalApprovedExpenses,
              parentDate: {
                date: month.date,
                year: `${new Date(month.date).getFullYear()}`,
                month: this.MonthNames[new Date(month.date).getMonth()],
              },
            };
            flatList.push(entry);
          }
        }

        // Direct Expenses Revenue Summary
        for (let j = 0; j < month.directExpensesRevenue.length; j++) {
          const entry = this.cloneDeep(month.directExpensesRevenue[j]);
          //Add Row for Direct Expenses
          const targetResource = flatList.find(
            (e) => e.rowType == entry.rowType && e.laborType == entry.laborType
          );
          if (targetResource) {
            //Already Added Resource, add NEW HEADER prop value
            targetResource[`hours-${month.date}`] = {
              class: entry.class,
              laborType: entry.laborType,
              isCost: entry.isCost,
              sumCategory: entry.sumCategory,
              rowType: entry.rowType,
              title: entry.title,
              totalExpenses: entry.totalExpenses,
              totalPendingExpenses: entry.totalPendingExpenses,
              totalApprovedExpenses: entry.totalApprovedExpenses,
              parentDate: {
                date: month.date,
                year: `${new Date(month.date).getFullYear()}`,
                month: this.MonthNames[new Date(month.date).getMonth()],
              },
            };
          } else {
            //New Resource, add header prop value, then add as new resource
            entry[`hours-${month.date}`] = {
              class: entry.class,
              laborType: entry.laborType,
              isCost: entry.isCost,
              sumCategory: entry.sumCategory,
              rowType: entry.rowType,
              colSpan: entry.colSpan ?? 1,
              title: entry.title,
              totalExpenses: entry.totalExpenses,
              totalPendingExpenses: entry.totalPendingExpenses,
              totalApprovedExpenses: entry.totalApprovedExpenses,
              parentDate: {
                date: month.date,
                year: `${new Date(month.date).getFullYear()}`,
                month: this.MonthNames[new Date(month.date).getMonth()],
              },
            };
            flatList.push(entry);
          }
        }

        // Net Summary
        for (let j = 0; j < month.net.length; j++) {
          const entry = this.cloneDeep(month.net[j]);
          //Add Row for Net Summary
          const targetResource = flatList.find(
            (e) => e.rowType == entry.rowType && e.laborType == entry.laborType
          );
          if (targetResource) {
            //Already Added Resource, add NEW HEADER prop value
            targetResource[`hours-${month.date}`] = {
              class: entry.class,
              laborType: entry.laborType,
              originalLaborType: entry.originalLaborType,
              sumLaborType: entry.sumLaborType,
              isCost: entry.isCost,
              sumCategory: entry.sumCategory,
              rowType: entry.rowType,
              title: entry.title,
              total: entry.total,
              totalPending: entry.totalPending,
              totalApproved: entry.totalApproved,
              parentDate: {
                date: month.date,
                year: `${new Date(month.date).getFullYear()}`,
                month: this.MonthNames[new Date(month.date).getMonth()],
              },
            };
          } else {
            //New Resource, add header prop value, then add as new resource
            entry[`hours-${month.date}`] = {
              class: entry.class,
              laborType: entry.laborType,
              originalLaborType: entry.originalLaborType,
              sumLaborType: entry.sumLaborType,
              isCost: entry.isCost,
              sumCategory: entry.sumCategory,
              rowType: entry.rowType,
              colSpan: entry.colSpan ?? 1,
              title: entry.title,
              total: entry.total,
              totalPending: entry.totalPending,
              totalApproved: entry.totalApproved,
              parentDate: {
                date: month.date,
                year: `${new Date(month.date).getFullYear()}`,
                month: this.MonthNames[new Date(month.date).getMonth()],
              },
            };
            flatList.push(entry);
          }
        }
      }
      this.projectBigBoardMonths = flatList;

      //Add Row Sum Totals column cells
      for (let i = 0; i < this.projectBigBoardMonths.length; i++) {
        const rowItem = this.projectBigBoardMonths[i];
        // rowItem.rowType == "TotalMonthLaborRevenue"
        if (rowItem.laborType == enums.LABOR_TYPE.Revenue.value) {
          rowItem["rowSumTotal"] = {
            rowType: rowItem.rowType,
            datatype: "money",
            total: 0,
            totalPending: 0,
            totalApproved: 0,
          };
          rowItem.rowSumTotal.total = this.dynamicHeaders.reduce((tot, hdr) => {
            return tot + rowItem[hdr.value].totalRevenue;
          }, 0);
          rowItem.rowSumTotal.totalPending = this.dynamicHeaders.reduce((tot, hdr) => {
            return tot + rowItem[hdr.value].totalPendingRevenue;
          }, 0);
          rowItem.rowSumTotal.totalApproved = this.dynamicHeaders.reduce((tot, hdr) => {
            return tot + rowItem[hdr.value].totalApprovedRevenue;
          }, 0);
        } else if (rowItem.laborType == enums.LABOR_TYPE.DirectExpensesCost.value) {
          rowItem["rowSumTotal"] = {
            rowType: rowItem.rowType,
            datatype: "money",
            total: 0,
            totalPending: 0,
            totalApproved: 0,
          };
          rowItem.rowSumTotal.total = this.dynamicHeaders.reduce((tot, hdr) => {
            return tot + rowItem[hdr.value].totalExpenses;
          }, 0);
          rowItem.rowSumTotal.totalPending = this.dynamicHeaders.reduce((tot, hdr) => {
            return tot + rowItem[hdr.value].totalPendingExpenses;
          }, 0);
          rowItem.rowSumTotal.totalApproved = this.dynamicHeaders.reduce((tot, hdr) => {
            return tot + rowItem[hdr.value].totalApprovedExpenses;
          }, 0);
        } else if (rowItem.laborType == enums.LABOR_TYPE.DirectExpensesRevenue.value) {
          rowItem["rowSumTotal"] = {
            rowType: rowItem.rowType,
            datatype: "money",
            total: 0,
            totalPending: 0,
            totalApproved: 0,
          };
          rowItem.rowSumTotal.total = this.dynamicHeaders.reduce((tot, hdr) => {
            return tot + rowItem[hdr.value].totalExpenses;
          }, 0);
          rowItem.rowSumTotal.totalPending = this.dynamicHeaders.reduce((tot, hdr) => {
            return tot + rowItem[hdr.value].totalPendingExpenses;
          }, 0);
          rowItem.rowSumTotal.totalApproved = this.dynamicHeaders.reduce((tot, hdr) => {
            return tot + rowItem[hdr.value].totalApprovedExpenses;
          }, 0);
        } else if (rowItem.laborType == enums.LABOR_TYPE.Labor.value) {
          rowItem["rowSumTotal"] = {
            rowType: rowItem.rowType,
            datatype: "money",
            total: 0,
            totalPending: 0,
            totalApproved: 0,
          };
          rowItem.rowSumTotal.total = this.dynamicHeaders.reduce((tot, hdr) => {
            return tot + rowItem[hdr.value].totalLabor;
          }, 0);
          rowItem.rowSumTotal.totalPending = this.dynamicHeaders.reduce((tot, hdr) => {
            return tot + rowItem[hdr.value].totalPendingLabor;
          }, 0);
          rowItem.rowSumTotal.totalApproved = this.dynamicHeaders.reduce((tot, hdr) => {
            return tot + rowItem[hdr.value].totalApprovedLabor;
          }, 0);
        } else if (rowItem.laborType == enums.LABOR_TYPE.NetSummary.value) {
          rowItem["rowSumTotal"] = {
            rowType: rowItem.rowType,
            datatype: "money",
            total: 0,
            totalPending: 0,
            totalApproved: 0,
          };
          rowItem.rowSumTotal.total = this.dynamicHeaders.reduce((tot, hdr) => {
            return tot + rowItem[hdr.value].total;
          }, 0);
          rowItem.rowSumTotal.totalPending = this.dynamicHeaders.reduce((tot, hdr) => {
            return tot + rowItem[hdr.value].totalPending;
          }, 0);
          rowItem.rowSumTotal.totalApproved = this.dynamicHeaders.reduce((tot, hdr) => {
            return tot + rowItem[hdr.value].totalApproved;
          }, 0);
        } else if (
          ["UserEntry"].includes(rowItem.rowType) ||
          rowItem.laborType == enums.LABOR_TYPE.Hours.value
        ) {
          rowItem["rowSumTotal"] = {
            rowType: rowItem.rowType,
            datatype: "hours",
            total: 0,
            totalPending: 0,
            totalApproved: 0,
          };
          rowItem.rowSumTotal.total = this.dynamicHeaders.reduce((tot, hdr) => {
            return tot + rowItem[hdr.value].totalHours;
          }, 0);
          rowItem.rowSumTotal.totalPending = this.dynamicHeaders.reduce((tot, hdr) => {
            return tot + rowItem[hdr.value].totalPendingHours;
          }, 0);
          rowItem.rowSumTotal.totalApproved = this.dynamicHeaders.reduce((tot, hdr) => {
            return tot + rowItem[hdr.value].totalApprovedHours;
          }, 0);
        }
      }

      this.$log(">>>>>>>>>>>>>>>> projectBigBoardMonths", this.projectBigBoardMonths);
      if (this.isBigBoard || this.isMyBoard || this.isSmallBoard) {
        setTimeout(() => {
          this.collapseAllExceptNet();
        }, 650);
      }
    },
    monthDiff(d1, d2) {
      var months;
      months = (d2.getFullYear() - d1.getFullYear()) * 12;
      months -= d1.getMonth();
      months += d2.getMonth();
      return months <= 0 ? 0 : months;
    },
    getNextMonth(date) {
      // Get the month and year of the current date
      const currentMonth = date.getMonth();
      const currentYear = date.getFullYear();

      // Calculate the month and year of the next month
      let nextMonth = currentMonth + 1;
      let nextYear = currentYear;

      if (nextMonth > 11) {
        // If the next month is greater than 11 (December), add 1 to the year and set the month to 0 (January)
        nextMonth = 0;
        nextYear += 1;
      }
      return new Date(nextYear, nextMonth, 1);
    },
    monthDiffList(start, end) {
      var current = start;
      var ret = [];

      while (current < end) {
        current = this.getNextMonth(current);
        ret.push(current);
      }

      return ret;
    },
  },
  watch: {
    options: {
      handler(val) {
        var bbType = this.isBigBoard ? "BigBoard" : this.isMyBoard ? "MyBoard" : null;
        if (this.isBigBoard || this.isMyBoard) {
          this.$setToLocal(`${bbType}-range-startDate`, this.options.startDate);
          this.$setToLocal(`${bbType}-range-endDate`, this.options.endDate);
          this.getBigBoardMonthsDebounced();
        }
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss">
.no-data-available {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    width: 350px;
    // opacity: 0.87;
  }

  p {
    margin-top: 0.5rem;
    margin-bottom: 0;
  }
}

.big-board-radio .v-input--selection-controls__input {
  margin-right: 6px;
}

.big-board-table {
  .v-data-table__wrapper {
    padding-bottom: 0;
  }
}

.big-board-table table .big-board-group,
.big-board-group {
  font-weight: 500;
  // color: rgba($deep-purple-base, 1);
  background: rgba(#00bcd4, 0.08);
  background: rgba(#3f51b5, 0.08);

  &.cost-group {
    // color: rgba($orange-base, 1);
    background: rgba($orange-base, 0.08);
  }

  &.revenue-group {
    // color: rgba($success-base, 1);
    background: rgba($success-base, 0.08);
  }

  &.hours-group {
    // color: rgba($info-base, 1);
    background: rgba($info-base, 0.08);
  }

  &.direct-expenses-cost-group {
    // color: rgba($error-base, 1);
    background: rgba($accent-base, 0.08);
  }

  &.direct-expenses-revenue-group {
    // color: rgba($error-base, 1);
    background: rgba($error-base, 0.08);
  }

  &.net-summary-group {
    color: rgba(#fff, 1);
    background: rgba($shades-black, 1);
  }

  .big-board-group-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
  }
}

.big-board-table table .group-total {
  h4 {
    // text-decoration: underline;
    background: rgba($shades-black, 0.08);
    background: #eceff1;
    border: 1px solid rgba($shades-black, 0.16);
    padding: 0 0.35rem;
    border-radius: 0.25rem;
  }
}

.big-board-table table .group-total-dark {
  h4 {
    background: rgba($shades-black, 1);
    color: #fff;
    border: 1px solid rgba(#fff, 0.16);
    padding: 0 0.35rem;
    border-radius: 0.25rem;
  }
}

.big-board-table table .v-data-table-header {
  z-index: 100 !important;
  z-index: 20 !important;
}

.big-board-table table thead tr > th:nth-child(2) {
  background: #f2f3f3;
  position: sticky;
  left: 275px;
  top: 0;
  z-index: 100;
  border-right: thin solid rgba(42, 54, 59, 0.12);
}

.big-board-table table thead tr > th:first-child {
  background: #f2f3f3;
  position: sticky;
  left: 0;
  top: 0;
  z-index: 100;
  // border-right: thin solid rgba(42, 54, 59, 0.12);
}

.big-board-table table tr .span-content.sticky-cell {
  position: sticky;
  left: calc(275px + 84px);
}

.big-board-table table .year-header-value {
  position: sticky;
  left: calc(283px + 84px);
}

.big-board-table table tr > td:nth-child(2) {
  background-color: #fff;
  position: sticky;
  left: calc(275px);
  z-index: 10;
  border-right: thin solid rgba(42, 54, 59, 0.12);

  &:after {
    content: "";
    position: absolute;
    height: calc(100%);
    top: 0;
    right: 0;
    width: 2px;
    z-index: -2;
    box-shadow: 3px 0 4px rgba($shades-black, 0.16);
  }
}

.big-board-table table tr:hover > td:nth-child(2) {
  background-color: #eee;
}

.big-board-table table tr > td:first-child {
  background: #fff;
  position: sticky;
  left: 0;
  z-index: 10;
  // border-right: thin solid rgba(42, 54, 59, 0.12);

  // &:after {
  //   content: "";
  //   position: absolute;
  //   height: calc(100%);
  //   top: 0;
  //   right: 0;
  //   width: 2px;
  //   z-index: -2;
  //   box-shadow: 3px 0 4px rgba($shades-black, 0.16);
  // }
}

.big-board-table table tr > th:first-child .big-board-group-content {
  display: inline-flex;
  position: sticky;
  z-index: 10;
  left: 16px;
}

.big-board-table table tr:hover > td:first-child {
  background: #eeeeee;
}

.big-board-table table tr > td:not(:first-child),
.big-board-table table tr > th:not(:first-child) {
  border-left: thin solid rgba(42, 54, 59, 0.12);
}

//.big-board-table table tr > td .entry-span {
//  cursor: pointer;
//}

.big-board-table table tr > td .net-summary-span,
.big-board-table table tr > td .direct-expenses-span,
.big-board-table table tr > td .row-sum-span,
.big-board-table table tr > td .revenue-span,
.big-board-table table tr > td .labor-span,
.big-board-table table tr > td .hours-span,
.big-board-table table tr > td .entry-span {
  display: flex;
  width: 100%;
  height: 100%;
  cursor: pointer;

  .span-content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0 0.35rem;
    width: 100%;
    height: 100%;
    max-width: 100px;
  }
}

.big-board-table table tr > td .row-sum-span.net-summary-special {
  cursor: default;
  position: relative;
  z-index: 0;
}

.big-board-table table tr > td .row-sum-span.net-summary-special:after {
  content: "";
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
  background: rgba($shades-black, 0.1);
  // background: radial-gradient(circle, rgba($shades-black, 0.06) 25%, transparent 26%),
  //   radial-gradient(circle at bottom left, rgba($shades-black, 0.06) 12%, transparent 13%),
  //   radial-gradient(circle at bottom right, rgba($shades-black, 0.06) 12%, transparent 13%),
  //   radial-gradient(circle at top left, rgba($shades-black, 0.06) 12%, transparent 13%),
  //   radial-gradient(circle at top right, rgba($shades-black, 0.06) 12%, transparent 13%);
  // background-size: 1em 1em;
  // background-color: #eceff1;
  background: repeating-linear-gradient(
      0deg,
      #eceff1 0,
      #eceff1 20%,
      transparent 0,
      transparent 50%
    ),
    repeating-linear-gradient(
      135deg,
      rgba($shades-black, 0.04) 0,
      rgba($shades-black, 0.04) 10%,
      transparent 0,
      transparent 50%
    );
  background-size: 1em 1em;
  background-color: #eceff1;
  z-index: -1;
}
.big-board-table table tr:hover > td .row-sum-span.net-summary-special:after {
  background: repeating-linear-gradient(
      0deg,
      #d9e2e6 0,
      #d9e2e6 20%,
      transparent 0,
      transparent 50%
    ),
    repeating-linear-gradient(
      135deg,
      rgba($shades-black, 0.04) 0,
      rgba($shades-black, 0.04) 10%,
      transparent 0,
      transparent 50%
    );
  background-size: 1em 1em;
  background-color: #d9e2e6;
}

.big-board-table table tr > td.net-summary-special {
  cursor: default;
  position: relative;
  z-index: 0;
}
.big-board-table table tr > td:first-child.net-summary-special {
  display: flex;
  position: sticky;
  z-index: 10;
  left: 0;
}

.big-board-table table tr > td.net-summary-special:after {
  content: "";
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
  background: rgba($shades-black, 0.1);
  background: repeating-linear-gradient(
      0deg,
      #eceff1 0,
      #eceff1 20%,
      transparent 0,
      transparent 50%
    ),
    repeating-linear-gradient(
      135deg,
      rgba($shades-black, 0.04) 0,
      rgba($shades-black, 0.04) 10%,
      transparent 0,
      transparent 50%
    );
  background-size: 1em 1em;
  background-color: #eceff1;
  z-index: -1;
}
.big-board-table table tr:hover > td.net-summary-special:after {
  background: repeating-linear-gradient(
      0deg,
      #d9e2e6 0,
      #d9e2e6 20%,
      transparent 0,
      transparent 50%
    ),
    repeating-linear-gradient(
      135deg,
      rgba($shades-black, 0.04) 0,
      rgba($shades-black, 0.04) 10%,
      transparent 0,
      transparent 50%
    );
  background-size: 1em 1em;
  background-color: #d9e2e6;
}

.big-board-table table tr > td.net-summary-special span {
  cursor: default !important;
}

.isHighlighted > span {
  background: rgba(#ff6d00, 0.2) !important;
  color: #ff6d00 !important;
}

// .big-board-table {
//   overflow: hidden;
// }

// .big-board-table td,
// .big-board-table th {
//   position: relative;
// }

// // tbody tr:not(.v-row-group__header):hover {
// //   background-color: rgba(255, 255, 0, 0.5);
// // }

// .big-board-table td:not(.big-board-group):not(:first-child):not(:nth-child(2)):hover::after,
// .big-board-table th:not(.big-board-group):not(:first-child):not(:nth-child(2)):hover::after {
//   background-color: rgba($shades-black, 0.04);
//   content: "\00a0";
//   height: 10000px;
//   left: 0;
//   position: absolute;
//   top: -5000px;
//   width: 100%;
//   z-index: 100;
//   pointer-events: none;
//   user-select: none;
// }
</style>
