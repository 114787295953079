<template>
  <div>
    <slideout
      dock="right"
      :size="mainPanelSize"
      :min-size="nested ? 800 : 1200"
      :allow-resize="nested ? false : false"
      :append-to="nested ? '' : '#app'"
      :class="readonly ? 'slideout-readonly-content' : ''"
      :visible.sync="slideouts.update.active"
      v-on:close="onSlideoutClosing"
    >
      <template v-slot:header>
        <h3 v-if="isFetching" class="font-weight-bold pa-1" small>
          <i class="fad fa-spinner-third fa-spin mr-2"></i> Fetching Space Template ...
        </h3>
        <h3 v-else-if="selected.id != null" class="font-weight-bold pa-1" small>
          <span v-if="readonly">
            <i class="fad fa-pen-square mr-2"></i> Space Template: '{{ selected.name }}'
          </span>
          <span v-else> <i class="fad fa-pen-square mr-2"></i> Update: '{{ selected.name }}' </span>
          <entity-id
            class="ml-2"
            :label="selected.id"
            :path="spaceTemplateUrl"
            title="Space Template"
          ></entity-id>
        </h3>
        <h3 v-else class="font-weight-bold pa-1" small>
          <i class="fad fa-plus-square mr-2"></i> Create a Space Template
        </h3>
        <div>
          <code
            v-if="readonly"
            class="text-caption ml-2 white secondary--text mr-2"
            style="border: 1px dashed rgba(42, 54, 59, 0.28) !important"
          >
            <span class="fa-stack mr-0">
              <i class="fad fa-pencil fa-stack-1x"></i>
              <i class="fas fa-slash fa-stack-1x" style="color: Tomato"></i>
            </span>
            Read-Only
          </code>
          <panel-size-control v-model="slideouts.update.fullWidth"></panel-size-control>
          <v-btn @click="closeUpdateSlideout()" icon><i class="far fa-times"></i></v-btn>
        </div>
      </template>
      <div color="white" style="position: sticky; top: 0; z-index: 2">
        <v-tabs
          v-model="slideouts.update.tab"
          color="error"
          v-if="slideouts.update.active && !isFetching"
        >
          <v-tab :key="0">
            <v-fab-transition mode="out-in">
              <span class="tab-required-icon" v-if="!spaceInfoTabIsValid">
                <i class="fas fa-star-of-life pink--text"></i>
              </span>
            </v-fab-transition>
            Space Information
          </v-tab>
          <v-tab :key="1">
            Equipment
            <v-chip
              :color="slideouts.update.tab == 1 ? 'error' : 'grey lighten-4'"
              class="ml-2 font-weight-bold"
              small
              label
            >
              {{ spaceEquipmentCount }}
            </v-chip>
          </v-tab>
          <v-tab :key="2"> Drawing Document Definition </v-tab>
        </v-tabs>
        <v-divider></v-divider>
      </div>

      <v-container fluid class="pa-0 text-center" v-if="isFetching">
        <video
          width="320"
          muted
          loop
          autoplay
          style="padding: 0.5rem; opacity: 0.64; margin: 0 auto"
        >
          <source src="/img/art/astronaut-loves-music-4980476-4153140.mp4" type="video/mp4" />
        </video>
        <p class="font-weight-bold">Fetching Space Template ...</p>
      </v-container>
      <v-container fluid class="pa-0" v-else>
        <v-form v-model="slideouts.update.valid" ref="updateForm">
          <v-tabs-items v-model="slideouts.update.tab">
            <v-tab-item :key="0">
              <v-container fluid class="pa-6">
                <v-row class="my-0">
                  <v-col cols="12" md="4" sm="12" class="py-0">
                    <v-text-field-alt
                      :rules="[allRules.required, allRules.noWhiteSpaces]"
                      label="Name"
                      id="spaceTemplateName"
                      ref="spaceTemplateName"
                      placeholder="Space Template Name"
                      v-model="selected.name"
                      :readonly="readonly"
                    >
                    </v-text-field-alt>
                  </v-col>
                  <v-col cols="12" md="4" sm="12" class="py-0">
                    <v-text-field-alt
                      :rules="[allRules.required, allRules.noWhiteSpaces]"
                      label="Template Version"
                      id="spaceTemplateVersion"
                      ref="spaceTemplateVersion"
                      placeholder="Template Version"
                      v-model="selected.versionNumber"
                      :readonly="readonly"
                    >
                    </v-text-field-alt>
                  </v-col>
                  <v-col cols="12" md="4" sm="12" class="py-0">
                    <label
                      class="mb-2 d-block"
                      style="font-weight: 600 !important; font-size: 15px"
                    >
                      Space Complexity Factor
                    </label>
                    <counter
                      v-model="selected.complexityFactor"
                      :min="0.5"
                      :max="100"
                      :readonly="readonly || selected.template != null"
                      :step="0.5"
                    />
                  </v-col>
                  <v-col cols="12" md="6" sm="12" class="py-0">
                    <single-image-uploader
                      id="update-space-template-uploader"
                      :apiUrl="imageApiUrl"
                      v-model="selected.imageUrl"
                      label="Space Image"
                      :disabled="readonly"
                    ></single-image-uploader>
                  </v-col>
                  <v-col cols="12" md="6" sm="12" class="py-0">
                    <!-- <v-textarea-alt
                      label="Description"
                      id="spaceTemplateDescription"
                      placeholder="Space Description"
                      v-model="selected.description"
                      no-resize
                      height="132px"
                      :readonly="readonly"
                    >
                    </v-textarea-alt> -->
                    <rich-text-editor
                      v-model="selected.description"
                      title="Space Template Description"
                      :readonly="readonly"
                      allowExpand
                      maxHeight="132px"
                      showLabel
                      class="mb-4 font-weight-medium"
                    />
                  </v-col>
                  <v-col cols="12" md="6" sm="12">
                    <client-selector
                      v-model="selected.clientId"
                      :obj.sync="selected.client"
                      :readonly="readonly"
                      required
                    >
                    </client-selector>
                  </v-col>
                  <!-- <v-col cols="12" md="6" sm="12" class="py-0">
                    <layout-template-selector
                      :obj.sync="selected.drawingViewLayout"
                      :readonly="readonly"
                      v-model="selected.drawingViewLayoutId"
                    ></layout-template-selector>
                  </v-col> -->
                </v-row>
              </v-container>
            </v-tab-item>
            <v-tab-item :key="1" eager>
              <space-area-groups-manager
                isSpaceTemplate
                ref="spaceAreaGroupsManager"
                v-model="selected"
                :readonly="readonly"
                :isLoading="slideouts.update.isLoading"
                :validSpace="slideouts.update.valid"
                :installersList="installersList"
                :providersList="providersList"
              ></space-area-groups-manager>
            </v-tab-item>
            <v-tab-item :key="2">
              <drawing-document-definitions
                v-model="selected"
                :readonly="readonly"
              ></drawing-document-definitions>
            </v-tab-item>
          </v-tabs-items>
        </v-form>

        <space-template-stats
          nested
          ref="statesTemplate"
          :template="selected"
        ></space-template-stats>
      </v-container>

      <template v-slot:footer>
        <v-card-actions>
          <v-spacer></v-spacer>
          <div
            class="d-flex ml-2"
            v-if="!isFetching && readonly && isMainRoute && $has(perms.SpaceTemplates.Update)"
          >
            <v-tooltip top z-index="999" nudge-top="-4px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  @click="switchToEditMode()"
                  :disabled="isFetching"
                  color="orange"
                >
                  <i class="fas fa-pen mr-2" style="font-size: 10px"></i>Edit
                </v-btn>
              </template>
              <span class="d-flex align-center"
                >Edit<kbd class="light ml-2 fs-12px">CTRL<span class="opacity-54">+</span>E</kbd>
              </span>
            </v-tooltip>
          </div>

          <div class="d-flex ml-2" v-if="!isFetching && !readonly">
            <v-tooltip top z-index="999" nudge-top="-4px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  :disabled="slideouts.update.isLoading"
                  @click="jumpToRequiredField()"
                  color="error"
                  elevation="2"
                  width="32px"
                  height="32px"
                  min-width="32px"
                  min-height="32px"
                  outlined
                >
                  <i class="fas fa-asterisk fs-14px"></i>
                </v-btn>
              </template>
              <span>Jump to Required Field</span>
            </v-tooltip>
          </div>

          <v-btn
            class="ml-2"
            v-if="!isFetching && !readonly && selected.id != null"
            :disabled="slideouts.update.isLoading || !slideouts.update.hasChanges"
            @click="discardChanges()"
            color="secondary"
          >
            <i class="fal fa-clock-rotate-left mr-2"></i> Discard
          </v-btn>

          <div class="d-flex ml-2" v-if="!isFetching && !readonly">
            <v-tooltip top z-index="999" nudge-top="-4px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  color="info"
                  :disabled="
                    !slideouts.update.valid ||
                    slideouts.update.isLoading ||
                    !slideouts.update.hasChanges
                  "
                  @click="updateConfirmed(false)"
                  :loading="slideouts.update.isLoading"
                >
                  <i class="mr-2" :class="selected.id ? 'fas fa-save' : 'fal fa-plus'"></i>
                  {{ selected.id ? "Save" : "Create" }}
                </v-btn>
              </template>
              <span class="d-flex align-center"
                >{{ selected.id ? "Save" : "Create"
                }}<kbd class="light ml-1 fs-12px">CTRL<span class="opacity-54">+</span>S</kbd>
              </span>
            </v-tooltip>
          </div>
          <div class="d-flex ml-2" v-if="!isFetching && !readonly">
            <v-tooltip top z-index="999" nudge-top="-4px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  color="cyan white--text"
                  :disabled="
                    !slideouts.update.valid ||
                    slideouts.update.isLoading ||
                    !slideouts.update.hasChanges
                  "
                  @click="updateConfirmed(true)"
                  :loading="slideouts.update.isLoading"
                >
                  <i class="fas fa-save mr-2"></i>
                  {{ selected.id ? "Save & Close" : "Create & Close" }}
                </v-btn>
              </template>
              <span class="d-flex align-center"
                >{{ selected.id ? "Save & Close" : "Create & Close" }} Panel<kbd
                  class="light ml-2 fs-12px"
                  >CTRL<span class="opacity-54">+</span>SHIFT<span class="opacity-54">+</span>S</kbd
                >
              </span>
            </v-tooltip>
          </div>

          <div class="d-flex ml-2" v-if="selected.id != null">
            <v-tooltip top z-index="999" nudge-top="-4px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  elevation="2"
                  width="32px"
                  height="32px"
                  min-width="32px"
                  min-height="32px"
                  :loading="isFetching"
                  :disabled="
                    slideouts.update.isLoading || isFetching || slideouts.update.hasChanges
                  "
                  @click="fetchTemplate()"
                >
                  <i class="fal fa-arrows-rotate" style="font-size: 16px"></i>
                </v-btn>
              </template>
              <span>Refetch Space Template</span>
            </v-tooltip>
          </div>
          <v-menu dense offset-y top left z-index="1000" v-if="selected.id != null">
            <template v-slot:activator="{ attrs, on }">
              <v-btn
                :disabled="isFetching || slideouts.update.isLoading || slideouts.update.hasChanges"
                :elevation="2"
                width="32px"
                height="32px"
                min-width="32px"
                min-height="32px"
                class="ml-2"
                v-bind="attrs"
                v-on="on"
              >
                <i class="far fa-ellipsis-v" style="font-size: 16px"></i>
              </v-btn>
            </template>
            <v-list class="more-options-menu">
              <v-list-item @click="displayStats">
                <v-list-item-icon class="mr-2 justify-center">
                  <v-icon small>fal fa-analytics</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Stats</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click="openCloneModal" v-if="$has(perms.SpaceTemplates.Update)">
                <v-list-item-icon class="mr-2 justify-center">
                  <v-icon small>fal fa-clone</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Clone</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click="del" v-if="$has(perms.SpaceTemplates.Delete)">
                <v-list-item-icon class="mr-2 justify-center">
                  <v-icon small>fal fa-trash-alt red--text</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="red--text">Delete</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-card-actions>
      </template>
    </slideout>

    <clone-space-template
      @done="addToSpaceTemplates"
      ref="cloneSpaceTemplateModal"
    ></clone-space-template>
  </div>
</template>

<script>
import perms from "../../../plugins/permissions";
import spaceTemplateService from "../services/spaceTemplates-service";
import installersAPI from "../../Equipments/services/installers-service";
import providersAPI from "../../Equipments/services/providers-service";
import ClientSelector from "../../Shared/components/ClientSelector.vue";
import RichTextEditor from "../../Shared/components/RichTextEditor.vue";
import PanelSizeControl from "@Shared/components/PanelSizeControl.vue";
import CloneSpaceTemplate from "./CloneSpaceTemplate.vue";
import SpaceTemplateStats from "./SpaceTemplateStats.vue";
import DrawingDocumentDefinitions from "./DrawingDocumentDefinitions.vue";
import SpaceAreaGroupsManager from "./SpaceAreaGroupsManager.vue";

export default {
  name: "edit-space-template",
  data() {
    return {
      perms: perms,
      templateId: null,
      isFetching: false,
      readonly: false,
      installersList: [],
      providersList: [],
      selected: {},
      selectedCemented: {},
      selectedEquipment: {},
      selectedAreaGroup: {},
      tempAreaGroupId: -1,
      expandedAreaGroupPanels: [],
      slideouts: {
        update: {
          active: false,
          fullWidth: false,
          valid: false,
          tab: null,
          hasChanges: null,
          isLoading: false,
          categoryLoading: false,
        },
        addEquipments: {
          active: false,
          isLoading: false,
        },
      },
      modals: {
        renameAreaGroup: {
          active: false,
          valid: false,
          data: {
            name: null,
          },
        },
        createAreaGroup: {
          active: false,
          valid: false,
          data: {
            name: null,
          },
        },
        moveEquipments: {
          active: false,
          valid: false,
          TargetAreaGroup: null,
          SourceAreaGroup: null,
          moveRequest: false,
        },
      },
      selectedEquipments: {},
      imageApiUrl: `SpaceTemplates/Image`,
    };
  },
  props: {
    nested: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    // this.$log(">>>>>>>>>>>>>>>>>>>>>>> Space Temp MOUNTED");
    this.getInstallers();
    this.getProviders();
    document.addEventListener("keydown", this.documentKeyListener);
  },
  beforeDestroy() {
    // this.$log(">>>>>>>>>>>>>>>>>>>>>>> Space Temp beforeDestroy");
    document.removeEventListener("keydown", this.documentKeyListener);
  },
  methods: {
    documentKeyListener(event) {
      this.ctrlKeyActive = event.ctrlKey;
      this.shiftKeyActive = event.shiftKey;
      if (
        this.ctrlKeyActive &&
        !this.shiftKeyActive &&
        event.code == "KeyS" &&
        this.slideouts.update.valid &&
        !this.slideouts.update.isLoading &&
        this.slideouts.update.hasChanges
      ) {
        event.preventDefault();
        this.updateConfirmed(false);
      } else if (
        this.ctrlKeyActive &&
        this.shiftKeyActive &&
        event.code == "KeyS" &&
        this.slideouts.update.valid &&
        !this.slideouts.update.isLoading &&
        this.slideouts.update.hasChanges
      ) {
        event.preventDefault();
        this.updateConfirmed(true);
      } else if (
        this.ctrlKeyActive &&
        !this.shiftKeyActive &&
        event.code == "KeyE" &&
        !this.isFetching &&
        this.readonly &&
        this.isMainRoute &&
        this.$has(this.perms.SpaceTemplates.Update)
      ) {
        event.preventDefault();
        this.switchToEditMode(true);
      }
    },
    displayStats() {
      this.$refs.statesTemplate.open(this.cloneDeep(this.selected));
    },
    addToSpaceTemplates(spaceTemplate) {
      this.closeUpdateSlideout();
      setTimeout(() => {
        this.$emit("clone", spaceTemplate);
      }, 50);
    },
    openCloneModal() {
      this.$refs.cloneSpaceTemplateModal.open(this.selected);
    },
    discardChanges() {
      this.selected = this.cloneDeep(this.selectedCemented);
    },
    onDeleteSpaceTemplateSuccess(id) {
      this.$emit("delete", id);
    },
    del() {
      this.$dialog
        .warning({
          text: ` Are you sure you want to delete this space template?<br/><h4>${this.selected.name}</h4>`,
          title: `Delete Space Template?`,
          color: "error",
          persistent: true,
          actions: {
            false: {
              text: "Cancel",
            },
            true: {
              text: "Confirm",
              color: "error",
              handle: () => {
                return spaceTemplateService
                  .delete(this.selected.id)
                  .then((resp) => {
                    this.onDeleteSpaceTemplateSuccess(this.selected.id);
                    this.closeUpdateSlideout();
                    this.$dialog.notify.success("Space Template deleted successfully", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  })
                  .catch((resp) => {
                    this.$dialog.notify.error("Error deleting the Space Template!", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  });
              },
            },
          },
        })
        .then((res) => {});
    },
    getInstallers() {
      this.slideouts.update.installersLoading = true;
      installersAPI
        .typeHead(null, true)
        .then((resp) => {
          this.installersList = resp.data;
          this.slideouts.update.installersLoading = false;
        })
        .catch((err) => {
          this.slideouts.update.installersLoading = false;
          // this.$handleError(err);
        });
    },
    getProviders() {
      this.slideouts.update.providersLoading = true;
      providersAPI
        .typeHead(null, true)
        .then((resp) => {
          this.providersList = resp.data;
          this.slideouts.update.providersLoading = false;
        })
        .catch((err) => {
          this.slideouts.update.providersLoading = false;
          // this.$handleError(err);
        });
    },
    switchToEditMode() {
      this.readonly = false;
    },
    onSlideoutClosing(e) {
      // prevent close and wait
      e.wait = true;

      if (this.slideouts.update.hasChanges && !this.$confirmReleaseChanges()) {
        // allow close
        e.close = false;
        return;
      }

      //reset the changes
      this.selected = {};
      this.cementTemplate();
      this.$releaseChanges();
      this.isFetching = false;
      this.expandedAreaGroupPanels = [];

      //allow close, and un wait
      e.wait = false;
      e.close = true;
      this.$emit("close");
    },
    announceChange(isNewTemplate) {
      this.$emit("save", this.selected, isNewTemplate);
    },
    fetchTemplate() {
      this.isFetching = true;
      spaceTemplateService
        .getTemplateById(this.templateId)
        .then((resp) => {
          this.$log("getTemplateById >> success", resp.data);
          this.isFetching = false;
          this.slideouts.update.isLoading = false;
          this.selected = this.cloneDeep(resp.data);
          this.sortAreaGroups();
          if (this.selected.versionNumber == null) {
            this.selected.versionNumber = "1.0";
          }
          this.cementTemplate();
          if (this.isMainRoute) document.title = this.selected.name + " | Concordia";
          this.handleFocus();
        })
        .catch((err) => {
          this.$log("getTemplateById >> error", err);
          this.closeUpdateSlideout();
          this.$handleError(err);
        });
    },
    sortAreaGroups() {
      this.selected.areaGroups.sort((a, b) =>
        a.name.toLowerCase().localeCompare(b.name.toLowerCase())
      );
    },
    open(id, editMode = false) {
      if (id == null) {
        this.selected = {
          isTemplate: true,
          complexityFactor: 0,
          iavSheetCount: 1,
          scdSheetCount: 1,
          planSheetCount: 1,
          overviewSheetCount: 1,
          detailSheetCount: 1,
          versionNumber: "1.0",
          areaGroups: [],
        };
        //new template?, then add a default area group
        this.selected.areaGroups = [
          {
            id: null,
            tempId: this.$refs.spaceAreaGroupsManager.getTempAreaGroupId(),
            name: "New Area Group 1",
            areaEquipments: [],
          },
        ];
        this.readonly = false;
        this.isFetching = false;
        this.templateId = null;
        this.expandedAreaGroupPanels = [];
        this.slideouts.update.tab = 0;
        this.$refs.updateForm.resetValidation();
        this.handleFocus();
        this.cementTemplate();
      } else {
        this.templateId = id;
        this.readonly = !editMode;
        this.slideouts.update.tab = 0;
        this.fetchTemplate();
      }
      this.slideouts.update.active = true;
      // this.cementTemplate();
    },
    cementTemplate() {
      this.selectedCemented = this.cloneDeep(this.selected);
      this.$log("########>>>>>>> cementTemplate()");
      this.checkForChanges();
    },
    updateConfirmed(closeAfter) {
      this.slideouts.update.isLoading = true;
      let spaceToSend = JSON.parse(JSON.stringify({ ...this.selected }));

      spaceToSend.areaGroups = spaceToSend.areaGroups.map((areaGroup) => {
        return {
          id: areaGroup.id,
          name: areaGroup.name,
          isRack: areaGroup.isRack,
          areaEquipments: areaGroup.areaEquipments.map((areaEquipment) => {
            return {
              id: areaEquipment.id,
              equipmentId: areaEquipment.equipment.id,
              count: areaEquipment.count,
              installerId: areaEquipment.installerId,
              providerId: areaEquipment.providerId,
            };
          }),
        };
      });
      if (spaceToSend.areaGroups.length == 0) spaceToSend.clearAllEquipments = true;
      else {
        spaceToSend.areaGroups.forEach((areaGroup) => {
          areaGroup.areaEquipments = areaGroup.areaEquipments.filter(
            (areaEquip) => areaEquip.count > 0
          );
        });
      }
      this.$log("update >> spaceToSend", spaceToSend);
      spaceTemplateService
        .update(spaceToSend)
        .then((resp) => {
          this.slideouts.update.isLoading = false;
          this.$log("update >> Success", resp);
          let message = "Space template is updated successfully!";
          this.selected = this.cloneDeep(resp.data);
          this.cementTemplate();
          let isNewTemplate = false;
          if (!spaceToSend.id) {
            this.templateId = this.selected.id;
            message = "Space template is added successfully!";
            isNewTemplate = true;
          }
          this.announceChange(isNewTemplate);
          this.$dialog.notify.success(message, {
            position: "top-right",
            timeout: 3000,
          });

          this.readonly = true;
          this.checkForChanges();
          if (closeAfter) {
            this.closeUpdateSlideout();
          }
        })
        .catch((err) => {
          this.$log("update >> error", err);
          this.slideouts.update.isLoading = false;
          this.$handleError(err);
        });
    },
    rowClass(item) {
      return item.count > 0 ? "row-not-empty" : "";
    },
    closeUpdateSlideout() {
      this.slideouts.update.isLoading = false;
      this.slideouts.update.active = false;
    },
    checkForChanges() {
      if (!this.slideouts.update.active) return;

      this.slideouts.update.hasChanges = !this.isEqual(this.selected, this.selectedCemented);

      if (this.slideouts.update.hasChanges) {
        this.$log(
          "%c=>> deepDiff",
          "color: red",
          this.deepDiff(this.selectedCemented, this.selected)
        );
      }

      if (this.slideouts.update.hasChanges) this.$guardChanges();
      else this.$releaseChanges();
    },
    handleFocus() {
      if (this.isFetching) return;
      if (this.slideouts.update.tab == 0) {
        setTimeout(() => {
          // this.$refs.spaceTemplateName.focus();
        }, 350);
      } else if (this.slideouts.update.tab == 1) {
        setTimeout(() => {
          if (
            this.$refs.spaceAreaGroupsManager &&
            this.$refs.spaceAreaGroupsManager.$refs.addAreaGroupBtn
          ) {
            this.$refs.spaceAreaGroupsManager.$refs.addAreaGroupBtn.$el.focus();
          }
        }, 350);
      }
    },
    jumpToRequiredField() {
      if (
        this.findRequiredFieldInFrom(this.$refs.updateForm, () => {
          this.slideouts.update.tab = 0;
        })
      )
        return;

      this.$dialog.notify.info("All required fields are filled!", {
        position: "top-right",
        timeout: 3000,
      });
    },
    getAreaGroupEquipmentsCount(areaGroup) {
      if (areaGroup != null && areaGroup.areaEquipments != null)
        return areaGroup.areaEquipments.reduce((total, cur) => {
          return total + cur.count;
        }, 0);
      else return 0;
    },
  },
  computed: {
    mainPanelSize() {
      return this.slideouts.update.fullWidth ? "100%" : this.nested ? "87%" : "78%";
    },
    spaceTemplateUrl() {
      if (this.selected == null) return "";
      return `space-templates/${this.selected.id}`;
    },
    spaceEquipmentCount() {
      if (this.selected != null && this.selected.areaGroups != null)
        return this.selected.areaGroups.reduce((total, cur) => {
          return total + this.getAreaGroupEquipmentsCount(cur);
        }, 0);
      else return 0;
    },
    isMainRoute() {
      if (this.$route.name == "space-templates" || this.$route.name == "space-template")
        return true;
      else return false;
    },
    spaceInfoTabIsValid() {
      return this.slideouts.update.valid;
    },
  },
  watch: {
    selected: {
      handler() {
        this.checkForChanges();
      },
      deep: true,
    },
    "slideouts.update.tab": {
      handler() {
        this.handleFocus();
      },
      deep: true,
    },
    "slideouts.addEquipments.active": {
      handler() {
        if (this.slideouts.addEquipments.active) {
          setTimeout(() => {
            this.$refs.addEquipmentsControl.focus();
          }, 350);
        }
      },
      deep: true,
    },
    searchLayoutTemplates(val) {
      this.getLayoutTemplatesDebounced();
    },
  },
  components: {
    ClientSelector,
    RichTextEditor,
    PanelSizeControl,
    CloneSpaceTemplate,
    SpaceTemplateStats,
    DrawingDocumentDefinitions,
    SpaceAreaGroupsManager,
  },
};
</script>
<style lang="scss">
.v-menu__content.provider-installer-select-menu {
  z-index: 111 !important;
}
</style>
