var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"px-6 pt-2",staticStyle:{"background":"#eceff1"},attrs:{"fluid":""}},[_c('v-row',{staticClass:"mt-2 mb-2",attrs:{"justify":"center","align":"center"}},[_c('v-col',[_c('h3',[_vm._v("Projects that has spaces which are linked to this space template!")])]),_c('v-col',{staticClass:"d-flex justify-end",attrs:{"lg":"5","md":"2","sm":"6"}},[_c('v-text-field',{ref:"mainSearch",staticClass:"table-search-field",style:({
          'max-width':
            _vm.mainSearchInFocus || (_vm.options.search != '' && _vm.options.search != null)
              ? '200px'
              : '110px',
        }),attrs:{"label":"Search","dense":"","solo":"","clearable":"","hide-details":"","prepend-inner-icon":"far fa-search","loading":_vm.loadingStates.table},on:{"focus":function($event){_vm.mainSearchInFocus = true},"blur":function($event){_vm.mainSearchInFocus = false}},model:{value:(_vm.options.search),callback:function ($$v) {_vm.$set(_vm.options, "search", $$v)},expression:"options.search"}})],1)],1),_c('v-data-table',{staticClass:"elevation-2",attrs:{"dense":"","headers":_vm.headers,"items":_vm.entities,"server-items-length":_vm.total,"items-per-page":15,"loading":_vm.loadingStates.table,"options":_vm.options,"footer-props":{
      showFirstLastPage: true,
      firstIcon: 'far fa-arrow-to-left',
      lastIcon: 'far fa-arrow-to-right',
      prevIcon: 'far fa-angle-left',
      nextIcon: 'far fa-angle-right',
      itemsPerPageOptions: [15, 30, 50, 100],
    }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"header.jiraCode",fn:function(ref){
    var header = ref.header;
return [_c('i',{class:header.icon}),_vm._v(" "+_vm._s(header.text.toUpperCase())+" ")]}},{key:"item.jiraCode",fn:function(ref){
    var item = ref.item;
return [(item.jiraCode != null && item.jiraCode != '')?_c('a',{staticClass:"jira-external-link",attrs:{"target":"_blank","href":'https://dangeloconsultants.atlassian.net/browse/' + item.jiraCode},on:{"click":function($event){$event.stopPropagation();}}},[_c('span',{staticClass:"icon-wrpr mr-1"},[_c('i',{staticClass:"fab fa-jira org-icon"}),_c('i',{staticClass:"far fa-external-link alt-icon"})]),_c('span',[_vm._v(_vm._s(item.jiraCode))])]):_c('span',{staticClass:"text--disabled"},[_c('i',{staticClass:"fad fa-unlink"})])]}},{key:"item.client",fn:function(ref){
    var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","nudge-top":"-16px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"pa-2",on:{"click":function($event){$event.stopPropagation();return _vm.viewClient(item.client.id)}}},'div',attrs,false),on),[(item.client.logoUrl != null && item.client.logoUrl != '')?_c('v-img',{attrs:{"src":item.client.logoUrl,"max-height":"28","max-width":"54","position":"left center","contain":""}}):_c('v-img',{attrs:{"src":"/img/DNA_Symbol.png","max-height":"28","max-width":"54","position":"left center","contain":""}})],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.client.name))])])]}},{key:"item.name",fn:function(ref){
    var item = ref.item;
return [_c('h4',[_vm._v(_vm._s(item.name))])]}},{key:"item.status",fn:function(ref){
    var item = ref.item;
return [_c('project-status',{attrs:{"status":item.status,"small":"","show-parent":""}})]}},{key:"item.createDate",fn:function(ref){
    var item = ref.item;
return [_c('dater',{attrs:{"date":item.createDate,"date-only":""}})]}},{key:"item.updateDate",fn:function(ref){
    var item = ref.item;
return [_c('dater',{attrs:{"date":item.updateDate,"date-only":""}})]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","elevation":"0","color":"info","target":"_blank","href":("/projects/" + (item.id))},on:{"click":function($event){$event.stopPropagation();}}},[_c('i',{staticClass:"fal fa-external-link"})])]}},{key:"no-data",fn:function(){return [_c('img',{attrs:{"width":"300","src":"/img/art/fogg-no-connection-2.png"}}),_c('p',{staticClass:"font-weight-bold"},[_vm._v("No Data Available!")])]},proxy:true},{key:"loading",fn:function(){return [_c('video',{staticStyle:{"padding":"0.5rem"},attrs:{"width":"250","muted":"","loop":"","autoplay":""},domProps:{"muted":true}},[_c('source',{attrs:{"src":"/img/art/astronaut-loves-music-4980476-4153140.mp4","type":"video/mp4"}})]),_c('p',{staticClass:"font-weight-bold"},[_vm._v("Searching the Cosmos...")])]},proxy:true}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }