<template>
  <div class="project-view-wrapper">
    <v-scroll-y-transition mode="out-in">
      <v-container
        fluid
        key="normal"
        class="project-view"
        v-if="!projectError && !isProjectLoading && isProjectInitiallyLoaded"
      >
        <v-row>
          <!-- <v-col
            cols="12"
            :lg="!showProjectOverview || createMode ? 12 : 9"
            :md="!showProjectOverview || createMode ? 12 : 8"
            sm="12"
            class="py-0"
          > -->
          <v-col class="py-0">
            <v-row justify="space-between">
              <v-col>
                <v-breadcrumbs-alt :items="breadcrumbs" class="text-truncate"></v-breadcrumbs-alt>
                <div
                  v-if="!createMode && isProjectInitiallyLoaded"
                  class="text--primary font-weight-black text-truncate d-flex justify-start align-center"
                >
                  <v-tooltip top z-index="9999" :disabled="copyProjectFullNameSnackbar">
                    <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs" v-on="on" style="cursor: pointer">
                        <h2
                          @click="copyToClipboard"
                          class="text--primary font-weight-black text-truncate d-flex justify-start align-center"
                        >
                          <i class="fad fa-folder mr-2"></i>
                          {{ project.fullName }}
                        </h2>
                      </div>
                    </template>
                    <span> Click to copy to clipboard! </span>
                  </v-tooltip>
                  <v-snackbar
                    v-model="copyProjectFullNameSnackbar"
                    :timeout="snackbarTimeout"
                    top
                    color="secondary"
                  >
                    Project Full Name Copied to Clipboard
                    <template v-slot:action="{ attrs }">
                      <v-btn
                        color="info"
                        text
                        v-bind="attrs"
                        @click="copyProjectFullNameSnackbar = false"
                      >
                        Close
                      </v-btn>
                    </template>
                  </v-snackbar>
                  <project-star
                    class="ml-2"
                    :starred="project.isStarred"
                    :project-id="project.id"
                  ></project-star>
                </div>
                <h2
                  v-else-if="!createMode && !isProjectInitiallyLoaded"
                  class="text--primary font-weight-black text-truncate"
                >
                  <v-progress-circular
                    :size="20"
                    :width="2"
                    indeterminate
                    color="info"
                    class="mr-2"
                  ></v-progress-circular>
                  Loading Project...
                </h2>
                <div
                  v-else
                  class="text--primary font-weight-black text-truncate d-flex justify-start align-center"
                >
                  <h2
                    class="text--primary font-weight-black text-truncate d-flex justify-start align-center"
                  >
                    <i class="fad fa-folder-plus mr-2"></i>
                    Create a New Project
                  </h2>
                </div>
              </v-col>
              <v-col class="d-flex align-end justify-end">
                <v-btn
                  color="info"
                  @click="createProject"
                  v-if="createMode"
                  :loading="isProjectSaving"
                  :disabled="!tabs.information.valid || isProjectSaving"
                >
                  <i class="fal fa-check mr-2"></i>Create Project
                </v-btn>
                <v-menu dense offset-y left bottom v-if="!createMode" style="z-index: 999">
                  <template v-slot:activator="{ attrs, on }">
                    <v-btn
                      small
                      color="white"
                      elevation="1"
                      height="32px"
                      min-width="40px"
                      class="ml-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <i class="far fa-ellipsis-v" style="font-size: 16px"></i>
                    </v-btn>
                  </template>
                  <v-list class="more-options-menu">
                    <v-list-item @click="openCloneModal()" v-if="$has(perms.Projects.Create)">
                      <v-list-item-icon class="mr-2 justify-center">
                        <v-icon small>fal fa-clone</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>Clone</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                      @click="openCreateJiraProjectModal()"
                      v-if="$has(perms.Projects.CreateJiraProject)"
                      :disabled="
                        project.jiraCreationStatus != enums.LinkedProjectStatus.Idle.value &&
                        project.jiraCreationStatus != enums.LinkedProjectStatus.Failed.value
                      "
                    >
                      <v-list-item-icon class="mr-2 justify-center">
                        <v-icon small>fal fa-plus</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>Create a Jira Project</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                      @click="openCreateMsTeamModal()"
                      v-if="$has(perms.Projects.CreateMsTeam)"
                      :disabled="
                        project.msTeamCreationStatus != enums.LinkedProjectStatus.Idle.value &&
                        project.msTeamCreationStatus != enums.LinkedProjectStatus.Failed.value
                      "
                    >
                      <v-list-item-icon class="mr-2 justify-center">
                        <v-icon small>fal fa-plus</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>Create a MS Team</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                      @click="del()"
                      v-if="$has(perms.Projects.Delete)"
                      :disabled="!$has(perms.Projects.Delete)"
                    >
                      <v-list-item-icon class="mr-2 justify-center">
                        <v-icon small :class="$has(perms.Projects.Delete) ? '' : 'opacity-54'"
                          >fal fa-trash-alt red--text</v-icon
                        >
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title
                          class="red--text"
                          :class="$has(perms.Projects.Delete) ? '' : 'opacity-54'"
                          >Delete</v-list-item-title
                        >
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-btn
                  color="secondary"
                  dark
                  class="ml-2"
                  v-if="!showProjectOverview && !createMode"
                  @click="toggleProjectOverview(true)"
                >
                  <i class="fad fa-box-full mr-2"></i
                  ><span style="text-transform: none !important">Project Overview</span>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col md="auto" style="width: 320px" v-if="showProjectOverview && !createMode"></v-col>
        </v-row>
        <v-row class="mt-2">
          <v-col
            md="auto"
            style="width: 220px"
            class="pr-0"
            v-if="$vuetify.breakpoint.lg || $vuetify.breakpoint.xl"
          >
            <v-card style="width: calc(100% - 12px); border-radius: 4px 0 0 4px">
              <v-tabs color="error" vertical v-model="tab" show-arrows @change="onTabChange">
                <!-- <v-tab
                  v-for="(item, i) in authorizedTabs"
                  :key="i"
                  :disabled="isProjectSaving || (item.allowInCreateMode ? false : createMode)"
                >
                  <v-fab-transition mode="out-in">
                    <span class="tab-required-icon" v-if="!item.valid && isProjectInitiallyLoaded">
                      <i class="fas fa-star-of-life pink--text"></i>
                    </span>
                  </v-fab-transition>
                  <i class="fad mr-2" :class="item.icon"></i>
                  {{ item.title }}
                  <v-fab-transition mode="out-in">
                    <span
                      class="tab-changes-icon"
                      v-if="item.hasChange && isProjectInitiallyLoaded"
                    >
                      <i class="fas fa-save info--text fa-beat"></i>
                    </span>
                  </v-fab-transition>
                </v-tab> -->
                <template v-for="(item, i) in authorizedTabsWithHeaders">
                  <v-tab
                    :key="i"
                    v-if="!item.isHeader"
                    :disabled="isProjectSaving || (item.allowInCreateMode ? false : createMode)"
                  >
                    <v-fab-transition mode="out-in">
                      <span
                        class="tab-required-icon"
                        v-if="!item.valid && isProjectInitiallyLoaded"
                      >
                        <i class="fas fa-star-of-life pink--text"></i>
                      </span>
                    </v-fab-transition>
                    <i class="fad mr-2" :class="item.icon"></i>
                    {{ item.title }}
                    <v-fab-transition mode="out-in">
                      <span
                        class="tab-changes-icon"
                        v-if="item.hasChange && isProjectInitiallyLoaded"
                      >
                        <i class="fas fa-save info--text fa-beat"></i>
                      </span>
                    </v-fab-transition>
                  </v-tab>
                  <h4 v-else :key="i" class="project-tab-header">
                    {{ item.title }}
                  </h4>
                </template>
              </v-tabs>
            </v-card>
          </v-col>
          <!-- <v-col
            cols="12"
            :lg="!showProjectOverview || createMode ? 12 : 9"
            :md="!showProjectOverview || createMode ? 12 : 8"
            sm="12"
          > -->
          <v-col class="px-0">
            <v-card
              :loading="isProjectSaving"
              style="width: calc(100% - 14px); border-radius: 0 4px 4px 0"
            >
              <div
                color="white"
                style="position: sticky; top: 0px; z-index: 2"
                @wheel="wheelIt"
                ref="tabsContainer"
                v-if="$vuetify.breakpoint.mdAndDown"
              >
                <v-tabs color="error" v-model="tab" show-arrows @change="onTabChange">
                  <v-tab
                    v-for="(item, i) in authorizedTabs"
                    :key="i"
                    :disabled="isProjectSaving || (item.allowInCreateMode ? false : createMode)"
                  >
                    <v-fab-transition mode="out-in">
                      <span
                        class="tab-required-icon"
                        v-if="!item.valid && isProjectInitiallyLoaded"
                      >
                        <i class="fas fa-star-of-life pink--text"></i>
                      </span>
                    </v-fab-transition>
                    <i class="fad mr-2" :class="item.icon"></i>
                    {{ item.title }}
                    <v-fab-transition mode="out-in">
                      <span
                        class="tab-changes-icon"
                        v-if="item.hasChange && isProjectInitiallyLoaded"
                      >
                        <i class="fas fa-save info--text fa-beat"></i>
                      </span>
                    </v-fab-transition>
                  </v-tab>
                </v-tabs>
                <v-divider></v-divider>
              </div>
              <!-- :class="tab == tabs.drawingDocuments.key ? 'project-drawing-document-tab-opened' : ''"
              :class="item.key == tabs.drawingDocuments.key ? 'project-drawing-document-tab' : ''" -->
              <div :class="`${projectActiveTabClass}-tab-opened`">
                <v-scroll-x-transition mode="out-in">
                  <project-information
                    v-if="tab == getTab(tabs.information.id).key"
                    ref="basicInfo"
                    key="basicInfo"
                    :isProjectInitiallyLoaded="isProjectInitiallyLoaded"
                    :isProjectSaving.sync="isProjectSaving"
                    :projectStatusList="projectStatusList"
                    v-model="project"
                    :create-mode="createMode"
                    :has-change.sync="tabs.information.hasChange"
                    @update:project="updateProject"
                    @discard="discardProjectInfoChanges()"
                    @valid-changes="projectTabValidityChanged($event, 'information')"
                    @project-number-changed="projectNumberChanged"
                    @switch-to-variables="switchToVariablesTab"
                    :readonly="readonly"
                  ></project-information>
                  <project-phases
                    v-else-if="tab == getTab(tabs.phases.id).key"
                    key="projectPhases"
                    :projectId="project.id"
                    @valid-changes="projectTabValidityChanged($event, 'phases')"
                    @has-changes="projectTabHasChanged($event, 'phases')"
                  ></project-phases>
                  <project-contracts
                    v-else-if="tab == getTab(tabs.contracts.id).key"
                    key="projectContracts"
                    :projectId="project.id"
                    @valid-changes="projectTabValidityChanged($event, 'contracts')"
                    @has-changes="projectTabHasChanged($event, 'contracts')"
                  ></project-contracts>
                  <project-small-board
                    v-else-if="tab == getTab(tabs.smallBoard.id).key"
                    key="projectSmallBoard"
                    :projectId="project.id"
                    @valid-changes="projectTabValidityChanged($event, 'smallBoard')"
                    @has-changes="projectTabHasChanged($event, 'smallBoard')"
                  ></project-small-board>
                  <project-spaces
                    v-else-if="tab == getTab(tabs.spaces.id).key"
                    key="projectSpaces"
                    ref="projectSpaces"
                    :projectId="project.id"
                    :overview-section="projectOverviewSection"
                    :buildings="project.buildings"
                    :readonly="readonly"
                    @refresh-overview="refreshProjectOverview"
                  ></project-spaces>
                  <project-equipment
                    v-else-if="tab == getTab(tabs.equipment.id).key"
                    key="projectEquipment"
                    ref="projectEquipment"
                    :projectId="project.id"
                    :readonly="readonly"
                    @refresh-overview="refreshProjectOverview"
                  ></project-equipment>
                  <keep-alive v-else-if="tab == getTab(tabs.notes.id).key" key="projectNotesKA">
                    <project-notes
                      key="projectNotes"
                      :projectId="project.id"
                      :readonly="readonly"
                      @has-changes="projectTabHasChanged($event, 'notes')"
                    >
                    </project-notes>
                  </keep-alive>
                  <drawing-documents-view-manager
                    v-else-if="tab == getTab(tabs.drawingDocuments.id).key"
                    key="projectDrawingDocument"
                    :projectId="project.id"
                    :overview-section="projectOverviewSection"
                  ></drawing-documents-view-manager>
                  <project-reports
                    v-else-if="tab == getTab(tabs.projectReports.id).key"
                    key="projectReports"
                    :projectId="project.id"
                  ></project-reports>
                  <keep-alive
                    v-else-if="tab == getTab(tabs.contacts.id).key"
                    key="projectContactsKA"
                  >
                    <project-contacts
                      key="projectContacts"
                      :project="project"
                      @has-changes="projectTabHasChanged($event, 'contacts')"
                    >
                    </project-contacts>
                  </keep-alive>
                  <keep-alive
                    v-else-if="tab == getTab(tabs.assignees.id).key"
                    key="projectAssigneesKA"
                  >
                    <project-assignees
                      key="projectAssignees"
                      :projectId="project.id"
                      @has-changes="projectTabHasChanged($event, 'assignees')"
                    ></project-assignees>
                  </keep-alive>
                  <buildings-and-levels
                    v-else-if="tab == getTab(tabs.buildings.id).key"
                    key="projectBuildings"
                    :projectId="project.id"
                    :overview-section="projectOverviewSection"
                  ></buildings-and-levels>
                  <project-budget
                    v-else-if="tab == getTab(tabs.budget.id).key"
                    key="projectBudget"
                    :projectId="project.id"
                    :projectStage="project.stage"
                    @valid-changes="projectTabValidityChanged($event, 'budget')"
                    @has-changes="projectTabHasChanged($event, 'budget')"
                  ></project-budget>
                  <project-variables-static
                    v-else-if="tab == getTab(tabs.variables.id).key"
                    key="projectVariables"
                    :projectId="project.id"
                    @valid-changes="projectTabValidityChanged($event, 'variables')"
                    @has-changes="projectTabHasChanged($event, 'variables')"
                    @change="projectVariablesChanged"
                  ></project-variables-static>
                  <project-access-list
                    v-else-if="tab == getTab(tabs.accessList.id).key"
                    key="projectAccessList"
                    :projectId="project.id"
                    @valid-changes="projectTabValidityChanged($event, 'accessList')"
                    @has-changes="projectTabHasChanged($event, 'accessList')"
                  ></project-access-list>
                  <project-external-access-list
                    v-else-if="tab == getTab(tabs.externalAccessList.id).key"
                    key="projectExternalAccessList"
                    :projectId="project.id"
                    @valid-changes="projectTabValidityChanged($event, 'externalAccessList')"
                    @has-changes="projectTabHasChanged($event, 'externalAccessList')"
                  ></project-external-access-list>
                  <project-timeline
                    v-else-if="tab == getTab(tabs.timeline.id).key"
                    key="projectTimeline"
                    :projectId="project.id"
                    :projectStage="project.stage"
                    @valid-changes="projectTabValidityChanged($event, 'timeline')"
                    @has-changes="projectTabHasChanged($event, 'timeline')"
                  >
                  </project-timeline>
                  <project-activity-logs
                    v-else-if="tab == getTab(tabs.activityLogs.id).key"
                    key="projectActivityLogs"
                    :projectId="project.id"
                    :project="project"
                  >
                  </project-activity-logs>
                </v-scroll-x-transition>
              </div>
            </v-card>
          </v-col>
          <v-col md="auto" style="width: 320px" v-if="showProjectOverview && !createMode">
            <v-btn
              min-width="20px"
              width="20px"
              height="20px"
              color="secondary"
              dark
              class="hide-overview-btn"
              small
              @click="toggleProjectOverview(false)"
            >
              <i class="fas fa-angle-right" small></i>
            </v-btn>
            <project-overview
              ref="projectOverviewSection"
              :projectId="this.project.id"
              :installation-factor="this.projectInstallationFactor"
              @open-item="openProjectSpace"
              @collapse="toggleProjectOverview(false)"
            ></project-overview>
          </v-col>
        </v-row>
        <clone-project ref="cloneProjectModal"></clone-project>
      </v-container>
      <v-container
        fluid
        key="loading"
        class="project-view"
        v-else-if="!projectError && isProjectLoading"
      >
        <div
          class="d-flex flex-column align-center justify-center"
          style="
            overflow: auto;
            overflow: overlay;
            height: 100%;
            flex: 1 1 auto;
            display: flex;
            flex-direction: column;
          "
        >
          <video
            width="275"
            muted
            loop
            autoplay
            class="inset-shadow-video"
            style="padding: 0.5rem; border-radius: 10rem; background: #fff"
          >
            <source src="/img/art/astronaut-mines-coins-4979111-4153144.mp4" type="video/mp4" />
          </video>
          <h4 class="mt-3 text--disabled">Loading Project ...</h4>
        </div>
      </v-container>
      <v-container fluid key="error" class="project-view" v-else>
        <div
          class="d-flex flex-column align-center justify-center"
          style="
            overflow: auto;
            overflow: overlay;
            height: 100%;
            flex: 1 1 auto;
            display: flex;
            flex-direction: column;
          "
        >
          <custom-error-404 label="Project" v-if="projectErrorStatus == 404"></custom-error-404>
          <custom-error-401
            label="Project"
            v-else-if="projectErrorStatus == 401"
          ></custom-error-401>
          <custom-error-generic label="Project" v-else></custom-error-generic>
        </div>
      </v-container>
    </v-scroll-y-transition>
  </div>
</template>

<script>
import enums from "../../../plugins/enums";
import projectsAPI from "../services/projects-service";
import ProjectInformation from "../components/ProjectInformation.vue";
import ProjectSpaces from "../../Spaces/components/ProjectSpaces.vue";
import ProjectOverview from "../../Spaces/components/ProjectOverview.vue";
import ProjectReports from "../components/ProjectReports.vue";
import ProjectActivityLogs from "../components/activityLogs/ProjectActivityLogs.vue";
import ProjectVariablesStatic from "../components/ProjectVariablesStatic.vue";
import DrawingDocumentsViewManager from "../../DrawingDocuments/components/DrawingDocumentsViewManager.vue";
import BuildingsAndLevels from "../components/BuildingsAndLevels.vue";
import CloneProject from "../components/CloneProject.vue";
import ProjectTimeline from "../components/ProjectTimeline.vue";
import ProjectBudget from "../components/ProjectBudget";
import ProjectAssignees from "../components/ProjectAssignees.vue";
import ProjectContacts from "../components/ProjectContacts.vue";
import ProjectPhases from "../components/ProjectPhases.vue";
import ProjectContracts from "../components/ProjectContracts.vue";
import perms from "../../../plugins/permissions";
import CustomError404 from "../../Main/components/CustomError404.vue";
import CustomError401 from "../../Main/components/CustomError401.vue";
import CustomErrorGeneric from "../../Main/components/CustomErrorGeneric.vue";
import ProjectSmallBoard from "../components/ProjectSmallBoard.vue";
import ProjectAccessList from "../components/ProjectAccessList.vue";
import ProjectExternalAccessList from "../components/ProjectExternalAccessList.vue";
import ProjectNotes from "../components/ProjectNotes.vue";
import ProjectEquipment from "../components/ProjectEquipment.vue";
import jiraAPI from "../services/jira-service";
import msTeamsAPI from "../services/ms-teams-service";
import ProjectStar from "../components/ProjectStar.vue";

export default {
  components: {
    ProjectInformation,
    ProjectSpaces,
    ProjectOverview,
    ProjectReports,
    ProjectVariablesStatic,
    DrawingDocumentsViewManager,
    BuildingsAndLevels,
    CloneProject,
    ProjectTimeline,
    ProjectBudget,
    ProjectAssignees,
    ProjectContacts,
    ProjectPhases,
    ProjectContracts,
    CustomError404,
    CustomError401,
    CustomErrorGeneric,
    ProjectSmallBoard,
    ProjectAccessList,
    ProjectNotes,
    ProjectExternalAccessList,
    ProjectActivityLogs,
    ProjectStar,
    ProjectEquipment,
  },
  data() {
    return {
      enums,
      showProjectOverview: true,
      readonly: false,
      copyProjectFullNameSnackbar: false,
      snackbarTimeout: 2000,
      perms: perms,
      returnedProjectsQuery: null,
      project: {
        id: null,
        client: null,
        stage: enums.PROJECT_STAGE.Opportunity.value,
        autoGenerateProjectNumber: true,
        number: null,
        createJiraProject: false,
        jiraCode: null,
        createMSTeam: false,
        microsoftTeamId: null,
        microsoftGeneralSiteName: null,
        microsoftGeneralChannelId: null,
      },
      projectCemented: {
        id: null,
        client: null,
        stage: enums.PROJECT_STAGE.Opportunity.value,
        autoGenerateProjectNumber: true,
        number: null,
        createJiraProject: false,
        jiraCode: null,
        createMSTeam: false,
        microsoftTeamId: null,
        microsoftGeneralSiteName: null,
        microsoftGeneralChannelId: null,
      },
      variablesInstallationFactor: null,
      isRouteParamsRead: false,
      isMounted: false,
      isProjectInitiallyLoaded: false,
      isProjectLoading: false,
      isProjectSaving: false,
      projectError: false,
      projectErrorStatus: 0,
      tab: 0,
      tabs: {
        header_contents: {
          title: "Content",
          icon: "fa-arrow-progress",
          key: 0,
          isHeader: true,
          authorized:
            this.$has(perms.Projects.View) ||
            this.$has(perms.DrawingDocuments.View) ||
            this.$has(perms.ProjectVariables.View),
        },
        information: {
          title: "Basic Information",
          id: "basic-info",
          class: "basic-info",
          icon: "fa-circle-info",
          valid: true,
          hasChange: false,
          key: 1,
          allowInCreateMode: true,
          authorized: this.$has(perms.Projects.View),
          route: {
            name: "view-project",
            params: {
              projectId: true,
            },
          },
        },
        phases: {
          title: "Phases",
          id: "project-phases",
          class: "project-phases",
          icon: "fa-arrow-progress",
          // icon: "fa-timeline-arrow",
          key: 2,
          authorized: false,
        },
        spaces: {
          title: "Spaces",
          id: "project-spaces",
          class: "project-spaces",
          icon: "fa-table-pivot", //square-quarters - table-pivot - square-dashed
          valid: true,
          hasChange: false,
          key: 3,
          authorized: this.$has(perms.Projects.View),
          route: {
            name: "view-project-spaces",
            aliasName: "view-project-space",
            params: {
              projectId: true,
            },
          },
        },
        equipment: {
          title: "Equipment",
          id: "project-equipment",
          class: "project-equipment",
          icon: "fa-plug", //square-quarters - table-pivot - square-dashed
          valid: true,
          hasChange: false,
          key: 4,
          authorized: this.$has(perms.ProjectEquipment.View),
          route: {
            name: "view-project-equipment",
            params: {
              projectId: true,
            },
          },
        },
        buildings: {
          title: "Buildings",
          id: "project-buildings",
          class: "project-buildings",
          icon: "fa-building",
          valid: true,
          hasChange: false,
          key: 5,
          authorized: this.$has(perms.Projects.View),
          route: {
            name: "view-project-buildings",
            params: {
              projectId: true,
            },
          },
        },
        drawingDocuments: {
          title: "Drawing Documents",
          id: "project-drawing-documents",
          class: "project-drawing-documents",
          icon: "fa-pen-ruler",
          valid: true,
          hasChange: false,
          key: 6,
          authorized: this.$has(perms.DrawingDocuments.View),
          route: {
            name: "view-project-drawing-documents",
            params: {
              projectId: true,
            },
          },
        },
        variables: {
          title: "Variables",
          id: "project-variables",
          class: "project-variables",
          icon: "fa-square-root-variable",
          valid: true,
          hasChange: false,
          key: 7,
          authorized: this.$has(perms.ProjectVariables.View),
          route: {
            name: "view-project-variables",
            params: {
              projectId: true,
            },
          },
        },
        header_resources: {
          title: "Resources",
          icon: "fa-arrow-progress",
          key: 8,
          isHeader: true,
          authorized:
            this.$has(perms.ProjectContracts.View) ||
            this.$has(perms.SmallBoard.View) ||
            this.$has(perms.ProjectBudget.View) ||
            this.$has(perms.ProjectTimeline.View) ||
            this.$has(perms.ProjectContacts.View),
        },
        contracts: {
          title: "Contracts",
          id: "project-contracts",
          class: "project-contracts",
          icon: "fa-file-signature",
          valid: true,
          hasChange: false,
          key: 9,
          authorized: this.$has(perms.ProjectContracts.View),
          route: {
            name: "view-project-contracts",
            params: {
              projectId: true,
            },
          },
        },
        smallBoard: {
          title: "Small Board",
          id: "project-smallBoard",
          class: "project-smallBoard",
          icon: "fa-crow",
          valid: true,
          hasChange: false,
          key: 10,
          authorized: this.$has(perms.SmallBoard.View),
          route: {
            name: "view-project-small-board",
            params: {
              projectId: true,
            },
          },
        },
        assignees: {
          title: "Assignees",
          id: "project-assignees",
          class: "project-assignees",
          icon: "fa-user-gear",
          valid: true,
          hasChange: false,
          key: 11,
          authorized: false,
          route: {
            name: "view-project-assignees",
            params: {
              projectId: true,
            },
          },
        },
        budget: {
          title: "Budget",
          id: "project-budget",
          class: "project-budget",
          icon: "fa-sack-dollar",
          valid: true,
          hasChange: false,
          key: 12,
          authorized: this.$has(perms.ProjectBudget.View),
          route: {
            name: "view-project-budget",
            params: {
              projectId: true,
            },
          },
        },
        timeline: {
          title: "Timeline",
          id: "project-timeline",
          class: "project-timeline",
          icon: "fa-timeline-arrow",
          valid: true,
          hasChange: false,
          key: 13,
          authorized: this.$has(perms.ProjectTimeline.View),
          route: {
            name: "view-project-timeline",
            params: {
              projectId: true,
            },
          },
        },
        contacts: {
          title: "Contacts",
          id: "project-contacts",
          class: "project-contacts",
          icon: "fa-users",
          valid: true,
          hasChange: false,
          key: 14,
          authorized: this.$has(perms.ProjectContacts.View),
          route: {
            name: "view-project-contacts",
            params: {
              projectId: true,
            },
          },
        },
        header_Reporting: {
          title: "Reporting",
          icon: "fa-arrow-progress",
          key: 15,
          isHeader: true,
          authorized:
            this.$has(perms.ProjectReports.View) ||
            this.$has(perms.ProjectNotes.View) ||
            this.$has(perms.ProjectActivityLogs.View),
        },
        projectReports: {
          title: "Reports",
          id: "project-reports",
          class: "project-reports",
          icon: "fa-file-spreadsheet",
          valid: true,
          hasChange: false,
          key: 16,
          authorized: this.$has(perms.ProjectReports.Create),
          route: {
            name: "view-project-reports",
            params: {
              projectId: true,
            },
          },
        },
        notes: {
          title: "Notes",
          id: "project-notes",
          class: "project-notes",
          icon: "fa-message-lines",
          valid: true,
          hasChange: false,
          key: 17,
          authorized: this.$has(perms.ProjectNotes.View),
          route: {
            name: "view-project-notes",
            params: {
              projectId: true,
            },
          },
        },
        activityLogs: {
          title: "Activity Logs",
          id: "project-activity-logs",
          class: "project-activity-logs",
          icon: "fa-history",
          valid: true,
          hasChange: false,
          key: 18,
          authorized: this.$has(perms.ProjectActivityLogs.View),
          route: {
            name: "view-project-activity-logs",
            params: {
              projectId: true,
            },
          },
        },
        header_access: {
          title: "Access Control",
          icon: "fa-arrow-progress",
          key: 19,
          isHeader: true,
          authorized:
            this.$has(perms.ProjectAccessList.View) ||
            this.$has(perms.ProjectExternalAccessList.View),
        },
        accessList: {
          title: "DNA Access List",
          id: "project-access-list",
          class: "project-access-list",
          icon: "fa-key",
          valid: true,
          hasChange: false,
          key: 20,
          authorized: this.$has(perms.ProjectAccessList.View),
          route: {
            name: "view-project-access-list",
            params: {
              projectId: true,
            },
          },
        },
        externalAccessList: {
          title: "External Access List",
          id: "project-external-access-list",
          class: "project-external-access-list",
          icon: "fa-key",
          valid: true,
          hasChange: false,
          key: 21,
          authorized: this.$has(perms.ProjectExternalAccessList.View),
          route: {
            name: "view-project-external-access-list",
            params: {
              projectId: true,
            },
          },
        },
      },
      modals: {
        createProjectConfirmation: {
          active: false,
          valid: false,
          loading: false,
        },
      },
    };
  },
  mounted() {
    setTimeout(() => {
      this.isMounted = true;
    }, 500);

    if (
      this.$store.getters.personalSettings != null &&
      this.$store.getters.personalSettings.autoCollapseNavigationDrawerOnProjectOpen
    ) {
      setTimeout(() => {
        this.$cocoSideMenu.viewMini();
      }, 200);
    }
  },
  created() {
    this.readonly = !this.$has(perms.Projects.Update);
    if (this.$route.query.pq) this.returnedProjectsQuery = this.$route.query.pq;
    if (this.$route.params.id) this.project.id = Number.parseInt(this.$route.params.id);
    else this.project.id = null;
    this.isRouteParamsRead = true;
    if (this.createMode) {
      this.isProjectInitiallyLoaded = true;
      this.breadcrumbs[1].text = "Create a New Project";
      if (this.$refs.projectNameBox) this.$refs.projectNameBox.focus();
    }
    // else {
    //   //handle edit
    //   this.getProject();
    // }
    this.showProjectOverview = this.$store.getters.showProjectOverview;
    window.addEventListener("storage", this.onStorageUpdate);
  },
  beforeDestroy() {
    window.removeEventListener("storage", this.onStorageUpdate);
  },
  methods: {
    onStorageUpdate(event) {
      if (event.key === "showProjectOverview") {
        this.$store.dispatch("refreshShowProjectOverview");
        this.showProjectOverview = this.$store.getters.showProjectOverview;
      }
    },
    toggleProjectOverview(state) {
      this.showProjectOverview = state;
      this.$store.dispatch("setShowProjectOverview", state);
    },
    refreshProjectOverview() {
      if (this.projectOverviewSection) this.projectOverviewSection.refresh();
    },
    openCreateJiraProjectModal() {
      if (this.tabs.information.hasChange) {
        this.$dialog.notify.warning("Project has unsaved changes!", {
          position: "top-right",
          timeout: 3000,
        });
        return;
      }
      this.$dialog
        .warning({
          text: `Are you sure you want to generate a new Jira project and link to this Concordia project <b>${this.project.name}</b>?`,
          title: `Create Jira Project?`,
          color: "info",
          persistent: true,
          actions: {
            false: {
              text: "Cancel",
            },
            true: {
              text: "Confirm",
              color: "info",
              handle: () => {
                return jiraAPI
                  .createJiraProject(this.project.id)
                  .then((resp) => {
                    if (resp.data) {
                      this.$dialog.notify.success("MS Team created and linked successfully!", {
                        position: "top-right",
                        timeout: 3000,
                      });
                      window.location.reload();
                    } else
                      this.$dialog.notify.error("Error creating the Jira project!", {
                        position: "top-right",
                        timeout: 3000,
                      });
                  })
                  .catch((resp) => {
                    this.$dialog.notify.error("Error creating the Jira project!", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  });
              },
            },
          },
        })
        .then((res) => {});
    },
    openCreateMsTeamModal() {
      if (this.tabs.information.hasChange) {
        this.$dialog.notify.warning("Project has unsaved changes!", {
          position: "top-right",
          timeout: 3000,
        });
        return;
      }
      this.$dialog
        .warning({
          text: `Are you sure you want to generate a new MS Team and link to this Concordia Project:
          <b>${this.project.name}</b>?<br/>
          <span class="orange--text text--darken-2 d-flex align-center mt-3">
              <i class="fas fa-circle-info mr-2"></i>Please don't close this dialog, this may take a moment!
          </span>
          `,
          title: `Create MS Team?`,
          color: "info",
          persistent: true,
          actions: {
            false: {
              text: "Cancel",
            },
            true: {
              text: "Confirm",
              color: "info",
              handle: () => {
                return msTeamsAPI
                  .createTeam(this.project.id)
                  .then((resp) => {
                    if (resp.data) {
                      this.$dialog.notify.success("MS Team created and linked successfully!", {
                        position: "top-right",
                        timeout: 3000,
                      });
                      window.location.reload();
                    } else
                      this.$dialog.notify.error("Error creating the MS Team!", {
                        position: "top-right",
                        timeout: 3000,
                      });
                  })
                  .catch((resp) => {
                    this.$dialog.notify.error("Error creating the MS Team!", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  });
              },
            },
          },
        })
        .then((res) => {});
    },
    switchToVariablesTab() {
      if (!this.$has(perms.ProjectVariables.View)) return;
      this.tab = this.getTab(this.tabs.variables.id).key;
    },
    getTab(id) {
      // var tab = Object.values(this.tabs).find((t) => t.id == id);
      var tab = this.authorizedTabs.find((t) => t.id == id) || {};
      return tab;
    },
    projectNumberChanged(newNumber) {
      var newFullName =
        this.project.fullName.slice(0, 1) + newNumber + this.project.fullName.slice(6);

      this.project.number = newNumber;
      this.projectCemented.number = newNumber;

      this.project.fullName = newFullName;
      this.projectCemented.fullName = newFullName;
    },
    wheelIt(evt) {
      evt.preventDefault();
      // this.$log("wheel", evt);
      if (evt.deltaY > 0) {
        // this.$log("<<<< left");
        if (this.$refs.tabsContainer.querySelector(".v-slide-group__next"))
          this.$refs.tabsContainer.querySelector(".v-slide-group__next").click();
      } else {
        // this.$log(">>>> right");
        if (this.$refs.tabsContainer.querySelector(".v-slide-group__prev"))
          this.$refs.tabsContainer.querySelector(".v-slide-group__prev").click();
      }
      // this.$log("|||| scrollLeft", this.$refs.tabsContainer.scrollLeft);
      evt.stopPropagation();
    },
    projectTabValidityChanged(val, item) {
      this.tabs[item].valid = val;
    },
    projectTabHasChanged(val, item) {
      this.tabs[item].hasChange = val;
    },
    projectVariablesChanged(vars) {
      this.variablesInstallationFactor = vars.installationFactor;
    },
    getProject() {
      this.$log(">>>>>>>>>>>>>>>>>>>>>>>>> getProject");
      this.isProjectLoading = true;
      this.projectError = false;
      document.title = "Loading project... | Concordia";
      projectsAPI
        .get(this.project.id)
        .then((resp) => {
          this.$log(">>>>>>>>>>>>>>>>>>>>>>>>> projectsAPI RESP", resp.data);
          this.isProjectInitiallyLoaded = true;
          this.isProjectLoading = false;
          this.updateProject(resp.data);
          setTimeout(() => {
            if (this.$refs.projectNameBox) this.$refs.projectNameBox.focus();
          }, 100);
          this.$log(">>>>>>>>>>>>>>>>>>>>>>>>> this.$route", this.$route);
          // this.checkForSingleSpaceRoute(this.$route);
        })
        .catch((err) => {
          this.$log(">>>>>>>>>>>>>>>>>>>>>>>>> projectsAPI catch", err);
          this.projectError = true;
          this.isProjectLoading = false;
          this.projectErrorStatus = err.response?.status;
          if (this.projectErrorStatus == 404) {
            document.title = "Error: Project Not Found! | Concordia";
          } else {
            document.title = "Error: Loading Project! | Concordia";
          }
          this.project = null;
          this.cementProject();
          // this.$handleError(err);
        });
    },
    createProject() {
      this.isProjectSaving = true;
      var toSend = this.cloneDeep(this.project);
      this.$log("Create Project", toSend);
      if (toSend.state != null && typeof toSend.state != "string")
        toSend.state = toSend.state.value;
      projectsAPI
        .add(toSend)
        .then((resp) => {
          this.isProjectSaving = false;
          var message = "Project updated!";
          if (this.createMode) {
            this.$log("resp.data", resp.data);
            this.updateProject(resp.data);
            message = "Project created successfully!";
            this.$router.push({
              name: `view-project`,
              params: { id: this.project.id },
            });
          } else {
            this.updateProject(resp.data);
          }
          this.$dialog.notify.success(message, {
            position: "top-right",
            timeout: 3000,
          });
          this.$refs.basicInfo.refreshJiraProjects();
        })
        .catch((err) => {
          this.$log("err", err, err.request);
          this.isProjectSaving = false;
          this.$handleError(err);
        });
    },
    updateProject(data) {
      this.project = data;
      this.refreshProjectTitle();
      this.cementProject();
    },
    refreshProjectTitle() {
      if (!this.isProjectInitiallyLoaded) return;
      // if (this.breadcrumbs.length > 1) {
      //   this.breadcrumbs[1].text =
      //     this.project.fullName.length > 75
      //       ? this.project.fullName.substr(0, 75) + "..."
      //       : this.project.fullName;
      // }
      document.title = this.project.fullName + " | Concordia";
    },
    cementProject() {
      this.projectCemented = this.cloneDeep(this.project);
      this.checkForProjectInfoChanges();
    },
    checkForProjectInfoChanges() {
      this.tabs.information.hasChange = !this.isEqual(this.project, this.projectCemented);
      // this.$log("#### this.project", this.cloneDeep(this.project));
      // this.$log("#### this.projectCemented", this.cloneDeep(this.projectCemented));
      if (this.tabs.information.hasChange) this.$guardChanges();
      else this.$releaseChanges();
      if (this.tabs.information.hasChange) {
        this.$log("%c==>> DIFF", "color: red", this.deepDiff(this.cementProject, this.project));
      }
    },
    discardProjectInfoChanges() {
      this.project = this.cloneDeep(this.projectCemented);
    },
    // checkForSingleSpaceRoute(route) {
    //   if (route.name == "view-project-space") this.tab = this.tabs.spaces.key;
    // },
    openProjectSpace(id) {
      const path = `/projects/${this.project.id}/spaces/${id}`;
      if (this.$route.path !== path) this.$router.push(path);
    },
    del() {
      this.$dialog
        .warning({
          text: `Are you sure you want to delete this project?`,
          title: `Delete Project?`,
          color: "error",
          persistent: true,
          actions: {
            false: {
              text: "Cancel",
            },
            true: {
              text: "Confirm",
              color: "error",
              handle: () => {
                return projectsAPI
                  .delete(this.project.id)
                  .then(() => {
                    this.$router.replace(this.projectStage.route.path);
                    this.$dialog.notify.success("Project deleted successfully!", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  })
                  .catch(() => {
                    this.$dialog.notify.error("An error occurred during deleting the project!", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  });
              },
            },
          },
        })
        .then((res) => {});
    },
    openCloneModal() {
      this.$refs.cloneProjectModal.open(this.project);
    },
    copyToClipboard() {
      this.copyProjectFullNameSnackbar = true;
      navigator.clipboard.writeText(this.project.fullName);
    },
    onTabChange() {
      var targetAuthTab = this.authorizedTabs.find((t) => t.key == this.tab);
      if (targetAuthTab && targetAuthTab.route != null) {
        var params = {};
        if (targetAuthTab.route.params && targetAuthTab.route.params.projectId) {
          params.id = this.project.id;
        }
        this.$router.push({
          name: targetAuthTab.route.name,
          params: params,
        });
      }
    },
  },
  computed: {
    authorizedTabs() {
      var authTabs = this.cloneDeep(
        Object.values(this.tabs).filter((tab) => tab.authorized && !tab.isHeader)
      );
      this.$log("authTabs", this.cloneDeep(authTabs));
      for (let i = 0; i < authTabs.length; i++) {
        authTabs[i].key = i;
      }
      return authTabs;
    },
    authorizedTabsWithHeaders() {
      var authTabs = Object.values(this.tabs).filter((tab) => tab.authorized);
      for (let i = 0; i < authTabs.length; i++) {
        if (authTabs[i].key >= 0) {
          authTabs[i].key = i;
        }
      }
      return authTabs;
    },
    projectInstallationFactor() {
      if (this.variablesInstallationFactor != null) return this.variablesInstallationFactor;
      return this.project.installationFactor;
    },
    projectActiveTabClass() {
      // var active = Object.values(this.tabs).find((t) => t.key == this.tab);
      var active = Object.values(this.authorizedTabs).find((t) => t.key == this.tab);
      if (active != null) {
        return active.class;
      }
      return "none";
    },
    projectStage() {
      return this.getEnumMember(enums.PROJECT_STAGE, this.project.stage);
    },
    projectStatusList() {
      if (!this.isProjectInitiallyLoaded) return [];
      return this.projectStage.PROJECT_STATUS;
    },
    breadcrumbs() {
      if (!this.isProjectInitiallyLoaded)
        return [
          {
            text: "Projects",
            exact: true,
            disabled: true,
            to: {
              path: "/projects",
            },
          },
          {
            text: "...",
            disabled: true,
          },
        ];
      if (this.returnedProjectsQuery != null)
        return [
          {
            text: this.projectStage.text,
            exact: true,
            to: {
              path: this.projectStage.route.path,
              query: { pq: this.returnedProjectsQuery },
            },
          },
          {
            text: "",
            disabled: true,
          },
          // {
          //   text: this.project.fullName,
          //   disabled: true,
          // },
        ];
      else
        return [
          {
            text: this.projectStage.text,
            exact: true,
            to: {
              path: this.projectStage.route.path,
            },
          },
          {
            text: "",
            disabled: true,
          },
          // {
          //   text: this.project.fullName,
          //   disabled: true,
          // },
        ];
    },
    createMode() {
      if (!this.isRouteParamsRead || this.project == null) return null;
      // this.$log("createMode() >>> this.project.id", this.project.id);
      if (this.project.id == null) return true;
      else return false;
    },
    projectOverviewSection() {
      if (!this.isMounted) return null;
      return this.$refs.projectOverviewSection;
    },
  },
  watch: {
    createMode: {
      handler(newValue, oldValue) {
        if (oldValue === true && newValue === false) {
          //it was a new project that got saved and routed to be viewed/edited
          this.$log(">>>>>>>>>>>>>>> NEW PROJECT EDIT <<<<<<<<<<<<<<<<<<<");
          this.refreshProjectTitle();
        }
      },
      deep: true,
    },
    $route: {
      handler(newRoute, oldRoute) {
        //TODO the next code need to change when (done from authorized tabs stuff)
        // this.$log("EDIT PROJECT - oldRoute", oldRoute, "newRoute", newRoute);

        var targetTab = Object.values(this.tabs).find(
          (t) => t.route && (t.route.name == newRoute.name || t.route.aliasName == newRoute.name)
        );
        var targetAuthTab = this.authorizedTabs.find(
          (t) => t.route && (t.route.name == newRoute.name || t.route.aliasName == newRoute.name)
        );

        //route that exists inside an edit project page
        if (targetTab) {
          // new project?
          if (
            newRoute.params &&
            ((oldRoute && newRoute.params.id != oldRoute.params.id) ||
              newRoute.params.id != this.project.id)
          ) {
            if (newRoute.params.id) {
              this.project.id = Number.parseInt(newRoute.params.id);
              this.getProject();
            }
          }
          //change to the requested tab
          if (targetAuthTab) this.tab = targetAuthTab.key;
          else this.tab = this.tabs.information.key;

          if (this.isProjectInitiallyLoaded) {
            document.title = this.project.fullName + " | Concordia";
          }
        }

        this.$log("EDIT PROJECT - targetTab", targetTab);
        this.$log("EDIT PROJECT - targetAuthTab", targetAuthTab);
        this.$log("EDIT PROJECT - tab", this.tab);
      },
      deep: true,
      immediate: true,
    },
    project: {
      handler() {
        this.checkForProjectInfoChanges();
      },
      deep: true,
    },
    tab: {
      handler() {
        //TODO the next code need to change when (done from authorized tabs stuff)
        if (this.tab === this.tabs.information.key) {
          setTimeout(() => {
            if (this.$refs.projectNameBox) this.$refs.projectNameBox.focus();
          }, 100);
        } else if (this.tab === this.tabs.spaces.key) {
          setTimeout(() => {
            this.$refs.projectSpaces.focus();
          }, 500);
        } else if (this.tab === this.tabs.projectReports.key) {
          setTimeout(() => {
            this.$refs.projectReports.focus();
          }, 500);
        }
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss">
.project-tab-header {
  text-transform: uppercase;
  font-size: 12px;
  padding-left: 16px;
  letter-spacing: 0.87px;
  color: rgba($shades-black, 0.64) !important;
  margin-bottom: 0.25rem;
  margin-top: 2rem;

  &:nth-child(2) {
    margin-top: 0.75rem;
  }
}

.v-tabs--vertical {
  .v-tabs-bar {
    border-radius: inherit;
    height: auto !important;

    .v-tab {
      justify-content: start;
      height: 36px !important;

      i {
        width: 16px;
      }

      &:not(.v-tab--active) {
        color: rgba($shades-black, 0.72) !important;
      }
    }
  }
}

.stage-select {
  display: inline-block;
  width: min-content;
  margin-left: 0.25rem;

  .v-text-field__details {
    display: none;
  }

  .v-input__slot {
    min-height: 32px !important;
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
    // box-shadow: none !important;

    .v-select__slot {
      height: 32px;
    }
  }
  .theme--light.v-text-field--filled:not(.v-input--is-focused):not(.v-input--has-state)
    > .v-input__control
    > .v-input__slot:not(:hover) {
    background-color: transparent !important;
  }
  .v-text-field.v-text-field--solo.v-input--dense > .v-input__control {
    min-height: unset !important;
  }

  .v-input__append-inner {
    display: none !important;
  }
}

.project-stage-pointer {
  .project-stage {
    cursor: pointer !important;
  }
}

.project-drawing-documents-tab-opened {
  /*height: 100%;
  & > .v-window__container {
    height: 100%;
    .v-window-item {
      height: 100%;
      & > .container {
        height: 100%;
      }
    }
  }*/

  height: 100%;
  overflow: hidden;

  & > .container {
    height: 100%;
    overflow: hidden;
  }
}

.project-spaces-tab-opened {
  height: 100%;
  overflow: hidden;

  & > .container {
    height: 100%;
    overflow: hidden;
  }
}

.project-equipment-tab-opened {
  height: 100%;
  overflow: hidden;

  & > .container {
    height: 100%;
    overflow: hidden;
  }
}

.project-variables-tab-opened {
  // height: 100%;

  // & > .v-window__container {
  //   height: 100%;

  //   .v-window-item {
  //     height: 100%;

  //     & > div {
  //       height: 100%;
  //       display: flex;
  //       flex-direction: column;

  //       & > .container {
  //         height: 100%;
  //         display: flex;
  //         flex-direction: column;

  //         & > div {
  //           height: 100%;
  //           flex: 1 1 auto;
  //           overflow: overlay;
  //         }
  //       }
  //     }
  //   }
  // }

  height: 100%;
  overflow: hidden;

  & > .container {
    height: 100%;
    overflow: hidden;
  }
}

.project-contracts-tab-opened {
  // height: 100%;

  // & > .v-window__container {
  //   height: 100%;

  //   .v-window-item {
  //     height: 100%;

  //     & > div {
  //       height: 100%;
  //       display: flex;
  //       flex-direction: column;

  //       & > .container {
  //         height: 100%;
  //         display: flex;
  //         flex-direction: column;

  //         & > div {
  //           height: 100%;
  //           flex: 1 1 auto;
  //           overflow: overlay;
  //         }
  //       }
  //     }
  //   }
  // }

  height: 100%;
  overflow: hidden;

  & > .container {
    height: 100%;
    overflow: hidden;
  }
}

.project-notes-tab-opened {
  // height: 100%;

  // & > .v-window__container {
  //   height: 100%;

  //   .v-window-item {
  //     height: 100%;

  //     & > .container {
  //       height: 100%;
  //     }
  //   }
  // }

  height: 100%;
  overflow: hidden;

  & > .container {
    height: 100%;
    overflow: hidden;
  }
}

.project-activity-logs-tab-opened {
  // height: 100%;

  // & > .v-window__container {
  //   height: 100%;

  //   .v-window-item {
  //     height: 100%;

  //     & > .container {
  //       height: 100%;
  //     }
  //   }
  // }

  height: 100%;
  overflow: hidden;

  & > .container {
    height: 100%;
    overflow: hidden;
  }
}

.project-smallBoard-tab-opened {
  // height: 100%;

  // & > .v-window__container {
  //   height: 100%;

  //   .v-window-item {
  //     height: 100%;

  //     & > .container {
  //       height: 100%;
  //     }
  //   }
  // }

  height: 100%;
  overflow: hidden;

  & > .container {
    height: 100%;
    overflow: hidden;
  }
}

.project-reports-tab-opened {
  // height: 100%;

  // & > .v-window__container {
  //   height: 100%;

  //   .v-window-item {
  //     height: 100%;

  //     & > .container {
  //       height: 100%;
  //       display: flex;
  //       flex-direction: column;

  //       & > div {
  //         height: 100%;
  //         flex: 1 1 auto;
  //         overflow: overlay;
  //       }
  //     }
  //   }
  // }

  height: 100%;
  overflow: hidden;
  background: #fff;

  & > .container {
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    & > div {
      height: 100%;
      flex: 1 1 auto;
      overflow: overlay;
    }
  }
}

.jira-project-key {
  color: $shades-black;
  font-size: 14px;
  font-weight: 600;
}

.jira-project-name {
  color: rgba($shades-black, 0.54);
  font-size: 14px;
  font-weight: 400;
  max-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.v-main__wrap > .container.container--fluid:not(.mdAndDown) {
  height: 100%;

  & > .project-view-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;

    & > .container.container--fluid.project-view {
      display: flex;
      flex-direction: column;
      height: 100%;

      & > .row:first-child {
        flex: 0 1 auto;
      }
      & > .row:nth-child(2) {
        flex: 1 1 auto;
        overflow-y: auto;

        & > .col,
        & > .col-12 {
          overflow: hidden;
          position: relative;

          & > .v-card {
            overflow-y: auto;
            display: flex;
            flex-direction: column;
            flex-grow: 0;
            position: absolute;
            width: calc(100% - 24px);
            height: calc(100% - 24px);
            top: 12px;

            .v-window {
              overflow: auto;
              overflow: overlay;
            }
          }
        }
      }
    }
  }
}

.v-list-item__title {
  font-size: 14px !important;

  .v-list-item__mask {
    color: inherit !important;
    background: rgba($primary-base, 0.2) !important;
    border-radius: 2px !important;
  }
}

.project-overview-card {
  padding: 0;
  display: flex;
  flex-flow: column;
  height: 100%;

  .project-overview-header {
    flex: 0 1 auto;
    padding: 0rem 0.5rem 0rem 1rem;
    background: rgba($shades-black, 0.04);
    border-bottom: 1px solid rgba($shades-black, 0.16);
    border-top-left-radius: inherit !important;
    border-top-right-radius: inherit !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    box-shadow: 0 3px 6px rgba($shades-black, 0.02), 0 3px 6px rgba($shades-black, 0.08);
    z-index: 1;

    .v-input--switch {
      margin: 0;

      .v-label {
        font-size: 12px;
      }

      .v-messages {
        display: none !important;
      }
    }

    .v-btn-toggle--group > .v-btn.v-btn {
      margin: 4px 1px;
    }
  }

  .project-overview-content {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    overflow-y: auto;
    padding: 0.5rem 0;

    &.empty {
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: calc(100% - 4rem);
      }

      p {
        margin-top: 0.5rem;
        margin-bottom: 0;
        opacity: 0.54;
      }
    }
  }

  .project-overview-footer {
    position: relative;
    flex: 0 1 auto;
    text-align: center;
    padding: 0.75rem 1rem;
    background: rgba($shades-black, 0.04);
    border-top: 1.5px solid rgba($shades-black, 0.2);
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    border-bottom-left-radius: inherit !important;
    border-bottom-right-radius: inherit !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 -3px 6px rgba($shades-black, 0.02), 0 -3px 6px rgba($shades-black, 0.08);
    z-index: 1;

    .project-overview-price-type {
      display: inline-flex;
      position: absolute;
      top: -36px;
      height: 36px;
      left: 50%;
      transform: translateX(-50%);
      background: #f7f7f7;
      border-radius: 0.5rem 0.5rem 0 0;
      border: 1.5px solid rgba($shades-black, 0.2);
      border-bottom: none;
      box-shadow: 0 -4px 6px rgba($shades-black, 0.01), 0 -4px 6px rgba($shades-black, 0.06);
      z-index: 1000;

      .v-btn {
        border-radius: 0.25rem !important;
      }
    }
  }
}
.hide-overview-btn {
  position: absolute;
  left: 2px;
  top: 22px;
  padding: 0 !important;
  z-index: 10;
}
</style>
