<template>
  <v-card
    v-if="selected != null"
    elevation="2"
    :class="{
      'contract-phase-record-card-no-border': noBorder,
      'contract-phase-record-card-valid-choice': validChoice,
      'contract-phase-record-card-disabled': disabled,
    }"
    class="contract-phase-record-card d-flex align-center justify-space-between flex-row py-2 px-3"
    style="gap: 1rem"
    :style="{
      width: fullWidth ? '100%' : 'auto',
    }"
    rounded="lg"
  >
    <div class="d-flex flex-column align-start" style="gap: 0.5rem">
      <h4>
        <i class="fad fa-folder-open mr-1 secondary--text"></i>
        <a
          small
          text
          target="_blank"
          class="py-0 px-1 fs-13px secondary--text"
          max-height="20px"
          :href="`/projects/${selected.project.id}`"
          >{{ selected.project.fullName }}
        </a>
      </h4>
      <h4 class="d-flex align-center">
        <i class="fad fa-calendar-lines mr-2 secondary--text" style="width: 16px"></i>
        <v-chip color="secondary" dark class="px-1" label x-small>
          <span class="fs-13px font-weight-medium">{{ selected.startDate.substr(0, 7) }}</span>
        </v-chip>
      </h4>
    </div>
    <div class="d-flex flex-row align-center" style="gap: 1rem">
      <div class="d-flex flex-column align-end text-right" style="gap: 6px; height: 61px">
        <b class="opacity-87 fs-12px" style="white-space: nowrap">{{
          getLaborTypeObj(selected.sourceLaborType).desc
        }}</b>
        <span
          class="d-flex align-center available-zone"
          style="gap: 0.35rem"
          @click="openSourceRecords"
        >
          <span class="fs-12px font-weight-bold opacity-64">Available:</span>
          <span class="green--text fs-18px font-weight-medium mono-font">
            <!-- {{ selected.availableHours.toString().padStart(2, "0") }} -->
            {{ (selected.availableHours - selected.offeredHours) | fixedFloatFormat }}
          </span>
        </span>
      </div>
      <v-divider vertical></v-divider>
      <div class="d-flex flex-column align-start" style="gap: 2px; height: 61px">
        <b class="opacity-72">Offered Hours</b>
        <counter
          v-model="selected.offeredHours"
          :min="0"
          :max="selected.availableHours"
          @input="offeredHoursChanged"
          :readonly="readonly || disabled"
          isFloat
        />
      </div>
      <v-divider vertical></v-divider>
      <div class="d-flex flex-column align-start" style="gap: 2px">
        <b class="opacity-72" style="white-space: nowrap">Target Labor Type</b>
        <v-select-alt
          style="width: 200px; max-width: 200px"
          class="v-select-alt-short"
          solo
          flat
          dense
          hide-details
          v-model="selected.targetLaborType"
          @input="targetLaborTypeChanged"
          :items="enums.LABOR_TYPE_LIST"
          item-text="desc"
          :readonly="readonly || disabled"
        >
          <template v-slot:selection="{ item }">
            <span class="d-flex flex-column justify-end">
              <span
                v-if="highlightDefaultLabor != null && item.value == highlightDefaultLabor"
                class="info--text fs-10px mb-0"
                style="line-height: 1"
              >
                <i class="far fa-check mr-1"></i>Default
              </span>
              <span class="font-weight-medium fs-13px" style="line-height: 1">{{ item.desc }}</span>
            </span>
          </template>
          <template v-slot:item="{ item }">
            <span class="d-flex flex-column justify-end">
              <span
                v-if="highlightDefaultLabor != null && item.value == highlightDefaultLabor"
                class="info--text fs-10px mb-0"
                style="line-height: 1"
              >
                <i class="far fa-check mr-1"></i>Default
              </span>
              <span class="font-weight-medium fs-13px" style="line-height: 1">{{ item.desc }}</span>
            </span>
          </template>
        </v-select-alt>
      </div>
    </div>
  </v-card>
</template>

<script>
import enums from "../../../plugins/enums";

export default {
  name: "available-record-card",
  components: {},
  data() {
    return {
      enums,
      selected: null,
    };
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
    noBorder: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    highlightDefaultLabor: {
      type: Number,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.selected = this.cloneDeep(this.value);
  },
  methods: {
    openSourceRecords() {
      this.$emit("source-records");
    },
    offeredHoursChanged() {
      this.$emit("input", this.cloneDeep(this.selected));
    },
    targetLaborTypeChanged() {
      this.$emit("input", this.cloneDeep(this.selected));
    },
    getLaborTypeObj(val) {
      return this.getEnumMember(enums.LABOR_TYPE, val);
    },
  },
  computed: {
    validChoice() {
      if (this.selected.offeredHours != 0) return true;
      return false;
    },
  },
  watch: {
    value: {
      handler() {
        if (this.isEqual(this.value, this.selected)) return;
        this.selected = this.cloneDeep(this.value);
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss">
.available-zone {
  display: inline-flex;
  cursor: pointer;
  background: rgba($shades-black, 0.06);
  border: 1px solid rgba($shades-black, 0.16);
  border-radius: 0.5rem;
  padding: 0.25rem 0.5rem;
  line-height: 1;
  transition: all 0.15s ease-out;

  &:hover {
    background: rgba($success-base, 0.2);
  }
}
</style>
