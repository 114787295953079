export default [
  {
    text: "Actions",
    value: "actions",
    align: "left",
    sortable: false,
    width: "110px",
    order: 0,
    hidable: true,
    hidden: false,
  },
  {
    text: "Number",
    value: "number",
    order: 1,
    hidable: true,
    width: "100px",
    hidden: false,
  },
  {
    text: "Name",
    value: "name",
    order: 2,
    width: "90px",
    hidable: true,
    hidden: false,
  },
  {
    text: "PO#",
    value: "po",
    order: 3,
    hidable: true,
    width: "90px",
    hidden: false,
  },
  {
    text: "PO Receive Date",
    value: "poReceiveDate",
    order: 4,
    hidable: true,
    width: "150px",
    hidden: false,
  },

  {
    text: "Status",
    value: "status",
    order: 5,
    hidable: true,
    width: "90px",
    hidden: false,
  },
  {
    text: "Start Date",
    value: "startDate",
    order: 6,
    hidable: true,
    width: "120px",
    hidden: false,
  },
  {
    text: "End Date",
    value: "endDate",
    order: 7,
    hidable: true,
    width: "120px",
    hidden: false,
  },
  {
    text: "Tempo planned time link",
    value: "tempoPlannedTimeLink",
    order: 8,
    hidable: true,
    width: "200px",
    hidden: false,
  },
  {
    text: "Tempo Logged time link",
    value: "tempoLoggedTimeLink",
    order: 9,
    hidable: true,
    width: "190px",
    hidden: false,
  },
  {
    text: "Total Authorized Fees",
    value: "totalAuthorizedFees",
    order: 10,
    hidable: true,
    width: "175px",
    hidden: false,
  },
  {
    text: "Created",
    value: "createDate",
    width: "110px",
    order: 3,
    hidable: true,
    hidden: false,
  },
  {
    text: "Last Update",
    value: "updateDate",
    width: "130px",
    order: 4,
    hidable: true,
    hidden: false,
  },
  {
    text: "Creator",
    value: "createdBy",
    width: "130px",
    order: 5,
    hidable: true,
    hidden: false,
  },
  {
    text: "Last Updater",
    value: "updatedBy",
    width: "130px",
    order: 6,
    hidable: true,
    hidden: false,
  },
];
