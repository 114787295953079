<template>
  <div>
    <v-tooltip bottom nudge-bottom="-5">
      <template v-slot:activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on">
          <v-btn height="32" color="secondary" small @click="open()">
            <i class="fad fa-file-spreadsheet mr-2" style="font-size: 16px"></i>
            Export
          </v-btn>
        </div>
      </template>
      <span>Export Entry Details to Excel</span>
    </v-tooltip>
    <v-dialog v-model="active" max-width="550px" persistent>
      <v-card>
        <v-card-title class="font-weight-bold d-flex" small>
          <i class="fad fa-file-spreadsheet mr-2"></i>Export Entry Details?
        </v-card-title>
        <v-divider></v-divider>
        <v-form>
          <v-card-text>
            <h3 class="mb-3 fs-17px font-weight-bold">
              Generate and excel report from this entry details list?
            </h3>
            <div
              class="px-2 rounded mb-2"
              :class="{ 'blue lighten-5': includeUsersTabs }"
              style="margin-top: 26px; padding-top: 6px; padding-bottom: 6px"
              :style="
                includeUsersTabs
                  ? 'border: 1.5px dashed #2196f3 !important'
                  : 'border: 1.5px dashed #b0bec5'
              "
            >
              <v-checkbox
                color="info"
                v-model="includeUsersTabs"
                class="ma-0 pa-0"
                hide-details
                :disabled="isLoading"
              >
                <template v-slot:label>
                  <label class="v-switch-label fs-15px" :class="{ 'info--text': includeUsersTabs }">
                    Include a Tab Per User For Each Labor Type?
                  </label>
                </template>
              </v-checkbox>
            </div>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="discard()"> <i class="fal fa-xmark mr-2"></i> Cancel </v-btn>
            <v-btn
              color="info"
              @click="generateConfirmed()"
              :loading="isLoading"
              :disabled="isLoading"
            >
              <i class="fal fa-check mr-2"></i> Generate
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import enums from "../../../plugins/enums";
import contractsService from "../../Contracts/services/contracts-service";
import { eventBus } from "../../../main";

export default {
  name: "export-entry-details-to-excel",
  data() {
    return {
      enums,
      isLoading: false,
      active: false,
      includeUsersTabs: false,
    };
  },
  props: {
    options: {
      type: Object,
      default: () => {},
    },
    entryData: {
      type: Object,
      default: () => {},
    },
    recordsList: {
      type: Array,
      default: () => [],
    },
    primaryTitle: {
      type: String,
      default: () => "",
    },
    secondaryTitle: {
      type: String,
      default: () => "",
    },
    type: {
      type: Number,
      default: null,
    },
  },
  methods: {
    open() {
      this.active = true;
    },
    discard() {
      this.active = false;
      this.isLoading = false;
      this.includeUsersTabs = false;
    },
    isDirectExpense() {
      return (
        this.entryData.laborType == enums.LABOR_TYPE.DirectExpensesCost.value ||
        this.entryData.laborType == enums.LABOR_TYPE.DirectExpensesRevenue.value ||
        this.entryData.originalLaborType == enums.LABOR_TYPE.DirectExpensesCost.value ||
        this.entryData.originalLaborType == enums.LABOR_TYPE.DirectExpensesRevenue.value
      );
    },
    generateConfirmed() {
      this.$log(this.recordsList);
      let targetIds = this.recordsList.map((e) => e.record.id);
      this.isLoading = true;
      let entryType = 3;
      if (this.entryData.rowType == "UserEntry") {
        if (this.entryData.userId) entryType = 1;
        else entryType = 2;
      }
      var body = {
        entryType: entryType,
        primaryTitle: this.primaryTitle,
        secondaryTitle: this.secondaryTitle,
        date: this.options.date,
        targetIds: targetIds,
        isCost: !!this.entryData.isCost,
        isExpense: this.isDirectExpense(),
        bigBoardType: this.type,
        includeUsersTabs: this.includeUsersTabs,
      };
      this.$log("generateConfirmed >> this.options", body);
      const optionsToSend = this.$clean(body, true);
      contractsService.exportEntryDetailsInExcel(body).then(this.onResponse).catch(this.onCatch);
    },
    onResponse(resp) {
      this.$log("report generate resp.data", resp.data);
      this.$notify(resp.data, "info", resp.data.id, true);
      eventBus.$emit("add-to-download-center", resp.data);
      this.discard();
    },
    onCatch(err) {
      this.$log(">>> projectService export", err);
      this.isLoading = false;
      this.$dialog.notify.error(err, {
        position: "top-right",
        timeout: 3000,
      });
    },
  },
  computed: {},
  watch: {},
  components: {},
};
</script>

<style lang="scss"></style>
