<template>
  <div class="alt-comp-wrapper">
    <v-autocomplete-alt
      auto-select-first
      label="<i class='fab fa-jira mr-1'></i> Jira Issue"
      id="projectJiraCode"
      placeholder="Jira Key ex: B22009"
      :value="value"
      @input="handleSelectedJiraIssue"
      :items="jiraIssues"
      :filter="jiraIssuesFilter"
      dense
      filled
      item-text="key"
      item-value="key"
      :loading="isJiraIssuesLoading"
      :disabled="isJiraIssuesLoading"
      :readonly="readonly"
    >
      <template v-for="name in ['item', 'selection']" #[`${name}`]="data">
        <v-row
          align-content="center"
          justify="start"
          no-gutters
          style="flex: none; max-width: calc(100% - 40px); flex-wrap: nowrap"
          class="py-0"
          :key="`issueTemplate-${name}`"
        >
          <v-col sm="auto" class="d-flex align-center mr-2">
            <v-img
              v-if="
                data.item.fields.project.avatarUrls['32x32'] != null &&
                data.item.fields.project.avatarUrls['32x32'] != ''
              "
              :src="wrapAvatarUrl(data.item.fields.project.avatarUrls['32x32'])"
              height="32"
              width="32"
              position="center center"
              contain
            >
            </v-img>
          </v-col>
          <v-col class="d-flex justify-center col-auto flex-column">
            <div class="jira-project-key">
              {{ data.item.key }}
              <v-chip class="ml-2" x-small color="">
                <div class="jira-project-category-name">
                  {{ data.item.fields.summary }}
                </div>
              </v-chip>
            </div>
            <div class="jira-project-name" style="max-width: 100%">
              {{ data.item.id }}
            </div>
          </v-col>
        </v-row>
      </template>
    </v-autocomplete-alt>
    <v-btn
      min-width="28px !important"
      width="28px !important"
      height="28px !important"
      color="secondary"
      outlined
      class="pa-0"
      small
      target="_blank"
      :href="`https://dangeloconsultants.atlassian.net/browse/${value}`"
      :disabled="value == null || readonly"
      v-if="allowView && value"
    >
      <i class="far fa-external-link"></i>
    </v-btn>
    <v-btn
      min-width="28px !important"
      width="28px !important"
      height="28px !important"
      color="secondary"
      outlined
      class="pa-0"
      :loading="createJiraIssuesLoading"
      small
      v-if="!value && phaseId"
      @click="createPhaseJiraIssue()"
    >
      <i class="fal fa-plus"></i>
    </v-btn>
  </div>
</template>

<script>
import jiraAPI from "../../Projects/services/jira-service";

export default {
  props: {
    value: {
      type: String,
    },
    jiraIssueId: {
      type: String,
    },
    projectId: {
      type: Number,
    },
    phaseId: {
      type: [Number, String],
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    allowView: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      jiraIssues: [],
      isJiraIssuesLoading: false,
      createJiraIssuesLoading: false,
    };
  },
  methods: {
    handleSelectedJiraIssue(val) {
      this.$emit("input", val);
      const elm = this.jiraIssues.find((elm) => elm.key == val);
      this.$emit("update:jiraIssueId", elm ? elm.id : null);
    },
    getJiraIssues() {
      this.isJiraIssuesLoading = true;
      jiraAPI
        .issues(this.projectId)
        .then((resp) => {
          this.jiraIssues = resp.data.result || [];
          this.isJiraIssuesLoading = false;
        })
        .catch((err) => {
          this.isJiraIssuesLoading = false;
          this.$handleError(err);
        });
    },
    jiraIssuesFilter(item, queryText, itemText) {
      const text1 = item.key.toLowerCase();
      const text2 = item.id.toLowerCase();
      const text3 = item.fields == null ? "" : item.fields.summary.toLowerCase();
      const searchText = queryText.toLowerCase();

      return (
        text1.indexOf(searchText) > -1 ||
        text2.indexOf(searchText) > -1 ||
        text3.indexOf(searchText) > -1
      );
    },
    wrapAvatarUrl(url) {
      return jiraAPI.getAvatarWrapperUrl(url);
    },
    createPhaseJiraIssue() {
      this.createJiraIssuesLoading = true;
      jiraAPI
        .createJiraPhase({
          phaseId: this.phaseId,
        })
        .then((res) => {
          if (res.data.statusCode == 200) this.jiraIssues.unshift(res.data.self);
          this.createJiraIssuesLoading = false;
        })
        .catch((err) => {
          this.createJiraIssuesLoading = false;
          this.$handleError(err);
        });
    },
  },
  created() {
    this.getJiraIssues();
  },
};
</script>

<style scoped lang="scss">
.jira-project-key {
  color: $shades-black;
  font-size: 14px;
  font-weight: 600;
}
.jira-project-name {
  color: rgba($shades-black, 0.54);
  font-size: 14px;
  font-weight: 400;
  max-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>
