<template>
  <div
    class="alt-comp-wrapper project-selector"
    :class="{
      'details-hidden': hideDetails,
      'alt-comp-wrapper-small': small,
    }"
  >
    <v-autocomplete-alt
      auto-select-first
      :rules="required ? [allRules.required] : []"
      :label="
        hideLabel
          ? null
          : label == null || label == ''
          ? `<i class='fad fa-folder mr-1'></i> Project`
          : label
      "
      id="projectSelector"
      placeholder="Select a Project"
      :items="projects"
      :value="value"
      :search-input.sync="search"
      @input="handleSelectedProject"
      item-value="id"
      item-text="fullName"
      dense
      filled
      no-filter
      clearable
      :hide-details="hideDetails"
      :loading="isProjectLoading"
      :readonly="readonly"
      :menu-props="{
        zIndex: 90000,
        contentClass: 'user-selector-menu',
      }"
    >
      <template v-for="item in ['selection', 'item']" #[`${item}`]="data">
        <v-row
          :key="item.id + '_projectsSelector'"
          align-content="center"
          justify="start"
          no-gutters
          style="flex: none; max-width: calc(100% - 40px); width: 100%; flex-wrap: nowrap"
          class="py-0"
        >
          <v-col sm="auto" class="d-flex align-center mr-1">
            <div class="project-image-wrapper">
              <v-img
                v-if="data.item.imageUrl != null && data.item.imageUrl != ''"
                :src="data.item.imageUrl"
                height="24"
                width="40"
                position="center center"
                contain
              >
              </v-img>
              <v-img
                v-else
                src="/img/DNA_Symbol.png"
                height="24"
                width="40"
                position="center center"
                contain
              >
              </v-img>
            </div>
          </v-col>
          <v-col class="d-flex align-center col-auto">
            <div class="d-flex align-center font-weight-medium" style="font-size: 14px">
              {{ data.item.fullName }}
            </div>
          </v-col>
        </v-row>
      </template>
    </v-autocomplete-alt>
    <v-btn
      min-width="24px !important"
      width="24px !important"
      height="24px !important"
      color="secondary"
      outlined
      class="pa-0"
      small
      target="_blank"
      :href="`/projects/${this.value}`"
      :disabled="value == null"
      :loading="isProjectLoading"
    >
      <i class="far fa-external-link"></i>
    </v-btn>
  </div>
</template>

<script>
import projectService from "../../Projects/services/projects-service";

export default {
  props: {
    value: {
      type: Number,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    hideLabel: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {},
  data() {
    return {
      projectsTimerId: null,
      projects: [],
      search: "",
      isProjectLoading: false,
    };
  },
  methods: {
    handleSelectedProject(val) {
      if (val == null) {
        this.search = "";
      }
      this.$emit("input", val);
    },
    getProjects() {
      this.isProjectLoading = true;
      var toSend = { search: this.search };
      projectService
        .typeHead(this.$clean(toSend, true))
        .then((resp) => {
          this.projects = resp.data;
          this.$log("projects", resp.data);
          this.isProjectLoading = false;
        })
        .catch((err) => {
          this.isProjectLoading = false;
          this.$handleError(err);
        });
    },
    getProjectsDebounced() {
      if (this.projectsTimerId == null) {
        this.projectsTimerId = -1;
        this.getProjects();
        return;
      }
      // cancel pending call
      clearTimeout(this.projectsTimerId);
      // delay new call 400ms
      this.projectsTimerId = setTimeout(() => {
        this.getProjects();
      }, 500);
    },
  },
  created() {},
  watch: {
    search: {
      handler() {
        this.getProjectsDebounced();
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss">
.alt-comp-wrapper.project-selector {
  .v-autocomplete-alt {
    // min-width: 250px;
    width: calc(100% - 40px);
    flex: 1 1 auto;
    overflow: hidden;
  }
}

.project-image-wrapper {
  border: thin solid rgba($shades-black, 0.24);
  border-radius: 0.25rem;
}
</style>
