<template>
  <v-card
    v-if="value != null"
    elevation="2"
    class="contract-phase-record-card-no-border contract-phase-record-card d-flex align-center justify-space-between flex-row py-2 px-3"
    style="gap: 1rem"
    :style="{
      width: fullWidth ? '100%' : 'auto',
    }"
    rounded="lg"
  >
    <div class="d-flex flex-column align-start" style="gap: 0.5rem">
      <h4>
        <i class="fad fa-folder-open mr-1 secondary--text"></i>
        <a
          small
          text
          target="_blank"
          class="py-0 px-1 fs-13px secondary--text"
          max-height="20px"
          :href="`/projects/${value.project.id}`"
          >{{ value.project.fullName }}
        </a>
      </h4>
      <h4 class="d-flex align-start justify-start">
        <i class="fad fa-envelope mr-2 secondary--text" style="width: 16px; margin-top: 2px"></i>
        <span class="d-flex align-center justify-start flex-wrap">
          <code class="fs-11px blue-grey lighten-5 mono-font mr-1" style="flex: none">
            #{{ value.resourceShiftRequest.id }}
          </code>
          <span>{{ value.resourceShiftRequest.name }}</span>
          <i class="fad fa-circle fs-8px mx-2" style="line-height: 0"></i>
          <resource-shift-request-state
            small
            :state="value.resourceShiftRequest.state"
          ></resource-shift-request-state>
        </span>
      </h4>
      <h4 class="d-flex align-center">
        <i class="fad fa-calendar-lines mr-2 secondary--text" style="width: 16px"></i>
        <v-chip color="secondary" dark class="px-1" label x-small>
          <span class="fs-13px font-weight-medium">{{ value.startDate.substr(0, 7) }}</span>
        </v-chip>
      </h4>
    </div>
    <div class="d-flex flex-row align-center" style="gap: 1rem">
      <div class="d-flex flex-column align-end text-right" style="gap: 2px">
        <!-- <b class="blue-grey--text" style="white-space: nowrap">Source</b> -->
        <b class="opacity-87 fs-12px" style="white-space: nowrap">
          {{ getLaborTypeObj(value.laborType).desc }}
        </b>
        <user-all-selector
          :users="users"
          required
          small
          hideLabel
          hideDetails
          readonly
          hideViewBtn
          responsive
          hideBorder
          :isUsersLoading="isUsersLoading"
          style="white-space: nowrap"
          v-model="value.resourceShiftRequest.sourceUserId"
        ></user-all-selector>
      </div>
      <v-divider vertical class="mx-1"></v-divider>
      <div class="d-flex flex-column align-end pr-1" style="gap: 3px">
        <b class="blue-grey--text" style="white-space: nowrap">Shifted Hours</b>
        <span class="d-flex align-center" style="gap: 0.35rem">
          <span class="fs-12px font-weight-medium opacity-64">Hours:</span>
          <span
            style="line-height: 0"
            class="pink--text fs-18px font-weight-medium font-weight-medium mono-font"
            >-{{ value.shiftedHours }}
          </span>
        </span>
      </div>
      <v-divider vertical class="mx-1"></v-divider>
      <div class="d-flex flex-column align-start" style="gap: 2px; position: relative">
        <span class="d-flex align-center">
          <span class="blue-grey--text font-weight-bold" style="white-space: nowrap">
            Shift To<span class="opacity-72 ml-1">Date</span>
          </span>
        </span>
        <span>
          <span class="fs-13px font-weight-bold">{{ value.shiftedToDate.substr(0, 7) }}</span>
          <span
            v-if="shiftDiff != 0"
            style="white-space: nowrap; bottom: -16px; position: absolute; left: 0px"
            class="fs-11px d-inline-flex align-center font-weight-medium"
            :class="{
              'info--text': shiftDiff > 0,
              'pink--text': shiftDiff < 0,
              'val-zero': shiftDiff == 0,
            }"
            >{{ shiftDiff > 0 ? "+" : "" }}{{ shiftDiff }} {{ shiftDiff == 1 ? "month" : "months" }}
          </span>
        </span>
      </div>
    </div>
  </v-card>
</template>

<script>
import enums from "../../../plugins/enums";
import UserAllSelector from "../../Shared/components/UserAllSelector.vue";
import ResourceShiftRequestState from "../components/ResourceShiftRequestState.vue";

export default {
  name: "shift-request-record-full-card",
  components: { UserAllSelector, ResourceShiftRequestState },
  data() {
    return {
      enums,
    };
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
    noBorder: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    isUsersLoading: {
      type: Boolean,
      default: false,
    },
    users: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    shiftDiff() {
      if (this.value.shiftedToDate == null) return 0;
      return this.monthDiff(new Date(this.value.startDate), new Date(this.value.shiftedToDate));
    },
  },
  methods: {
    monthDiff(dt1, dt2) {
      var diff = (dt2.getTime() - dt1.getTime()) / 1000;
      diff /= 60 * 60 * 24 * 30;
      return Math.round(diff);
    },
    getLaborTypeObj(val) {
      return this.getEnumMember(enums.LABOR_TYPE, val);
    },
  },
};
</script>

<style lang="scss"></style>
