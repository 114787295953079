<template>
  <v-container fluid class="pa-3 pt-3">
    <div class="d-flex justify-space-between align-center mb-2">
      <h3 class="text--primary font-weight-black mr-3 d-inline-block">Buildings And Levels</h3>

      <v-tooltip top nudge-left="-4px">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            color="info"
            @click.stop="addEditBuildingOpen()"
            :disabled="!canEdit"
          >
            <i class="fal fa-plus mr-2"></i> New Building
          </v-btn>
        </template>
        <span>Add a new building inside this project</span>
      </v-tooltip>
    </div>

    <div style="border-radius: 0.5rem" class="pa-5 buildings-tree-wrapper">
      <div class="d-flex justify-center" v-if="isBuildingsLoading">
        <v-progress-circular
          color="primary"
          width="2"
          size="24"
          indeterminate
        ></v-progress-circular>
      </div>
      <v-treeview v-else dense open-on-click transition :items="tree">
        <template v-slot:prepend="{ item }">
          <i class="fad fa-building tree-building" v-if="item.type == 'Building'"></i>
          <i class="fad fa-layer-group tree-level" v-else></i>
        </template>
        <template v-slot:label="{ item }">
          <span class="tree-building" v-if="item.type == 'Building'"
            >{{ item.name }}
            <v-chip
              label
              v-if="item.area != null && item.area != 0"
              x-small
              color="secondary"
              class="ml-2 font-weight-bold px-1 justify-center d-inline-flex fs-11px"
              style="line-height: 0"
            >
              {{ item.area | numberWithCommas }} <span class="ml-1">SQFT</span>
            </v-chip>
          </span>
          <span class="tree-level" v-else>{{ item.name }}</span>
        </template>
        <template v-slot:append="{ item }">
          <v-tooltip top nudge-left="-4px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="item.type == 'Building'"
                v-bind="attrs"
                v-on="on"
                class="mr-2 fs-12px font-weight-medium"
                small
                color="green"
                dark
                @click.stop="addEditLevelOpen(item)"
                :disabled="!canEdit"
              >
                <i class="far fa-plus mr-2"></i> New Level
              </v-btn>
            </template>
            <span v-if="item.type == 'Building'">
              Add a new level inside building: {{ item.name }}
            </span>
          </v-tooltip>
          <v-btn
            icon
            color="secondary"
            class="mr-1"
            @click.stop="
              item.type == 'Building'
                ? addEditBuildingOpen(item)
                : addEditLevelOpen(item.building, item)
            "
            :disabled="!canEdit"
          >
            <i class="fal fa-pen"></i>
          </v-btn>
          <v-btn
            icon
            color="red"
            class="mr-1"
            @click.stop="item.type == 'Building' ? deleteBuilding(item.id) : deleteLevel(item)"
            :disabled="!canEdit"
          >
            <v-icon small>fal fa-trash-alt</v-icon>
          </v-btn>
        </template>
      </v-treeview>
    </div>
    <!-- Add Level Modal -->
    <v-dialog v-model="modals.addEditLevel.active" max-width="360px" persistent>
      <v-card>
        <v-card-title class="font-weight-bold" small>
          <template v-if="modals.addEditLevel.data.id">
            <i class="fad fa-pen-square mr-2 mt-1"></i>Edit ({{ selected.level.name }}) Level
          </template>
          <template v-else><i class="fad fa-plus-square mr-2 mt-1"></i>Add New Level</template>
        </v-card-title>
        <v-divider></v-divider>
        <v-form v-model="modals.addEditLevel.valid" ref="addEditLevelForm">
          <v-card-text class="pa-2">
            <v-container>
              <v-row class="my-0" dense>
                <v-col sm="12" class="mb-2">
                  <label class="mb-2 d-block" style="font-weight: 600 !important; font-size: 15px">
                    Building:
                  </label>
                  <h2>
                    <v-chip color="secondary" style="font-weight: 500 !important" label>
                      <i class="fad fa-building mr-2"></i>
                      {{ modals.addEditLevel.data.buildingName }}
                    </v-chip>
                  </h2>
                </v-col>
                <v-col sm="12">
                  <v-text-field-alt
                    :rules="[allRules.required, allRules.noWhiteSpaces]"
                    label="Name"
                    id="LevelName"
                    placeholder="Level Name"
                    v-model="modals.addEditLevel.data.name"
                  >
                  </v-text-field-alt>
                </v-col>
                <v-col sm="12">
                  <v-switch
                    color="info"
                    v-model="modals.addEditLevel.data.shortNameMatchesName"
                    @change="levelShortNameMatchesNameHandler"
                    class="mb-2 mt-0 pa-0"
                    hide-details
                  >
                    <template v-slot:label>
                      <label
                        class="v-switch-label fs-14px"
                        :class="
                          modals.addEditLevel.data.shortNameMatchesName
                            ? 'info--text'
                            : 'secondary--text'
                        "
                      >
                        Short Name Matches Name
                      </label>
                    </template>
                  </v-switch>
                </v-col>
                <v-col sm="12">
                  <v-text-field-alt
                    :rules="
                      modals.addEditLevel.data.shortNameMatchesName
                        ? [allRules.required, allRules.noWhiteSpaces]
                        : [allRules.lengthMax(10), allRules.required, allRules.noWhiteSpaces]
                    "
                    label="Short Name"
                    id="LevelShortName"
                    placeholder="Short Name"
                    :readonly="modals.addEditLevel.data.shortNameMatchesName"
                    v-model="modals.addEditLevel.data.shortName"
                  >
                  </v-text-field-alt>
                </v-col>
                <v-col sm="12">
                  <v-text-field-alt
                    :rules="[allRules.number]"
                    label="Area"
                    id="LevelArea"
                    placeholder="Area"
                    suffix="SQFT"
                    v-model.number="modals.addEditLevel.data.area"
                  >
                  </v-text-field-alt>
                </v-col>
                <v-col sm="12">
                  <v-textarea-alt
                    label="Description"
                    id="LevelDescription"
                    placeholder="Level Description"
                    hide-details
                    v-model="modals.addEditLevel.data.description"
                  >
                  </v-textarea-alt>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="addEditLevelDiscard()"
              ><i class="fal fa-chevron-left mr-2"></i>Discard</v-btn
            >
            <v-btn
              color="info"
              :loading="modals.addEditLevel.isLoading"
              :disabled="!modals.addEditLevel.valid || modals.addEditLevel.isLoading || !hasChanges"
              @click="addEditLevelConfirmed()"
            >
              <i class="fal fa-check mr-2"></i>
              {{ modals.addEditLevel.data.id ? "Update" : "Add" }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <!-- Add Or Edit Building Modal -->
    <v-dialog v-model="modals.addEditBuilding.active" max-width="360px" persistent>
      <v-card>
        <v-card-title class="font-weight-bold" small>
          <template v-if="modals.addEditBuilding.data.id">
            <i class="fad fa-pen-square mr-2 mt-1"></i>Edit ({{ selected.building.name }}) Building
          </template>
          <template v-else><i class="fad fa-plus-square mr-2 mt-1"></i>Add A New Building</template>
        </v-card-title>

        <v-divider></v-divider>
        <v-form v-model="modals.addEditBuilding.valid" ref="addEditBuildingForm">
          <v-card-text class="pa-2">
            <v-container>
              <v-row class="my-0" dense>
                <v-col sm="12">
                  <v-text-field-alt
                    :rules="[allRules.required, allRules.noWhiteSpaces]"
                    label="Name"
                    id="BuildingName"
                    placeholder="Building Name"
                    v-model="modals.addEditBuilding.data.name"
                  >
                  </v-text-field-alt>
                </v-col>
                <v-col sm="12">
                  <v-switch
                    color="info"
                    v-model="modals.addEditBuilding.data.shortNameMatchesName"
                    @change="buildingShortNameMatchesNameHandler"
                    class="mb-2 mt-0 pa-0"
                    hide-details
                  >
                    <template v-slot:label>
                      <label
                        class="v-switch-label fs-14px"
                        :class="
                          modals.addEditBuilding.data.shortNameMatchesName
                            ? 'info--text'
                            : 'secondary--text'
                        "
                      >
                        Short Name Matches Name
                      </label>
                    </template>
                  </v-switch>
                </v-col>
                <v-col sm="12">
                  <v-text-field-alt
                    :rules="
                      modals.addEditBuilding.data.shortNameMatchesName
                        ? [allRules.required, allRules.noWhiteSpaces]
                        : [allRules.lengthMax(10), allRules.required, allRules.noWhiteSpaces]
                    "
                    label="Short Name"
                    id="BuildingShortName"
                    placeholder="Short Name"
                    :readonly="modals.addEditBuilding.data.shortNameMatchesName"
                    v-model="modals.addEditBuilding.data.shortName"
                  >
                  </v-text-field-alt>
                </v-col>
                <v-col sm="12">
                  <v-text-field-alt
                    :rules="[allRules.number]"
                    label="Area"
                    id="BuildingArea"
                    placeholder="Area"
                    suffix="SQFT"
                    v-model.number="modals.addEditBuilding.data.area"
                  >
                  </v-text-field-alt>
                </v-col>
                <v-col sm="12">
                  <v-textarea-alt
                    label="Description"
                    id="BuildingDescription"
                    placeholder="Building Description"
                    hide-details
                    v-model="modals.addEditBuilding.data.description"
                  >
                  </v-textarea-alt>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="addEditBuildingDiscard()"
              ><i class="fal fa-chevron-left mr-2"></i>Discard</v-btn
            >
            <v-btn
              color="info"
              :loading="modals.addEditBuilding.isLoading"
              :disabled="
                !modals.addEditBuilding.valid || modals.addEditBuilding.isLoading || !hasChanges
              "
              @click="addOrUpdateBuildingConfirmed()"
            >
              <i class="fal fa-check mr-2"></i>
              {{ modals.addEditBuilding.data.id ? "Update" : "Add" }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import buildingsAPI from "../services/buildings-service";
import levelsAPI from "../services/levels-service";
import perms from "../../../plugins/permissions";

export default {
  name: "building-levels",
  data() {
    return {
      tree: [],
      isBuildingsLoading: false,
      hasChanges: false,
      selected: {
        building: null,
        level: null,
      },
      modals: {
        addEditLevel: {
          active: false,
          valid: false,
          isLoading: false,
          data: {
            name: null,
            buildingName: null,
            shortName: null,
            shortNameMatchesName: false,
            area: null,
            description: null,
          },
        },
        addEditBuilding: {
          active: false,
          valid: false,
          isLoading: false,
          data: {
            name: null,
            shortName: null,
            shortNameMatchesName: false,
            area: null,
            description: null,
          },
        },
      },
    };
  },
  watch: {
    "modals.addEditBuilding.data": {
      handler(newValue, oldval) {
        if (this.selected.building) {
          this.hasChanges = !this.isEqual(this.selected.building, newValue);
        }
      },
      deep: true,
    },
    "modals.addEditLevel.data": {
      handler(newValue, oldval) {
        if (this.selected.level) {
          this.hasChanges = !this.isEqual(this.selected.level, newValue);
        }
      },
      deep: true,
    },
    "modals.addEditBuilding.data.name": {
      handler() {
        if (
          this.modals.addEditBuilding.active &&
          this.modals.addEditBuilding.data.shortNameMatchesName
        ) {
          this.modals.addEditBuilding.data.shortName = this.modals.addEditBuilding.data.name;
        }
      },
      deep: true,
    },
    "modals.addEditLevel.data.name": {
      handler() {
        if (this.modals.addEditLevel.active && this.modals.addEditLevel.data.shortNameMatchesName) {
          this.modals.addEditLevel.data.shortName = this.modals.addEditLevel.data.name;
        }
      },
      deep: true,
    },
  },
  props: {
    projectId: {
      type: Number,
      default: null,
    },
    overviewSection: {
      type: Object,
      default: null,
    },
  },
  created() {
    this.getBuildingsAndLevels();
  },
  computed: {
    canEdit() {
      return this.$has(perms.Projects.Update);
    },
  },
  methods: {
    levelShortNameMatchesNameHandler() {
      if (this.modals.addEditLevel.active && this.modals.addEditLevel.data.shortNameMatchesName) {
        this.modals.addEditLevel.data.shortName = this.modals.addEditLevel.data.name;
      } else if (
        this.modals.addEditLevel.active &&
        !this.modals.addEditLevel.data.shortNameMatchesName
      ) {
        this.modals.addEditLevel.data.shortName = null;
      }
    },
    buildingShortNameMatchesNameHandler() {
      if (
        this.modals.addEditBuilding.active &&
        this.modals.addEditBuilding.data.shortNameMatchesName
      ) {
        this.modals.addEditBuilding.data.shortName = this.modals.addEditBuilding.data.name;
      } else if (
        this.modals.addEditBuilding.active &&
        !this.modals.addEditBuilding.data.shortNameMatchesName
      ) {
        this.modals.addEditBuilding.data.shortName = null;
      }
    },
    getBuildingsAndLevels() {
      this.isBuildingsLoading = true;
      buildingsAPI
        .getProjectBuildings(this.projectId)
        .then((resp) => {
          this.$log("getBuildingsAndLevels", resp.data.items);
          this.buildings = this.treePrepare(resp.data.items);
        })
        .finally(() => {
          this.isBuildingsLoading = false;
        });
    },
    treePrepare(data) {
      this.tree = data.map((elm) => this.getTreeObject(elm));
    },
    getTreeObject(elm) {
      return {
        id: elm.id,
        name: elm.name,
        shortName: elm.shortName,
        shortNameMatchesName: elm.shortNameMatchesName,
        area: elm.area,
        description: elm.description,
        type: "Building",
        children: [...elm.levels],
      };
    },
    addEditBuildingOpen(data) {
      this.$log("addEditBuildingOpen", data);
      if (data == null) {
        data = {
          name: null,
          shortName: null,
          shortNameMatchesName: false,
          area: null,
          description: null,
        };
      }
      this.selected.building = this.cloneDeep(data);
      this.modals.addEditBuilding = {
        active: true,
        valid: false,
        isLoading: false,
        data: this.cloneDeep(data),
      };
      setTimeout(() => {
        this.$refs.addEditBuildingForm.resetValidation();
      });
    },
    addEditBuildingDiscard() {
      this.modals.addEditBuilding = {
        active: false,
        valid: false,
        isLoading: false,
        data: {
          name: null,
          shortName: null,
          shortNameMatchesName: false,
          area: null,
          description: null,
        },
      };
      this.selected.building = null;
    },
    addOrUpdateBuildingConfirmed() {
      var buildingData = {
        projectId: this.projectId,
        ...this.modals.addEditBuilding.data,
      };
      this.$log("buildingData", buildingData);
      this.modals.addEditBuilding.isLoading = true;
      buildingsAPI
        .update(buildingData)
        .then((resp) => {
          this.$log("buildingsAP.update", resp.data);
          this.modals.addEditBuilding.isLoading = false;
          let message = "Building added successfully!";
          if (this.modals.addEditBuilding.data.id) {
            const buildingIndex = this.tree.findIndex(
              (elm) => elm.id == this.modals.addEditBuilding.data.id
            );
            this.tree.splice(buildingIndex, 1, this.getTreeObject(resp.data));
            message = `Building (${this.modals.addEditBuilding.data.name}) updated successfully!`;
          } else {
            this.tree.push(this.getTreeObject(resp.data));
          }
          this.$dialog.notify.success(message, {
            position: "top-right",
            timeout: 3000,
          });
          this.addEditBuildingDiscard();
          this.overviewSection?.refresh();
        })
        .catch((err) => {
          this.modals.addEditBuilding.isLoading = false;
          this.$handleError(err);
        });
    },
    deleteBuilding(id) {
      this.$dialog
        .warning({
          text: `Are you sure you want to delete this Building?`,
          title: `Delete Building?`,
          color: "error",
          persistent: true,
          actions: {
            false: {
              text: "Cancel",
            },
            true: {
              text: "Confirm",
              color: "error",
              handle: () => {
                return buildingsAPI
                  .delete(id)
                  .then(() => {
                    const index = this.tree.findIndex((e) => e.id == id);
                    if (index || index == 0) {
                      this.tree.splice(index, 1);
                    }

                    this.$dialog.notify.success("Building deleted successfully!", {
                      position: "top-right",
                      timeout: 3000,
                    });
                    this.overviewSection?.refresh();
                  })
                  .catch((rej) => {
                    let message = "Error deleting this building!";
                    if (rej.response.status == 400)
                      message = "Please delete all levels under this building first!";

                    this.$dialog.notify.error(message, {
                      position: "top-right",
                      timeout: 3000,
                    });
                  });
              },
            },
          },
        })
        .then((res) => {});
    },
    deleteLevel(item) {
      this.$dialog.warning({
        text: `Are you sure you want to delete this level?`,
        title: `Delete Level?`,
        color: "error",
        persistent: true,
        actions: {
          false: {
            text: "Cancel",
          },
          true: {
            text: "Confirm",
            color: "error",
            handle: () => {
              return levelsAPI
                .delete(item.id)
                .then(() => {
                  const index = this.tree.findIndex((e) => e.id == item.buildingId);
                  if (index || index == 0) {
                    const levelIndex = this.tree[index].children.findIndex(
                      (elm) => elm.id == item.id
                    );
                    this.$log(levelIndex);
                    if (levelIndex || levelIndex == 0)
                      this.tree[index].children.splice(levelIndex, 1);
                  }

                  this.$dialog.notify.success("level deleted successfully", {
                    position: "top-right",
                    timeout: 3000,
                  });
                })
                .catch((rej) => {
                  let message = "an error occurred during deleting this level";
                  if (rej.response.status == 400) message = "this level is used by spaces";

                  this.$dialog.notify.error(message, {
                    position: "top-right",
                    timeout: 3000,
                  });
                });
            },
          },
        },
      });
    },
    addEditLevelOpen(building, level = { name: null }) {
      if (!building) {
        building = this.tree.find((elm) => elm.id == level.buildingId);
      }
      this.modals.addEditLevel = {
        active: true,
        valid: false,
        isLoading: false,
        data: {
          ...level,
          buildingName: building.name,
          buildingId: building.id,
        },
      };
      this.selected.level = { ...this.modals.addEditLevel.data };

      setTimeout(() => {
        this.$refs.addEditLevelForm.resetValidation();
      });
    },
    addEditLevelDiscard() {
      this.modals.addEditLevel = {
        active: false,
        valid: false,
        isLoading: false,
        data: {
          name: null,
          buildingName: null,
          shortName: null,
          area: null,
          description: null,
        },
      };
      this.selected.level = null;
    },
    addEditLevelConfirmed() {
      var levelData = {
        projectId: this.projectId,
        buildingId: this.modals.addEditLevel.data.buildingId,
        name: this.modals.addEditLevel.data.name,
        shortName: this.modals.addEditLevel.data.shortName,
        shortNameMatchesName: this.modals.addEditLevel.data.shortNameMatchesName,
        id: this.modals.addEditLevel.data.id,
        area: this.modals.addEditLevel.data.area ? this.modals.addEditLevel.data.area : 0,
        description: this.modals.addEditLevel.data.description,
      };
      const buildingIndex = this.tree.findIndex((elm) => elm.id == levelData.buildingId);

      this.modals.addEditLevel.isLoading = true;
      levelsAPI
        .update(levelData)
        .then((resp) => {
          this.modals.addEditLevel.isLoading = false;
          let message = "Level added successfully!";
          if (levelData.id) {
            message = `Level (${levelData.name}) updated successfully!`;
            const index = this.tree[buildingIndex].children.findIndex(
              (elm) => elm.id == levelData.id
            );
            if (index || index == 0) this.tree[buildingIndex].children.splice(index, 1, resp.data);
          } else {
            this.tree[buildingIndex].children.push(resp.data);
          }
          this.$dialog.notify.success(message, {
            position: "top-right",
            timeout: 3000,
          });
          this.addEditLevelDiscard();
        })
        .catch((err) => {
          this.modals.addEditLevel.isLoading = false;
          this.$handleError(err);
        });
    },
    getBuildingItem(id, item = "id") {
      return this.tree.find((elm) => elm.id == id)[item];
    },
  },
};
</script>
