var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"alt-comp-wrapper",class:{
    'details-hidden': _vm.hideDetails,
    'hide-border': _vm.hideBorder,
    'alt-comp-wrapper-small': _vm.small,
    'alt-comp-wrapper-responsive': _vm.responsive,
  }},[_c('v-autocomplete-alt',_vm._b({staticClass:"project-user",attrs:{"auto-select-first":"","rules":_vm.required ? [_vm.allRules.required] : [],"label":_vm.hideLabel ? null : _vm.$attrs.label || 'User',"id":"projectUser","placeholder":"Select a User","value":_vm.value,"items":_vm.activeUsers,"dense":"","filter":_vm.usersFilter,"filled":"","hide-details":_vm.hideDetails,"item-text":"firstName","item-value":"id","item-disabled":_vm.isUserDisabled,"loading":_vm.isUsersLoading,"readonly":_vm.readonly,"clearable":"","menu-props":{
      zIndex: 90000,
      contentClass: 'user-selector-menu',
    }},on:{"change":_vm.onUserChange,"input":_vm.emitUser},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('user-avatar',{key:'selection' + data.index + 'user',attrs:{"user":data.item,"disableOpenLink":"","hide-roles":""}})]}},{key:"item",fn:function(data){return [_c('user-avatar',{key:'item' + data.index + 'user',attrs:{"user":data.item,"disableOpenLink":"","hide-roles":""}})]}},{key:"append-item",fn:function(){return [_c('div',{directives:[{name:"intersect",rawName:"v-intersect",value:(_vm.loadMore),expression:"loadMore"}]})]},proxy:true}])},'v-autocomplete-alt',_vm.$attrs,false)),(_vm.$has(_vm.perms.DNAUsers.View) && !_vm.hideViewBtn)?_c('v-btn',{staticClass:"pa-0",attrs:{"min-width":_vm.small ? '24px !important' : '28px !important',"width":_vm.small ? '24px !important' : '28px !important',"height":_vm.small ? '24px !important' : '28px !important',"color":"secondary","outlined":"","small":"","disabled":_vm.value == null},on:{"click":_vm.openUserInNewTab}},[_c('i',{staticClass:"fad fa-eye fs-14px"})]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }