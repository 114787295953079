<template>
  <div class="w-100">
    <img
      src="/img/art/tumble-weed.svg"
      class="mt-3"
      style="position: fixed; left: -100vw; opacity: 0"
    />
    <v-scroll-y-transition mode="out-in">
      <div
        v-if="isDrawingDocumentsLoading"
        :key="'documents-loading'"
        class="documents-loading d-flex flex-column align-center justify-center"
        style="height: 200px"
      >
        <v-progress-circular indeterminate color="info" :width="2"></v-progress-circular>
        <h4 class="mt-3 text--disabled">Loading Drawing Documents ...</h4>
      </div>
      <div class="w-100" :key="'documents-table'" v-else-if="drawingDocuments.length > 0">
        <v-row class="mb-0">
          <v-col cols="12" md="6" class="d-flex align-center justify-start" style="gap: 1.5rem">
            <h4 class="d-flex align-center justify-start">
              Drawing Documents
              <v-chip class="ml-2 font-weight-bold" small label style="height: 24px">{{
                drawingDocuments.length
              }}</v-chip>
            </h4>
          </v-col>
          <v-col cols="12" md="6" class="d-flex align-center justify-end" style="gap: 0.5rem">
            <v-sheet
              key="sheets-viewMode"
              elevation="2"
              height="32"
              rounded
              style="border: 1px solid orange"
              class="d-inline-flex align-center justify-center pl-3 pr-1"
            >
              <h4 class="ma-0 text--secondary fs-14px" style="flex: none">View as:</h4>
              <v-btn-toggle
                class="ml-3"
                v-model="viewMode"
                mandatory
                color="orange darken-1"
                dense
                group
                style="height: 32px"
              >
                <v-btn
                  :value="'list'"
                  color="white"
                  class="mx-0 mr-1"
                  style="
                    border-radius: 4px;
                    padding-left: 0.75rem !important;
                    padding-right: 0.75rem !important;
                  "
                  :disabled="isBuildingsLoading"
                >
                  <i class="fad fa-list mr-2"></i>
                  <span style="text-transform: none" class="font-weight-medium">List</span>
                </v-btn>
                <v-btn
                  :value="'association'"
                  color="white"
                  class="mx-0"
                  style="
                    border-radius: 4px;
                    padding-left: 0.75rem !important;
                    padding-right: 0.75rem !important;
                  "
                  :disabled="isBuildingsLoading"
                >
                  <i class="fad fa-folder-tree mr-2"></i>
                  <span style="text-transform: none" class="font-weight-medium"
                    >Association Tree</span
                  >
                </v-btn>
              </v-btn-toggle>
            </v-sheet>
          </v-col>
        </v-row>
        <v-scroll-y-transition mode="out-in">
          <div key="association" v-if="viewMode == 'association'">
            <v-treeview
              class="docs-tree"
              dense
              open-on-click
              transition
              :items="drawingDocumentsTree"
            >
              <template v-slot:prepend="{ item }">
                <i class="fad fa-building tree-building" v-if="item.type == 'Building'"></i>
                <i class="fad fa-layer-group tree-level" v-else-if="item.type == 'Level'"></i>
                <i class="fad fa-draw-polygon tree-space" v-else-if="item.type == 'Space'"></i>
                <i class="fad fa-folder tree-folder" v-else-if="item.type == 'Folder'"></i>
                <i
                  class="fad fa-file-invoice tree-document secondary--text"
                  v-else-if="item.type == 'Document'"
                ></i>
              </template>
              <template v-slot:label="{ item }">
                <span class="tree-building" v-if="item.type == 'Building'">{{ item.name }}</span>
                <span class="tree-level" v-else-if="item.type == 'Level'">{{ item.name }}</span>
                <span class="tree-space" v-else-if="item.type == 'Space'">{{ item.name }}</span>
                <span class="tree-folder" v-else-if="item.type == 'Folder'">{{ item.name }}</span>
                <div
                  class="tree-document secondary--text"
                  v-else-if="item.type == 'Document'"
                  @click.stop="openDocumentPanel(item.data.id)"
                >
                  {{ item.name }}
                </div>
                <span class="ml-4 fs-12px font-weight-bold" v-if="item.type != 'Document'">
                  {{ item.count }} {{ item.count == 1 ? "Doc" : "Docs" }}</span
                >
              </template>
              <!-- <template v-slot:append="{ item }"> </template> -->
            </v-treeview>
          </div>
          <div key="list" v-else-if="viewMode == 'list'">
            <v-row class="mb-0">
              <v-col cols="12" md="3" class="d-flex align-center justify-start" style="gap: 0.5rem">
                <v-scroll-y-transition mode="out-in">
                  <div
                    style="height: 32px; gap: 0.5rem"
                    class="d-flex align-center justify-start"
                    key="sheets-bulk-actions"
                    v-if="allowSelect && viewMode == 'list'"
                  >
                    <v-btn
                      class="fs-12px"
                      color="error"
                      :disabled="selectedDrawingDocuments.length < 1"
                      @click="bulkDelete"
                    >
                      <i class="far fa-trash-alt mr-2"></i> Delete ({{
                        selectedDrawingDocuments.length
                      }})
                    </v-btn>
                    <v-btn class="fs-12px" color="secondary" text @click="clearSelection">
                      <i class="far fa-square-xmark fs-15px mr-2"></i> Cancel
                    </v-btn>
                  </div>
                  <div
                    v-else-if="!allowSelect && viewMode == 'list'"
                    style="height: 32px; gap: 0.5rem"
                    class="d-flex align-center justify-start"
                    key="bulk_update_empty"
                  >
                    <v-btn
                      class="fs-12px"
                      color="info"
                      text
                      :disabled="drawingDocuments.length <= 1"
                      @click.stop="allowSelect = true"
                    >
                      <i class="far fa-layer-group mr-2"></i> Bulk Actions
                    </v-btn>
                  </div>
                </v-scroll-y-transition>
              </v-col>
              <v-col cols="12" md="9" class="d-flex align-center justify-end" style="gap: 0.5rem">
                <v-scroll-y-transition mode="out-in">
                  <div
                    v-if="!allowSelect"
                    key="filters"
                    class="d-flex align-center justify-center"
                    style="gap: 0.5rem"
                  >
                    <filter-menu
                      id="AssociatedTypeControl"
                      title="Associated With"
                      v-model="options.associatedTypes"
                      :items="associatedTypesList"
                      itemText="text"
                      itemValue="value"
                      multiple
                    >
                      <template #[`item`]="{ item }">
                        <v-list-item-content>
                          <div class="d-flex align-center" style="flex: none">
                            <i
                              class="fad mr-3"
                              style="width: 14px; line-height: 0; text-align: center"
                              :class="item.icon"
                            ></i>
                            <span class="font-weight-medium">{{ item.text }}</span>
                          </div>
                        </v-list-item-content>
                      </template>
                    </filter-menu>
                    <filter-menu
                      id="docTypeControl"
                      title="Document Type"
                      v-model="options.docTypes"
                      :items="docTypesList"
                      itemText="text"
                      itemValue="value"
                      multiple
                    >
                      <template #[`item`]="{ item }">
                        <v-list-item-content>
                          <div style="flex: none; width: fit-content">
                            <document-type class="d-inline-flex" :type="item.value"></document-type>
                          </div>
                        </v-list-item-content>
                      </template>
                    </filter-menu>
                    <filter-menu
                      id="docStateControl"
                      title="Document State"
                      v-model="options.docStates"
                      :items="statesList"
                      itemText="text"
                      itemValue="value"
                      multiple
                    >
                      <template #[`item`]="{ item }">
                        <v-list-item-content>
                          <div style="flex: none; width: fit-content">
                            <status-item
                              small
                              :statusList="statusList"
                              :status="item.value"
                            ></status-item>
                          </div>
                        </v-list-item-content>
                      </template>
                    </filter-menu>
                    <v-text-field
                      v-model="options.search"
                      label="Search"
                      ref="mainSearch"
                      class="table-search-field d-flex"
                      @focus="mainSearchInFocus = true"
                      @blur="mainSearchInFocus = false"
                      :style="{
                        'max-width':
                          mainSearchInFocus || (options.search != '' && options.search != null)
                            ? '200px' //200px
                            : '110px', //50px
                      }"
                      dense
                      solo
                      hide-details
                      clearable
                      prepend-inner-icon="far fa-search"
                    >
                    </v-text-field>
                    <refresh
                      :loading="isDrawingDocumentsLoading"
                      @refresh="loadDrawingDocuments()"
                    ></refresh>
                  </div>
                </v-scroll-y-transition>
              </v-col>
            </v-row>
            <v-data-table
              dense
              selectable-key
              :show-select="allowSelect"
              v-model="selectedDrawingDocuments"
              :headers="headers"
              :options.sync="options"
              :items="drawingDocumentsFilterd"
              :item-class="rowClass"
              class="elevation-2 documents-table"
              hide-default-footer
              @click:row="rowClicked"
            >
              <template v-slot:progress>
                <v-progress-linear color="blue" absolute indeterminate></v-progress-linear>
              </template>
              <template v-slot:[`header.jiraTicket`]="{ header }">
                <i :class="header.icon"></i>
                {{ header.text.toUpperCase() }}
              </template>
              <template v-slot:[`header.actions`]>
                <i class="fad fa-cog pl-2"></i>
              </template>
              <template v-slot:[`item.type`]="{ item }">
                <document-type :type="item.type"></document-type>
              </template>
              <template v-slot:[`item.name`]="{ item }">
                <div class="document-name">
                  {{ item.name }}
                </div>
              </template>
              <template v-slot:[`item.number`]="{ item }">
                <div class="document-sheet-number font-weight-bold mono-font">
                  {{ item.number }}
                </div>
              </template>
              <template v-slot:[`item.state`]="{ item }">
                <status-item small :statusList="statusList" :status="item.state"></status-item>
              </template>
              <template v-slot:[`item.assignee`]="{ item }">
                <user-avatar :user="item.assignee" icon></user-avatar>
              </template>
              <template v-slot:[`item.views`]="{ item }">
                <count-tag
                  icon="fa-layer-group"
                  :count="item.views ? item.views.length : 0"
                ></count-tag>
              </template>
              <template v-slot:[`item.jiraTicket`]="{ item }">
                <div class="document-jira-ticket">
                  <a
                    v-if="item.jiraTicket != null && item.jiraTicket != ''"
                    target="_blank"
                    class="jira-external-link"
                    @click.stop
                    :href="'https://dangeloconsultants.atlassian.net/browse/' + item.jiraTicket"
                  >
                    <span class="icon-wrpr mr-1">
                      <i class="fab fa-jira org-icon"></i>
                      <i class="far fa-external-link alt-icon"></i>
                    </span>
                    <span>{{ item.jiraTicket }}</span>
                  </a>
                  <span v-else class="text--disabled">
                    <i class="fad fa-unlink"></i>
                  </span>
                </div>
              </template>
              <template v-slot:[`item.createDate`]="{ item }">
                <dater :date="item.createDate" date-only></dater>
              </template>

              <template v-slot:[`item.updateDate`]="{ item }">
                <dater :date="item.updateDate" date-only></dater>
              </template>

              <template v-slot:[`item.createdBy`]="{ item }">
                <user-avatar :user="item.createdBy" icon></user-avatar>
              </template>

              <template v-slot:[`item.updatedBy`]="{ item }">
                <user-avatar :user="item.updatedBy" icon></user-avatar>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-menu dense offset-x right :disabled="allowSelect">
                  <template v-slot:activator="{ attrs, on }">
                    <v-btn icon small elevation="0" v-bind="attrs" v-on="on">
                      <i class="far fa-ellipsis-v" style="font-size: 16px"></i>
                    </v-btn>
                  </template>
                  <v-list class="more-options-menu">
                    <v-list-item :disabled="!canEdit" @click="editDocument(item)">
                      <v-list-item-icon class="mr-2 justify-center">
                        <v-icon small>fal fa-pen</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>Update</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item :disabled="!canCreate" @click="cloneDocument(item)">
                      <v-list-item-icon class="mr-2 justify-center">
                        <v-icon small>fal fa-copy</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>Clone</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>

                    <v-divider class="my-1"></v-divider>

                    <v-list-item :disabled="!canDelete" @click="deleteDocument(item)">
                      <v-list-item-icon class="mr-2 justify-center">
                        <v-icon small>fal fa-trash-alt red--text</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title class="red--text">Delete</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
              <template v-slot:no-data>
                <img width="300" src="/img/art/fogg-206.png" class="mt-3" />
                <p class="font-weight-bold mb-5">No Documents Available!</p>
              </template>
            </v-data-table>
          </div>
        </v-scroll-y-transition>
      </div>
      <div class="no-docs-available" :key="'no-docs-available'" v-else>
        <img src="/img/art/tumble-weed.svg" class="mt-3" />
        <h3 class="font-weight-medium mb-5 text--disabled">
          No documents here, only a green lizard!
        </h3>
      </div>
    </v-scroll-y-transition>
    <edit-drawing-document
      :project-id="projectId"
      @save="onDrawingDocumentSaved"
      @delete="onDrawingDocumentDeleted"
      @close="onDrawingDocumentClosed"
      ref="editDrawingDocument"
    ></edit-drawing-document>

    <clone-drawing-document ref="cloneDrawingDocument" @save="onCloneDrawingDocument">
    </clone-drawing-document>
  </div>
</template>

<script>
import enums from "../../../plugins/enums";
import DocumentType from "./DocumentType.vue";
import EditDrawingDocument from "./EditDrawingDocument.vue";
import ddmAPI from "../services/drawing-documents-service";
import projectsAPI from "../../Projects/services/projects-service";
import perms from "../../../plugins/permissions";
import drawingDocumentHeader from "../config/tables/drawingDocument.header";
import FilterMenu from "../../Shared/components/FilterMenu.vue";
import Refresh from "../../Shared/components/Refresh.vue";
import CloneDrawingDocument from "./CloneDrawingDocument.vue";

export default {
  name: "drawing-documents-list-view",
  data() {
    return {
      viewMode: "list",
      statusList: enums.DOCUMENT_STATE,
      statesList: enums.DOCUMENT_STATE_LIST,
      docTypesList: enums.DOCUMENT_TYPES_LIST,
      associatedTypesList: enums.ASSOCIATE_TYPE_LIST,
      drawingDocuments: [],
      drawingDocumentsTree: [],
      buildings: [],
      allowSelect: false,
      selectedDrawingDocuments: [],
      isDrawingDocumentsLoading: true,
      mainSearchInFocus: false,
      options: {
        page: 1,
        search: null,
        itemsPerPage: -1,
        associatedTypes: [],
        docTypes: [],
        docStates: [],
        sortBy: ["createDate"],
        sortDesc: [true],
      },
      selectedDoc: {},
    };
  },
  computed: {
    headers() {
      if (this.allowSelect) return drawingDocumentHeader.filter((h) => h.value != "actions");
      else return drawingDocumentHeader;
    },
    canCreate() {
      return this.$has(perms.DrawingDocuments.Create);
    },
    canEdit() {
      return this.$has(perms.DrawingDocuments.Update);
    },
    canDelete() {
      return this.$has(perms.DrawingDocuments.Delete);
    },
    drawingDocumentsFilterd() {
      var filtered = this.drawingDocuments
        .filter(this.searchFilterDoc)
        .filter(this.associationFilterDoc)
        .filter(this.typeFilterDoc)
        .filter(this.stateFilterDoc);

      this.$log(">>>> drawingDocumentsFilterd", filtered);
      return filtered;
    },
  },
  props: {
    projectId: {
      type: Number,
      default: null,
    },
    spaces: {
      type: Array,
      default: () => [],
    },
  },
  mounted() {
    this.loadDrawingDocuments();
    this.loadBuildingsLevelsSpaces();
  },
  methods: {
    getDocType(enumValue) {
      for (var val in enums.DOCUMENT_TYPES) {
        if (enums.DOCUMENT_TYPES[val].value == enumValue) return enums.DOCUMENT_TYPES[val];
      }
      return null;
    },
    associationFilterDoc(doc) {
      if (this.options.associatedTypes.length == 0) return true;
      return this.options.associatedTypes.includes(doc.associateType);
    },
    typeFilterDoc(doc) {
      if (this.options.docTypes.length == 0) return true;
      return this.options.docTypes.includes(doc.type);
    },
    stateFilterDoc(doc) {
      if (this.options.docStates.length == 0) return true;
      return this.options.docStates.includes(doc.state);
    },
    searchFilterDoc(doc) {
      if (this.options.search == null || this.options.search == "") return true;
      var term = this.options.search.toLowerCase();
      return (
        doc.name.toLowerCase().indexOf(term) > -1 ||
        doc.number.toLowerCase().indexOf(term) > -1 ||
        this.getDocType(doc.type).text.toLowerCase().indexOf(term) > -1 ||
        this.getDocType(doc.type).desc.toLowerCase().indexOf(term) > -1 ||
        (doc.associateType == enums.ASSOCIATE_TYPE.Space.value &&
          doc.space &&
          doc.space.name.toLowerCase().indexOf(term) > -1) ||
        (doc.associateType == enums.ASSOCIATE_TYPE.Space.value &&
          doc.space &&
          doc.space.number.toLowerCase().indexOf(term) > -1) ||
        (doc.associateType == enums.ASSOCIATE_TYPE.Level.value &&
          doc.level &&
          doc.level.name.toLowerCase().indexOf(term) > -1) ||
        (doc.associateType == enums.ASSOCIATE_TYPE.Building.value &&
          doc.building &&
          doc.building.name.toLowerCase().indexOf(term) > -1) ||
        (doc.associateType == enums.ASSOCIATE_TYPE.Building.value &&
          doc.building &&
          doc.building.shortName.toLowerCase().indexOf(term) > -1)
      );
    },
    loadDrawingDocuments() {
      this.isDrawingDocumentsLoading = true;
      this.$backToTop();
      ddmAPI
        .getProjectDocuments(this.projectId)
        .then((resp) => {
          this.isDrawingDocumentsLoading = false;
          this.drawingDocuments = resp.data;
          this.createDrawingDocumentsTree();
        })
        .catch((err) => {
          this.isDrawingDocumentsLoading = false;
          this.$handleError(err);
        });
    },
    loadBuildingsLevelsSpaces() {
      this.isBuildingsLoading = true;
      projectsAPI
        .getBuildingsLevelsSpaces(this.projectId)
        .then((resp) => {
          this.isBuildingsLoading = false;
          this.buildings = resp.data.items;
          this.createDrawingDocumentsTree();
        })
        .catch((err) => {
          this.isBuildingsLoading = false;
          this.$handleError(err);
        });
    },
    createDrawingDocumentsTree() {
      if (this.isDrawingDocumentsLoading || this.isBuildingsLoading) return;
      var tree = [];

      var docs = this.getNotAssociatedDocuments();
      var docsList = docs.map((doc) => {
        return {
          id: "doc_" + doc.id,
          name: doc.name,
          data: doc,
          type: "Document",
        };
      });
      if (docs.length > 0) {
        tree.push({
          id: "folder_not_associated",
          name: "Not Associated Drawing Documents",
          type: "Folder",
          count: docs.length,
          children: [...docsList],
        });
      }
      if (this.drawingDocuments.length >= 0) {
        tree.push(...this.buildings.map((building) => this.mapBuilding(building)));
      }
      this.drawingDocumentsTree = tree;
    },
    mapBuilding(building) {
      var docs = this.getBuildingDocuments(building.id);
      var docsList = docs.map((doc) => {
        return {
          id: "doc_" + doc.id,
          name: doc.name,
          data: doc,
          type: "Document",
        };
      });
      var docsFolder = null;
      if (docs.length > 0) {
        docsFolder = {
          id: "folder_" + building.id,
          name: "Drawing Documents",
          type: "Folder",
          count: docs.length,
          children: [...docsList],
        };
      }
      var buildingObj = {
        id: building.id,
        name: building.name,
        shortName: building.shortName,
        area: building.area,
        description: building.description,
        type: "Building",
        count: docs.length,
        children:
          docsFolder != null
            ? [docsFolder, ...building.levels.map((level) => this.mapLevel(level))]
            : building.levels.map((level) => this.mapLevel(level)),
      };

      var totalDocsCount = buildingObj.children.reduce((total, level) => {
        return total + level.count;
      }, 0);

      buildingObj.count = totalDocsCount;
      return buildingObj;
    },
    mapLevel(level) {
      var docs = this.getLevelDocuments(level.id);
      var docsList = docs.map((doc) => {
        return {
          id: "doc_" + doc.id,
          name: doc.name,
          data: doc,
          type: "Document",
        };
      });
      var docsFolder = null;
      if (docs.length > 0) {
        docsFolder = {
          id: "folder_" + level.id,
          name: "Drawing Documents",
          type: "Folder",
          count: docs.length,
          children: [...docsList],
        };
      }
      var levelObj = {
        id: level.id,
        name: level.name,
        type: "Level",
        count: docs.length,
        children:
          docsFolder != null
            ? [docsFolder, ...level.spaces.map((space) => this.mapSpace(space))]
            : level.spaces.map((space) => this.mapSpace(space)),
      };

      var totalDocsCount = levelObj.children.reduce((total, space) => {
        return total + space.count;
      }, 0);

      levelObj.count = totalDocsCount;
      return levelObj;
    },
    mapSpace(space) {
      var docs = this.getSpaceDocuments(space.id);
      var docsList = docs.map((doc) => {
        return {
          id: "doc_" + doc.id,
          name: doc.name,
          data: doc,
          type: "Document",
        };
      });
      return {
        id: space.id,
        name: space.name,
        type: "Space",
        count: docs.length,
        children: [...docsList],
      };
    },
    getBuildingDocuments(buildingId) {
      return this.drawingDocuments.filter(
        (doc) =>
          doc.associateType == enums.ASSOCIATE_TYPE.Building.value && doc.buildingId == buildingId
      );
    },
    getLevelDocuments(levelId) {
      return this.drawingDocuments.filter(
        (doc) => doc.associateType == enums.ASSOCIATE_TYPE.Level.value && doc.levelId == levelId
      );
    },
    getSpaceDocuments(spaceId) {
      return this.drawingDocuments.filter(
        (doc) => doc.associateType == enums.ASSOCIATE_TYPE.Space.value && doc.spaceId == spaceId
      );
    },
    getNotAssociatedDocuments() {
      return this.drawingDocuments.filter(
        (doc) =>
          doc.associateType == enums.ASSOCIATE_TYPE.NotAssociated.value || doc.associateType == null
      );
    },
    deleteDocument(doc) {
      this.$dialog
        .warning({
          text: `Are you sure you want to delete drawing document: <b>${doc.name}</b>?`,
          title: `Delete Drawing Document?`,
          color: "error",
          persistent: true,
          actions: {
            false: {
              text: "Cancel",
            },
            true: {
              text: "Confirm",
              color: "error",
              handle: () => {
                return ddmAPI
                  .deleteDocument(doc.id)
                  .then((resp) => {
                    this.onDrawingDocumentDeleted(doc.id);
                    this.$dialog.notify.success("Drawing Document deleted successfully", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  })
                  .catch((resp) => {
                    this.$dialog.notify.error(
                      "an error occurred during deleting the drawing document",
                      {
                        position: "top-right",
                        timeout: 3000,
                      }
                    );
                  });
              },
            },
          },
        })
        .then((res) => {});
    },
    onDrawingDocumentDeleted(id) {
      const index = this.drawingDocuments.indexOf(
        this.drawingDocuments.filter((e) => e.id == id)[0]
      );
      if (index > -1) {
        this.drawingDocuments.splice(index, 1);
      }
    },
    onDrawingDocumentClosed() {},
    onDrawingDocumentSaved(drawingDocumentData, isNewDrawingDocument) {
      this.$log("onDrawingDocumentSaved", drawingDocumentData, isNewDrawingDocument);
      if (isNewDrawingDocument) {
        this.addToArr(this.drawingDocuments, drawingDocumentData);
      } else {
        this.updateArr(this.drawingDocuments, drawingDocumentData);
      }
    },
    discardUpdate() {
      this.slideouts.update.active = false;
    },
    rowClass(item) {
      return item.count > 0 ? "row-not-empty" : "";
    },
    addDocument() {
      if (!this.canCreate) return;
      this.$refs.editDrawingDocument.open(null, true);
    },
    editDocument(item) {
      this.$refs.editDrawingDocument.open(item.id, true);
    },
    cloneDocument(item) {
      if (!this.canCreate) return;
      this.$refs.cloneDrawingDocument.open(item);
    },
    onCloneDrawingDocument() {
      this.loadDrawingDocuments();
    },
    rowClicked(item) {
      if (this.allowSelect) {
        var index = this.selectedDrawingDocuments.findIndex((s) => s.id == item.id);
        if (index == -1) this.selectedDrawingDocuments.push(item);
        else this.selectedDrawingDocuments.splice(index, 1);
        return;
      }
      this.openDocumentPanel(item.id);
    },
    openDocumentPanel(id) {
      this.$refs.editDrawingDocument.open(id, false);
    },
    clearSelection() {
      this.selectedDrawingDocuments = [];
      this.allowSelect = false;
    },
    bulkDelete() {
      this.$dialog
        .warning({
          text: `Are you sure you want to delete <u><b>"${this.selectedDrawingDocuments.length}"</b></u> drawing documents?`,
          title: `Delete Selected Drawing Documents?`,
          color: "error",
          persistent: true,
          actions: {
            false: {
              text: "Cancel",
            },
            true: {
              text: "Confirm",
              color: "error",
              handle: () => {
                return ddmAPI
                  .bulkDeleteDocuments({ ids: this.selectedDrawingDocuments.map((sd) => sd.id) })
                  .then((resp) => {
                    this.$log("resp", resp);
                    this.drawingDocuments = this.cloneDeep(
                      this.drawingDocuments.filter(
                        (e) => !this.selectedDrawingDocuments.some((sd) => sd.id == e.id)
                      )
                    );
                    this.clearSelection();
                    this.loadDrawingDocuments();
                    this.$dialog.notify.success(
                      "Selected drawing documents deleted successfully!",
                      {
                        position: "top-right",
                        timeout: 3000,
                      }
                    );
                  })
                  .catch((err) => {
                    this.$handleError(err);
                    // this.$dialog.notify.error(
                    //   "an error occurred during deleting the drawing document",
                    //   {
                    //     position: "top-right",
                    //     timeout: 3000,
                    //   }
                    // );
                  });
              },
            },
          },
        })
        .then((res) => {});
    },
  },
  watch: {},
  components: { DocumentType, EditDrawingDocument, FilterMenu, Refresh, CloneDrawingDocument },
};
</script>
<style lang="scss">
.docs-tree {
  .v-treeview-node__root {
    // box-shadow: 0px 3px 1px -2px rgba(42, 54, 59, 0.16), 0px 2px 2px 0px rgba(42, 54, 59, 0.1),
    //   0px 1px 5px 0px rgba(42, 54, 59, 0.08) !important;
    margin-bottom: 0.5rem;
  }

  .v-treeview-node__content {
    min-height: 40px;
    display: flex;
    align-items: center;
    background: #fff;
    box-shadow: 0px 3px 1px -2px rgba(42, 54, 59, 0.16), 0px 2px 2px 0px rgba(42, 54, 59, 0.1),
      0px 1px 5px 0px rgba(42, 54, 59, 0.08) !important;
    padding-left: 1rem;
    border-radius: 0.25rem;

    .v-treeview-node__label {
      min-height: 40px;
      display: flex;
      align-items: center;
      width: 100%;
    }

    .tree-document {
      min-height: 40px;
      display: flex;
      align-items: center;
      width: 100%;
    }
  }

  .v-treeview-node__prepend {
    margin: 0 !important;
  }
}

.documents-table {
  tbody tr:not(.v-data-table__empty-wrapper) {
    cursor: pointer;
  }
}

.no-docs-available {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    width: 500px;
    // opacity: 0.87;
  }

  p {
    margin-top: 0.5rem;
    margin-bottom: 0;
  }
}

.document-jira-ticket {
  position: relative;
}

.document-name {
  font-weight: 600;
}
</style>
