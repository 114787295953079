var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"alt-comp-wrapper project-selector",class:{
    'details-hidden': _vm.hideDetails,
    'alt-comp-wrapper-small': _vm.small,
  }},[_c('v-autocomplete-alt',{attrs:{"auto-select-first":"","rules":_vm.required ? [_vm.allRules.required] : [],"label":_vm.hideLabel
        ? null
        : _vm.label == null || _vm.label == ''
        ? "<i class='fad fa-folder mr-1'></i> Project"
        : _vm.label,"id":"projectSelector","placeholder":"Select a Project","items":_vm.projects,"value":_vm.value,"search-input":_vm.search,"item-value":"id","item-text":"fullName","dense":"","filled":"","no-filter":"","clearable":"","hide-details":_vm.hideDetails,"loading":_vm.isProjectLoading,"readonly":_vm.readonly,"menu-props":{
      zIndex: 90000,
      contentClass: 'user-selector-menu',
    }},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event},"input":_vm.handleSelectedProject},scopedSlots:_vm._u([_vm._l((['selection', 'item']),function(item){return {key:("" + item),fn:function(data){return [_c('v-row',{key:item.id + '_projectsSelector',staticClass:"py-0",staticStyle:{"flex":"none","max-width":"calc(100% - 40px)","width":"100%","flex-wrap":"nowrap"},attrs:{"align-content":"center","justify":"start","no-gutters":""}},[_c('v-col',{staticClass:"d-flex align-center mr-1",attrs:{"sm":"auto"}},[_c('div',{staticClass:"project-image-wrapper"},[(data.item.imageUrl != null && data.item.imageUrl != '')?_c('v-img',{attrs:{"src":data.item.imageUrl,"height":"24","width":"40","position":"center center","contain":""}}):_c('v-img',{attrs:{"src":"/img/DNA_Symbol.png","height":"24","width":"40","position":"center center","contain":""}})],1)]),_c('v-col',{staticClass:"d-flex align-center col-auto"},[_c('div',{staticClass:"d-flex align-center font-weight-medium",staticStyle:{"font-size":"14px"}},[_vm._v(" "+_vm._s(data.item.fullName)+" ")])])],1)]}}})],null,true)}),_c('v-btn',{staticClass:"pa-0",attrs:{"min-width":"24px !important","width":"24px !important","height":"24px !important","color":"secondary","outlined":"","small":"","target":"_blank","href":("/projects/" + (this.value)),"disabled":_vm.value == null,"loading":_vm.isProjectLoading}},[_c('i',{staticClass:"far fa-external-link"})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }