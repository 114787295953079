var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"alt-comp-wrapper",class:_vm.allowView ? 'allow-view' : ''},[_c('v-autocomplete-alt',{attrs:{"auto-select-first":"","label":_vm.label == null || _vm.label == ''
        ? "<i class='fab fa-jira mr-1'></i> Jira Project"
        : _vm.label,"id":"projectJiraCode","placeholder":"Jira Key ex: B22009","value":_vm.value,"items":_vm.jiraProjects,"filter":_vm.jiraProjectsFilter,"dense":"","filled":"","item-text":"key","item-value":"key","clearable":"","loading":_vm.isJiraProjectsLoading,"disabled":_vm.isJiraProjectsLoading,"readonly":_vm.readonly},on:{"input":_vm.handleSelectedJiraProject},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('v-row',{staticClass:"py-0",staticStyle:{"flex":"none","max-width":"calc(100% - 40px)","flex-wrap":"nowrap"},attrs:{"align-content":"center","justify":"start","no-gutters":""}},[_c('v-col',{staticClass:"d-flex align-center mr-2",attrs:{"sm":"auto"}},[(
              data.item.avatarUrls['32x32'] != null &&
                data.item.avatarUrls['32x32'] != ''
            )?_c('v-img',{attrs:{"src":_vm.wrapAvatarUrl(data.item.avatarUrls['32x32']),"height":"32","width":"32","position":"center center","contain":""}}):_vm._e()],1),_c('v-col',{staticClass:"d-flex justify-center col-auto flex-column"},[_c('div',{staticClass:"jira-project-key"},[_vm._v(" "+_vm._s(data.item.key)+" "),_c('v-chip',{staticClass:"ml-2",attrs:{"x-small":"","color":""}},[_c('div',{staticClass:"jira-project-category-name"},[_vm._v(" "+_vm._s(data.item.projectCategory.name)+" ")])])],1),_c('div',{staticClass:"jira-project-name",staticStyle:{"max-width":"100%"}},[_vm._v(" "+_vm._s(data.item.name)+" ")])])],1)]}},{key:"item",fn:function(data){return [_c('v-row',{staticClass:"py-2",staticStyle:{"flex":"none"},attrs:{"align-content":"center","justify":"start","no-gutters":""}},[_c('v-col',{staticClass:"d-flex align-center mr-2",attrs:{"sm":"auto"}},[(
              data.item.avatarUrls['48x48'] != null &&
                data.item.avatarUrls['48x48'] != ''
            )?_c('v-img',{attrs:{"src":_vm.wrapAvatarUrl(data.item.avatarUrls['48x48']),"height":"48","width":"48","position":"center center","contain":""}}):_vm._e()],1),_c('v-col',{staticClass:"d-flex justify-center col-auto flex-column"},[_c('div',{staticClass:"jira-project-key"},[_vm._v(" "+_vm._s(data.item.key)+" "),_c('v-chip',{staticClass:"ml-2",attrs:{"x-small":"","color":""}},[_c('div',{staticClass:"jira-project-category-name"},[_vm._v(" "+_vm._s(data.item.projectCategory.name)+" ")])])],1),_c('div',{staticClass:"jira-project-name",staticStyle:{"max-width":"225px"}},[_vm._v(" "+_vm._s(data.item.name)+" ")])])],1)]}},{key:"label-append",fn:function(){return [_vm._t("label-append")]},proxy:true}],null,true)}),_c('v-tooltip',{attrs:{"top":"","nudge-top":"-4","disabled":_vm.value == null},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"pa-0",attrs:{"min-width":"28px !important","width":"28px !important","height":"28px !important","color":"secondary","outlined":"","small":"","target":"_blank","href":("https://dangeloconsultants.atlassian.net/browse/" + _vm.value),"disabled":_vm.value == null}},'v-btn',attrs,false),on),[_c('i',{staticClass:"fad fa-arrow-up-right-from-square fs-14px"})])]}}])},[_c('span',[_vm._v("Open Jira Project")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }