<template>
  <slideout
    dock="right"
    :size="nested ? '87%' : '650px'"
    :min-size="nested ? 500 : 650"
    :allow-resize="nested ? false : false"
    :append-to="nested ? '' : '#app'"
    class="slideout-blue-header"
    :visible.sync="slideouts.update.active"
    v-on:close="onSlideoutClosing"
  >
    <template v-slot:header>
      <h3 class="font-weight-bold pa-1 d-flex align-center" small>
        <span style="white-space: no-wrap">
          <i class="fad fa-clock-rotate-left mr-2"></i>Available Hours Source Records
        </span>
      </h3>
      <v-btn @click="closeUpdateSlideout()" icon dark>
        <i class="far fa-times"></i>
      </v-btn>
    </template>

    <div
      v-if="slideouts.update.active"
      fluid
      class="pa-3 inset-shadow ma-3 rounded-lg"
      style="background: #eceff1"
    >
      <available-record-card v-model="record" readonly fullWidth></available-record-card>
    </div>
    <h4 class="mb-3 text-center">
      <span
        class="d-inline-flex align-center justify-center light-blue lighten-5"
        style="width: 36px; height: 36px; border-radius: 4rem"
      >
        <!-- <i class="fad fa-angles-down blue-grey--text fs-18px"></i> -->
        <i class="fas fa-equals light-blue--text text--darken-4 fs-18px"></i>
      </span>
    </h4>

    <v-container
      v-if="slideouts.update.active"
      fluid
      class="pa-0 inset-shadow"
      style="background: #eceff1; overflow: auto; overflow: overlay; height: 100%"
    >
      <v-scroll-y-transition mode="out-in" style="width: 100%">
        <div
          v-if="isRecordsLoading"
          :key="'projectBigBoardMonths-list-loading'"
          class="projectBigBoardMonths-list-loading d-flex flex-column align-center justify-center"
          style="
            overflow: auto;
            overflow: overlay;
            height: 100%;
            flex: 1 1 auto;
            display: flex;
            flex-direction: column;
          "
        >
          <video
            width="275"
            muted
            loop
            autoplay
            class="inset-shadow-video"
            style="padding: 0.5rem; border-radius: 10rem; background: #fff"
          >
            <source src="/img/art/astronaut-mines-coins-4979111-4153144.mp4" type="video/mp4" />
          </video>
          <h4 class="mt-3 text--disabled">Loading Available Hours Source ...</h4>
        </div>
        <div
          v-else-if="sources != null"
          key="has-records"
          class="pa-3"
          style="overflow: auto; overflow: overlay"
        >
          <h3 class="secondary--text mb-3">
            <i class="fad fa-file-signature mr-2 ml-1"></i>Contracts
          </h3>
          <phase-record-card
            class="mb-3"
            v-for="(phaseRecord, i) in sources.constructionPhaseRecords"
            :key="i"
            :value="sources.constructionPhaseRecords[i]"
            fullWidth
            :users="users"
            :isUsersLoading="isUsersLoading"
          ></phase-record-card>
          <v-divider class="mt-6 mb-4" v-if="sources.offerRecords.length > 0"></v-divider>
          <h3 class="secondary--text mb-3" v-if="sources.offerRecords.length > 0">
            <i class="fad fa-envelope mr-2 ml-1"></i>Resource Offers
          </h3>
          <offer-record-full-card
            class="mb-3"
            v-for="(offerRecord, i) in sources.offerRecords"
            :key="'offerRecord_' + i"
            :value="sources.offerRecords[i]"
            fullWidth
            :users="users"
            :isUsersLoading="isUsersLoading"
          ></offer-record-full-card>
          <v-divider class="mt-6 mb-4" v-if="sources.shiftRecords.length > 0"></v-divider>
          <h3 class="secondary--text mb-3" v-if="sources.shiftRecords.length > 0">
            <i class="fad fa-arrows-turn-right mr-2 ml-1"></i>Shift Requests
          </h3>
          <shift-request-record-full-card
            class="mb-3"
            v-for="(shiftRecord, i) in sources.shiftRecords"
            :key="'shiftRecord_' + i"
            :value="sources.shiftRecords[i]"
            fullWidth
            :users="users"
            :isUsersLoading="isUsersLoading"
          ></shift-request-record-full-card>
        </div>
      </v-scroll-y-transition>
    </v-container>

    <template v-slot:footer>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="ml-2" @click="closeUpdateSlideout" dark color="#01579b">
          <i class="far fa-xmark mr-2"></i>Close
        </v-btn>
      </v-card-actions>
    </template>
  </slideout>
</template>

<script>
import perms from "../../../plugins/permissions";
import constructionPhaseRecordsService from "../../Contracts/services/constructionPhaseRecords-service";
import PhaseRecordCard from "../../ResourceOffers/components/PhaseRecordCard.vue";
import OfferRecordFullCard from "../../ResourceOffers/components/OfferRecordFullCard.vue";
import AvailableRecordCard from "./AvailableRecordCard.vue";
import ShiftRequestRecordFullCard from "./ShiftRequestRecordFullCard.vue";
import UserAllSelector from "../../Shared/components/UserAllSelector.vue";

export default {
  name: "available-hours-source-records",
  components: {
    PhaseRecordCard,
    AvailableRecordCard,
    OfferRecordFullCard,
    ShiftRequestRecordFullCard,
    // UserAllSelector,
  },
  data() {
    return {
      perms: perms,
      isRecordsLoading: false,
      record: null,
      sources: null,
      slideouts: {
        update: {
          active: false,
        },
      },
    };
  },
  props: {
    nested: {
      type: Boolean,
      default: false,
    },
    sourceUserId: {
      type: String,
      default: null,
    },
    isUsersLoading: {
      type: Boolean,
      default: false,
    },
    users: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    open(record) {
      this.$log("open >> record", record);
      this.record = record;
      this.records = [];
      this.isRecordsLoading = false;
      this.getAvailableHoursSourceRecords();
      this.slideouts.update.active = true;
    },
    closeUpdateSlideout() {
      this.slideouts.update.active = false;
    },
    onSlideoutClosing(e) {
      this.$emit("close");
    },
    getAvailableHoursSourceRecords() {
      this.$log("getAvailableHoursSourceRecords");
      this.isRecordsLoading = true;
      var toSend = {
        sourceUserId: this.sourceUserId,
        projectId: this.record.project.id,
        startDate: this.record.startDate,
        sourceLaborType: this.record.sourceLaborType,
      };
      constructionPhaseRecordsService
        .getAvailableHoursSources(toSend)
        .then((resp) => {
          this.sources = this.cloneDeep(resp.data);
          this.$log("getAvailableHoursSources", this.sources);
          this.isRecordsLoading = false;
        })
        .catch(() => {
          this.isRecordsLoading = false;
        });
    },
  },
  watch: {},
};
</script>
