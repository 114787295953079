<template>
  <div
    class="alt-comp-wrapper"
    :class="{
      'details-hidden': hideDetails,
      'hide-border': hideBorder,
      'alt-comp-wrapper-small': small,
      'alt-comp-wrapper-responsive': responsive,
    }"
  >
    <v-autocomplete-alt
      auto-select-first
      :rules="required ? [allRules.required] : []"
      :label="hideLabel ? null : $attrs.label || 'User'"
      id="projectUser"
      class="project-user"
      placeholder="Select a User"
      :value="value"
      @change="onUserChange"
      @input="emitUser"
      :items="activeUsers"
      dense
      :filter="usersFilter"
      filled
      :hide-details="hideDetails"
      item-text="firstName"
      item-value="id"
      :item-disabled="isUserDisabled"
      :loading="isUsersLoading"
      :readonly="readonly"
      clearable
      v-bind="$attrs"
      :menu-props="{
        zIndex: 90000,
        contentClass: 'user-selector-menu',
      }"
    >
      <template v-slot:selection="data">
        <user-avatar
          :key="'selection' + data.index + 'user'"
          :user="data.item"
          disableOpenLink
          hide-roles
        ></user-avatar>
      </template>
      <template v-slot:item="data">
        <user-avatar
          :key="'item' + data.index + 'user'"
          :user="data.item"
          disableOpenLink
          hide-roles
        ></user-avatar>
      </template>
      <template #append-item>
        <div v-intersect="loadMore" />
      </template>
    </v-autocomplete-alt>
    <v-btn
      v-if="$has(perms.DNAUsers.View) && !hideViewBtn"
      :min-width="small ? '24px !important' : '28px !important'"
      :width="small ? '24px !important' : '28px !important'"
      :height="small ? '24px !important' : '28px !important'"
      color="secondary"
      outlined
      class="pa-0"
      small
      @click="openUserInNewTab"
      :disabled="value == null"
    >
      <i class="fad fa-eye fs-14px"></i>
    </v-btn>
  </div>
</template>

<script>
import perms from "../../../plugins/permissions";
export default {
  data() {
    return {
      perms,
      total: 0,
      options: {
        search: null,
      },
    };
  },
  props: {
    value: {
      type: [Number, String, Array],
    },
    obj: {
      type: Object,
    },
    users: {
      type: Array,
    },
    required: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    hideLabel: {
      type: Boolean,
      default: false,
    },
    hideBorder: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    isUsersLoading: {
      type: Boolean,
      default: true,
    },
    small: {
      type: Boolean,
      default: false,
    },
    responsive: {
      type: Boolean,
      default: false,
    },
    hideViewBtn: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    isUserDisabled(item) {
      return item.isSuspended && this.maskSuspendedUsers;
    },
    selectByEmail(email) {
      var user = this.users.find((u) => u.email == email);
      if (user) {
        this.value = user.id;
      }
    },
    openUserInNewTab(e) {
      e.stopPropagation();
      const path = `/users/${this.value}`;
      window.open(path, "_blank");
    },
    onUserChange() {
      this.$emit("change", this.value);
    },
    emitUser(id) {
      this.$emit("input", id);
      this.$emit("update:obj", id ? this.users.find((elm) => elm.id == id) : null);
    },
    usersFilter(item, queryText, itemText) {
      const text1 = item.firstName.toLowerCase();
      const text2 = item.lastName.toLowerCase();
      const text3 = item.email.toLowerCase();
      const searchText = queryText.toLowerCase();
      return (
        text1.indexOf(searchText) > -1 ||
        text2.indexOf(searchText) > -1 ||
        `${text1} ${text2}`.indexOf(searchText) > -1 ||
        text3.indexOf(searchText) > -1
      );
    },
    refreshAutocomplete() {
      if (this.value && (!this.obj || this.value != this.obj.id)) {
        const index = this.users.findIndex((elm) => elm.id == this.value);
        if (index == -1) {
          this.fetchObj();
        }
      } else {
        if (
          this.obj &&
          (this.users.findIndex((elm) => elm.id == this.obj.id) == -1 || this.value != this.obj.id)
        ) {
          this.addAndEmit(this.obj);
        }
      }
    },
    addAndEmit(item) {
      this.addUniqueArr(this.users, [item], "id", true);
      this.emitUser(item.id);
    },
    loadMore(undefine, _, isIntersecting) {
      if (isIntersecting && this.total > this.users.length && !this.isUsersLoading) {
        // this line to loadMore if there is no search
        if (!this.options.search) this.options.page++;
        // this line to clear search only if there is item selected
        if (this.value) this.options.search = "";
      }
    },
    fetchObj() {
      usersAPI.getUserById(this.value).then((res) => {
        this.addAndEmit(res.data);
      });
    },
  },
  computed: {
    maskSuspendedUsers() {
      return this.$store.getters.personalSettings.maskSuspendedUsers;
    },
    activeUsers() {
      if (this.maskSuspendedUsers)
        return this.users.filter((u) => !u.isSuspended || u.id == this.value);
      else return this.users;
    },
  },
  watch: {
    options: {
      handler() {},
      deep: true,
    },
    obj() {
      this.refreshAutocomplete();
    },
  },
};
</script>
