<template>
  <div class="alt-comp-wrapper" v-if="projectId != null">
    <v-autocomplete-alt
      auto-select-first
      :rules="[allRules.required]"
      :label="
        label == null || label == ''
          ? `<i class='fad fa-address-book mr-2'></i> Target Project Contact`
          : label
      "
      id="ProjectContactSelector"
      placeholder="Select a Project Contract"
      :value="value"
      @input="handleSelectedCompanyContact"
      :items="contacts"
      :filter="projectContactFilter"
      dense
      filled
      item-value="id"
      item-text="name"
      clearable
      :loading="isProjectContactsLoading"
      :disabled="isProjectContactsLoading"
      :readonly="readonly"
    >
      <template v-for="item in ['selection', 'item']" #[`${item}`]="data">
        <user-avatar
          :key="item.id + '_contractTemplateSelector'"
          :user="data.item"
          hide-roles
          hide-labor
          disable-open-link
        ></user-avatar>
      </template>
    </v-autocomplete-alt>
  </div>
</template>

<script>
import projectsAPI from "../services/projects-service";

export default {
  props: {
    value: {
      type: Number,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    projectId: {
      type: Number,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
  },
  data() {
    return { contacts: [], isProjectContactsLoading: false };
  },
  methods: {
    handleSelectedCompanyContact(val) {
      this.$emit("input", val);
    },
    getContacts() {
      this.isProjectContactsLoading = true;
      projectsAPI
        .getProjectItem(this.projectId, "contacts")
        .then((resp) => {
          this.$log("contacts", resp.data);
          this.contacts = resp.data.map((cc) => cc.companyContact);
          this.isProjectContactsLoading = false;
        })
        .catch((err) => {
          this.isProjectContactsLoading = false;
          this.$handleError(err);
        });
    },
    projectContactFilter(item, queryText, itemText) {
      const text1 = item.name.toLowerCase();
      const text2 = item.number == null ? "" : item.number.toLowerCase();
      const text3 = item.description == null ? "" : item.description.toLowerCase();
      const searchText = queryText.toLowerCase();

      return (
        text1.indexOf(searchText) > -1 ||
        text2.indexOf(searchText) > -1 ||
        text3.indexOf(searchText) > -1
      );
    },
    refresh() {
      this.getContacts();
    },
  },
  created() {
    this.getContacts();
  },
};
</script>

<style lang="scss">
.alt-comp-wrapper.space-templates-selector {
  .v-autocomplete-alt {
    width: calc(100% - 40px);
    flex: 1 1 auto;
    overflow: hidden;
  }
}

.typeahead-space-name {
  max-width: calc(100% - 40px) !important;
}

.typeahead-space-description {
  max-width: calc(100% - 40px) !important;
}
</style>
